import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import { dateToRequestDate, buildQueryParams } from "../../../core/date";
import { format } from "../../../core/number";

const TransferenciaEntradaView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [observation, setObservation] = useState(data != undefined ? data.observacion : 'Ninguna');
    const [categoryData, setCategoryData] = useState([]);
    const [categoria, setCategoria] = useState(undefined);
    const [farmData, setFarmData] = useState([]);
    const [farm, setFarm] = useState(0);
    const [retirementData, setRetirementData] = useState([]);
    const [retirement, setRetirement] = useState(0);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : -1);
    const [transCant, setTransCant] = useState(data != undefined ? data.cantidad : 0);
    const [pesoTotal, setPesoTotal] = useState(data != undefined ? data.peso_bruto_total : 0);
    const [fechaTransferencia, setFechaTransferencia] = useState(data != undefined ? data.fecha_transferencia : '');

    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);        
    
    const [creationDate, setCreation] = useState(undefined);
    const [userCreation, setUserCreation] = useState(undefined);
    const [userLastModification, setUserLastModification] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }   

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    


    useEffect(() => {
        retrieveFarmByCompany();
        retrieveCategory();
        retrieveRetirementByFarm(data != undefined ? data.retiro.id_retiro : 0);
        retrieveOwners();
    }, []);

    useEffect(() => {
        console.log(data);
        retrieveRetirementByFarm(data != undefined ? data.retiro.id_retiro : 0);
        setFarm(data != undefined ? data.estancia_involucrada.id : 0);
        setRetirement(data != undefined ? data.retiro.id_retiro : 0);
        setCarimbo(data != undefined ? data.carimbo : -1);
        setTransCant(data != undefined ? data.cantidad : 0);
        setPesoTotal(data != undefined ? data.peso_bruto_total : 0);
        setFechaTransferencia(data != undefined ? data.fecha_transferencia : '');
        setObservation(data != undefined && data.observacion ? data.observacion : '');
        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);        
        setCategoria(data != undefined ? data.categoria.id_categoria : 0);
        setOwner(data != undefined ? data.owner : undefined);                
    }, [data]);

    const handleUpdate = (e) => {
        let outcomeTransferData = {
            data: {
                cantidad: transCant,
                carimbo: carimbo,
                fecha_movimiento: fechaTransferencia,
                fecha_transferencia: fechaTransferencia,
                id_categoria: categoria,
                id_estancia: data.estancia.id,
                id_estancia_involucrada: farm,
                id_movimiento: data.id_movimiento,
                id_retiro: retirement,
                id_transferencia: data.id_transferencia,
                observacion: observation,
                peso_bruto_total: pesoTotal,
                peso_total_devastado: pesoTotal * 0.96,
                owner: owner ? owner.id : undefined
            }
        };
        update(outcomeTransferData);
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/outcome/transfer", data, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    const retrieveCategory = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            setCategoryData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setCategoryData([]);
        });
    }

    const retrieveFarmByCompany = () => {
        let {company:{id}} = cookie.kUserSession.data;
        axios.get(process.env.REACT_APP_API_URL + "farm/" + id, prepareHeaders())
        .then(rs => {
            setFarmData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setFarmData([]);
        });
    }

    const retrieveRetirementByFarm = async (idRetiro) => {
        let url = process.env.REACT_APP_API_URL + "retiros";
        setFarm(idRetiro)
        url += buildQueryParams({id_estancia: idRetiro});
        await axios.get(url, prepareHeaders())
        .then(rs => {
            setRetirementData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setRetirementData([]);
        });
    }

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos de la transferencia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="fecha-transferencia">
                                <Form.Label>Fecha de transferencia</Form.Label>
                                <Form.Control disabled={!edit} value={fechaTransferencia} onChange={(e) => { setFechaTransferencia(e.target.value); }} type="date" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="estancia-destino">
                                <Form.Label>Estancia destino</Form.Label>
                                <Form.Control disabled={!edit} value={farm} as="select"
                                              onChange={(e) => retrieveRetirementByFarm(e.target.value)}>
                                    <option value={0} selected disabled>Seleccione estancia destino</option>
                                    {
                                        farmData != undefined && farmData.length > 0
                                        ? farmData.map((i)  => (
                                            <option value={i.id}>{i.nombre}</option>
                                            ))
                                        : <option value={0} selected disabled>{'Ninguna estancia disponible'}</option>
                                    }
                                </Form.Control>
                            </Form.Group>                        
                        </Col>                            
                        <Col>
                            <Form.Group controlId="owner">
                                <Form.Label>Propietario</Form.Label>
                                <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                    <option value={0} selected disabled>Seleccione el propietario</option>
                                    {
                                        owners.map((i) => (
                                            <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>                                                            
                        <Col>
                            <Form.Group controlId="retiro">
                                <Form.Label>Zona</Form.Label>
                                <Form.Control disabled={!edit} value={retirement} 
                                onChange={(e) => { setRetirement(e.target.value); }} as="select">
                                    <option value={0} selected disabled>Seleccione retiro</option>
                                    {
                                        retirementData != undefined && retirementData.length > 0
                                        ? retirementData.map((i)  => (
                                            <option value={i.id_retiro}>{i.nombre}</option>
                                            ))
                                        : <option value={0} selected disabled>{'Ningun retiro disponible'}</option>
                                    }
                                </Form.Control>
                            </Form.Group>                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="categoria">
                                <Form.Label>Categor&iacute;a</Form.Label>
                                <Form.Control disabled={!edit} value={categoria} as="select"
                                              onChange={(e) => setCategoria(e.target.value)}>
                                    <option value={0} selected disabled>Seleccione categor&iacute;a</option>
                                    {
                                        categoryData != undefined && categoryData.length > 0
                                        ? categoryData.map((i)  => (
                                            <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        : <option value={0} selected disabled>{'Ninguna categoria disponible'}</option>
                                    }
                                </Form.Control>
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group controlId="carimbo">
                                <Form.Label>Carimbo</Form.Label>
                                <Form.Control disabled={!edit}  value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }}  type="number" placeholder="Ingrese carimbo"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="cantidad_sobre_guia">
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control disabled={!edit} value={transCant} onChange={(e) => { setTransCant(e.target.value); }} type="number" placeholder="Ingrese cantidad"/>
                            </Form.Group>
                        </Col>           
                        <Col>
                            <Form.Group controlId="cantidad_sobre_guia">
                                <Form.Label>Peso total (KG)</Form.Label>
                                <Form.Control disabled={!edit} value={pesoTotal} onChange={(e) => { setPesoTotal(e.target.value); }} type="number" placeholder="Ingrese cantidad"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{textAlign: "right"}}>
                            <span><strong>Peso promedio: </strong> {format(pesoTotal / transCant, 2)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{textAlign: "right"}}>
                            <span><strong>Peso total devastado (4%): </strong> {format(pesoTotal * 0.96, 2)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="observacion">
                                <Form.Label>Observaci&oacute;n</Form.Label>
                                <Form.Control disabled={!edit} value={observation} type="text"
                                 onChange={(e) => setObservation(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        edit ? (<></>) : (
                            <>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="creador">
                                            <Form.Label>Creador</Form.Label>
                                            <Form.Control value={userCreation} disabled type="email" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="modificador">
                                            <Form.Label>Modificador</Form.Label>
                                            <Form.Control value={userLastModification} disabled type="email" />
                                        </Form.Group>                                
                                    </Col>                     
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="creacion">
                                            <Form.Label>Creación</Form.Label>
                                            <Form.Control value={creationDate} disabled type="datetime-local" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>                                    
                        )
                    }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default TransferenciaEntradaView;