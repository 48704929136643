import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {
    Button,
    Form,
    FormGroup,
    Col
} from 'react-bootstrap';
import styles from './Login.module.css'
import InputKaranda from "../../components/input";
import Notifications, {notify} from "react-notify-toast";
import {checkSession} from "../../core/session";
import logo from "../../assets/img/karanda.svg";

const Login = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");
    const md5 = require('md5');

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const goToRecoverPassword = () => {
        let { history } = props;
        history.push("/recover");
    }

    const goSelect = () => {
        let { history, location: { pathname } } = props;
        history.push(pathname.concat('/selection'));
    }

    const login = (data, config) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "authentication/login", data, config).then(rs => {

            let data = rs.data;

            localStorage.setItem("user_photo", data.data.foto);
            data.data.foto = undefined;
            setCookies("kUserSession", data);
            setLoading(false);

            goSelect();
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            notify.show("Usuario o contraseña incorrectos", "error");
        });
    }

    const handleLogin = () => {
        const dataRequest = {
            data: {
                email: email,
                password: md5(password)
            }
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "X-Requested-With",
                "api_key": "k-randa"
            }
        };
        login(dataRequest, axiosConfig);
    }

    const onEnter = (e, action) => {
        if (onEnter != undefined && onEnter != null) {
            if (e.charCode == 13) {
                action();
            }
        }
    }    

    useEffect(() => {
        checkSession("login", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Notifications />
            <div className={`col-md-12 ${styles.backgroundImgKaranda}`}>
                <div className={styles.loginContainer}>
                    <div className={`col-md-4 ${styles.loginBox}`}>
                        <div className={styles.imageContent}>
                            <img src={logo} className={styles.imgLogStyle} alt="Karanda" />
                        </div>
                        <Form className={styles.formLogin}>
                            <FormGroup className={styles.alingContentForm} controlId="formUsername">
                                <Col md={12} sm={12} xs={12}>
                                    <Form.Control type="email" className="karanda-input" 
                                        placeholder="Ingrese su correo electrónico" value={email} 
                                        onChange={(e) => {setEmail(e.target.value);}}
                                        onKeyPress={(e) => {onEnter(e, handleLogin)}}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup className={styles.alingContentForm} controlId="formPassword">
                                <Col md={12} sm={12} xs={12}>

                                    <Form.Control type="password" className="karanda-input" 
                                        placeholder="Ingrese su contraseña" value={password} 
                                        onChange={(e) => {setPassword(e.target.value);}}
                                        onKeyPress={(e) => {onEnter(e, handleLogin)}}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={12} sm={12} xs={12}>
                                    <div className={styles.alingContentForm}>
                                        <Button style={{ backgroundColor: "#4a6437", width: "100%" }}
                                            variant="success"
                                            size="sm"
                                            onClick={handleLogin}>
                                            {
                                                loading ? (
                                                    <img src={loadingBtn} height="15"/>
                                                ) : (<span>Ingresar</span>)
                                            }                               
                                        </Button>
                                    </div>
                                </Col>
                                <Col className={styles.alingContentForm} sm={12} xs={12}>
                                    <a onClick={goToRecoverPassword} className={styles.retrievePasswordStyle}>¿Olvidaste tu contraseña?</a>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;