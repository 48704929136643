import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Salidas.module.css";
import MenuCard from "../../components/menu-card";
import History from "../../components/history";
import transferIcon from '@iconify/icons-mdi/transfer';
import bookDead from '@iconify/icons-fa-solid/book-dead';
import {checkSession} from "../../core/session";
import cashRegister from '@iconify/icons-mdi/cash-register';
import baselineOutdoorGrill from '@iconify/icons-ic/baseline-outdoor-grill';

const Salidas = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos de entrada",
            path: "/movimientos"
        },        
    ]    

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Salidas"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Salidas</h1>
                    <section className={styles.menuGroup}>
                        <MenuCard history={props.history} menu="Ventas" representation={cashRegister} path={"/movimientos/salidas/ventas"} />
                        <MenuCard history={props.history} menu="Mortandad" representation={bookDead} path={"/movimientos/salidas/mortandad"}/>
                        <MenuCard history={props.history} menu="Transferencias" representation={transferIcon} path={"/movimientos/salidas/transferencias"} />
                        <MenuCard history={props.history} menu="Consumo Interno" representation={baselineOutdoorGrill} path={"/movimientos/salidas/consumo"} />                        
                    </section>
                </section>
            </section>                
        </>
    );

};

export default Salidas;