export const validateNumber = (canBeNull, min, max, value) => {
    if(value == null || value == undefined){
        return canBeNull;
    }
    return value >= min && value <= max;
}

export const validateField = (canBeNull, minSize, maxSize, value) => {
    if(value == null || value == undefined || value.trim() == ""){
        return canBeNull;
    }
    return value.length >= minSize && value.length <= maxSize;
}

export const validateEmail = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email == null || email == undefined || email.trim() == ""){
        return false;
    }  
    return email.match(mailformat);
}

export const validateDate = (date) => date != null && date != undefined;