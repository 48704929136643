import React from "react";
import AccountsPreview from "./AccountsPreview";

const ReportAccountsPreview = ({data}) => {

    return (
        <>
            {
                data == undefined ? <></> : (
                    <div style={{padding: "1.5em"}}>
                        <AccountsPreview rows={data.rows} level={0}/>
                    </div>
                )
            }
        </>
    );

}

export default ReportAccountsPreview;