import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { Icon, InlineIcon } from '@iconify/react';

const ExpenseView = ({ show, handleClose, data, edit, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id : undefined);
    const [amount, setAmount] = useState(data != undefined ? data.importe : undefined);
    const [documentNumber, setDocumentNumber] = useState(data != undefined ? data.numero_factura : undefined);

    const [provider, setProvider] = useState(data != undefined ? data.cliente.id : 0);
    const [providers, setProviders] = useState([]);

    const [observation, setObservation] = useState(data != undefined ? data.observacion : undefined);
    const [expenseDate, setExpenseDate] = useState(data != undefined ? data.fecha_gasto : undefined);
    const [details, setDetails] = useState(data != undefined ? data.details : []);
    const [descripcion, setDescripcion] = useState(undefined);
    
    const [account, setAccount] = useState(0);
    const [accounts, setAccounts] = useState([]);


    const [amountDetail, setAmountDetail] = useState(undefined);
    
/*     const [personType, setPersonType] = useState(data != undefined ? data.datos_personales.tipo_persona : "F");
    const [name, setName] = useState(data != undefined ? data.datos_personales.nombres : undefined);
    const [lastName, setLastName] = useState(data != undefined ? data.datos_personales.apellidos : undefined);
    const [email, setEmail] = useState(data != undefined ? data.email : undefined);
    const [birthDay, setBirthDay] = useState(data != undefined ? data.datos_personales.fecha_nacimiento : undefined);
    const [documentNumber, setDocumentNumber] = useState(data != undefined ? data.datos_personales.nro_documento : undefined);
    const [role, setRole] = useState(data != undefined ? data.id_rol : 1); */



    const [creationDate, setCreation] = useState(undefined);
    const [userCreation, setUserCreation] = useState(undefined);
    const [userLastModification, setUserLastModification] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }      

    useEffect(() => {
        setId(data != undefined ? data.id : undefined);

        setAmount(data != undefined ? data.importe : undefined);
        setDocumentNumber(data != undefined ? data.numero_factura : undefined);
        setProvider(data != undefined ? data.cliente.id : 0);
        setObservation(data != undefined ? data.observacion : undefined);
        setExpenseDate(data != undefined ? data.fecha_gasto : undefined);
        setDetails(data != undefined ? data.details : []);

        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);
    }, [data]);

    const buildDetails = () => {
        var detailsEntity = [];
        for(var i = 0; i < details.length; i++){
            var detailEntity = {
                descripcion: details[i].descripcion,
                importe: details[i].amount,
                cuenta_contable_id: parseInt(details[i].cuenta_contable.id),
                cuenta_contable_codigo: details[i].cuenta_contable.codigo
            };
            detailsEntity.push(detailEntity);
        }
        return detailsEntity;
    }    

    const handleUpdate = (e) => {
        var data = {
            data: {
                id: id,
                fecha_gasto: expenseDate,
                numero_factura: documentNumber,
                observacion: observation,
                id_proveedor: parseInt(provider),
                details: buildDetails(),
                tipo_factura: "I"
            }
        }
        update(data);        
    }


    const sumDetails = () => {
        var total = 0;
        for(var i = 0; i < details.length; i++){
            total += parseInt(details[i].amount);
        }
        return total;
    }    

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "expense", data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    const retrieveAccountingAccounts = () => {
        //setAccountsLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/accounts?id_estancia=" + currentFarm.id + "&tipo=A", prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let { data } = rs.data;
                    //setAccountsLoading(false);
                    setAccounts(data);
                }
                else {
                    //setAccountsLoading(false);
                    setAccounts([]);
                }
            })
            .catch(error => {
                console.error(error);
                //setAccountsLoading(false);
                setAccounts([]);
            });        
    }      

    const retrieveProviders = () => {
        axios.get(process.env.REACT_APP_API_URL + "customer", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setLoading(false);
                setProviders(data);
            }
            else {
                setLoading(false);
                setProviders([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setProviders([]);
        });
    }    

    const addDetail = (e) => {
        e.preventDefault();
        if(account != undefined && amount != undefined && account != 0 && (descripcion != undefined && descripcion.trim().length > 0)){
            var detail = {
                cuenta_contable: searchAccount(account),
                amount: amountDetail,
                descripcion: descripcion
            }
            setDetails([...details, detail]);
            cleanDetailHeader();
        }
    }

    const searchAccount = (id) => {
        for(var i = 0; i < accounts.length; i++){
            if(parseInt(accounts[i].id) === parseInt(id)){
                return accounts[i];
            }
        }
        return {};
    }
    

    const cleanDetailHeader = () => {
        setAmountDetail("");
        setAccount(0);
    }    

    const removeFromDetails = (e, detail) => {
        e.preventDefault();
        var detailsNew = [];
        for(var i = 0; i < details.length; i++){
            if(details[i].cuenta_contable.id != detail.cuenta_contable.id){
                detailsNew.push(details[i]);
            }
        }
        setDetails(detailsNew);
    }    

    useEffect(() => {
        retrieveProviders();
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos del gasto</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control disabled={!edit} value={expenseDate} onChange={(e) => { setExpenseDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>

                                <Form.Group controlId="proveedor">
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Control as="select" value={provider} onChange={(e) => {setProvider(e.target.value);}} disabled={!edit}>
                                        <option value={0} selected disabled>Seleccione el cliente</option>
                                        {
                                            providers.map((i) => (
                                                <option value={i.id}>{i.razon_social}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Group controlId="factura">
                                    <Form.Label>N° Factura</Form.Label>
                                    <Form.Control disabled={!edit} value={documentNumber} type="text" onChange={(e) => {setDocumentNumber(e.target.value);}} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (
                                <Row>
                                    <Col>
                                        <Form.Group controlId="cuenta">
                                            <Form.Label>Cuenta contable</Form.Label>
                                            <Form.Control as="select" value={account} className="karanda-input" onChange={(e) => {setAccount(e.target.value);}}>
                                                <option value={0} selected>Seleccione la cuenta</option>
                                                {
                                                    accounts.map((i) => (
                                                        <option value={i.id}>{i.codigo + " - " + i.descripcion}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>                        
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Descripción</Form.Label>
                                            <Form.Control className="karanda-input" value={descripcion} onChange={(e) => {setDescripcion(e.target.value)}} placeholder="Ingrese la descripción" type="text"/>
                                        </Form.Group>
                                    </Col>                                    
                                    <Col>
                                        <Form.Group controlId="importe">
                                            <Form.Label>Importe</Form.Label>
                                            <div style={{display: "flex"}}>
                                                <Form.Control className="karanda-input" value={amountDetail} onChange={(e) => { setAmountDetail(e.target.value); }} type="number" placeholder="Ingrese el importe" />&nbsp;&nbsp;
                                                <Button onClick={addDetail}>Agregar</Button>
                                            </div>
                                        </Form.Group>                        
                                    </Col>
                                </Row>                                
                            ) : <></>
                        }
                        <Row>
                            {
                                details.length > 0 ? (
                                    <Table>
                                        <thead>
                                            <th>Cuenta contable</th>
                                            <th>Descripción</th>
                                            <th>Importe</th>
                                            {
                                                edit ? ( <td></td> ) : (<></>)
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                                details.map((i) => (
                                                    <tr>
                                                        <td>{i.cuenta_contable.codigo + " - " + i.cuenta_contable.descripcion}</td>
                                                        <td>{i.descripcion}</td>
                                                        <td style={{textAlign: "right"}}>{"Gs. " + new Intl.NumberFormat("de-DE").format(i.amount)}</td>
                                                        {
                                                            edit ? (
                                                                <td>
                                                                    <Icon icon={bxsTrash} style={{cursor: "pointer"}} width="20px" onClick={(e) => {removeFromDetails(e, i);}}/>
                                                                </td>
                                                            ) : (<></>)
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td style={{textAlign: "right"}}>TOTAL: </td>
                                                <td style={{textAlign: "right"}}>{"Gs. " + new Intl.NumberFormat("de-DE").format(sumDetails())}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                )
                                : (<></>)
                            }
                        </Row>                        
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control disabled={!edit} value={observation} type="text"
                                    onChange={(e) => setObservation(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="datetime-local" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (
                            <Button variant="primary" onClick={handleUpdate}>
                                {
                                    updateLoading ? (
                                        <img src={loadingBtn} height="15"/>
                                    ) : (<span>Actualizar</span>)
                                }
                            </Button>
                        )
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ExpenseView;