import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";

const EficienciaReproductivaModal = ( { handlePrevious, handleSave } ) => {

    const [ totalHembrasEnServicio, setTotalHembrasEnServicio ] = useState(undefined);
    const [ totalHembrasPrenhadas, setTotalHembrasPrenhadas ] = useState(undefined);
    const [ porcentajeDePrenhezGeneral, setPorcentajeDePrenhezGeneral ] = useState(undefined);
    const [ porcentajeDeVacasTotal, setPorcentajeDeVacasTotal ] = useState(undefined);
    const [ porcentajeDeExtraccionVientre, setPorcentajeDeExtraccionVientre ] = useState(undefined);
    const [ porcentajeDeReposicionDeVientres, setPorcentajeDeReposicionDeVientres ] = useState(undefined);
    const [ totalTernerosMarcados, setTotalTernerosMarcados ] = useState(undefined);
    const [ porcentajeMarcacion, setPorcentajeMarcacion ] = useState(undefined);
    const [ porcentajeMerma, setPorcentajeMerma ] = useState(undefined);
    const [ pesoPromedioDesteteMacho, setPesoPromedioDesteteMacho ] = useState(undefined);
    const [ pesoPromedioDesteteHembra, setPesoPromedioDesteteHembra ] = useState(undefined);
    const [ kgsDestetado, setKgsDestetado ] = useState(undefined);
    const [ observacion, setObservacion ] = useState(undefined);

    const internalHandleSave = (e) => {
        e.preventDefault();
        handleSave({
            eficienciaReproductiva: {
                kgDestetado: kgsDestetado,
                pesoPromedioDesteteHembras: pesoPromedioDesteteHembra,
                pesoPromedioDesteteMachos: pesoPromedioDesteteMacho,
                porcentajeExtraccionVientre: porcentajeDeExtraccionVientre,
                porcentajeMarcacion,
                porcentajeMerma,
                porcentajePrenhez: porcentajeDePrenhezGeneral,
                porcentajeReposicionVientre: porcentajeDeReposicionDeVientres,
                porcentajeVacasTotal: porcentajeDeVacasTotal,
                totalHembrasPrenhadas: totalHembrasPrenhadas,
                totalHembrasServicio: totalHembrasEnServicio,
                totalTernerosMarcados: totalTernerosMarcados
            },
            observacion
        });
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title><b>Eficiencia reproductiva</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Total de hembras en servicio</Form.Label>
                                <Form.Control placeholder="Total de hembras en servicio" className="karanda-input" value={totalHembrasEnServicio} onChange={(e) => setTotalHembrasEnServicio(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Total de hembras preñadas</Form.Label>
                                <Form.Control placeholder="Total de hembras preñadas" className="karanda-input" value={totalHembrasPrenhadas} onChange={(e) => setTotalHembrasPrenhadas(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de preñez general</Form.Label>
                                <Form.Control placeholder="Ingrese en % de preñez general" className="karanda-input" value={porcentajeDePrenhezGeneral} onChange={(e) => setPorcentajeDePrenhezGeneral(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de vacas s/ total CAB./PROM/AÑO</Form.Label>
                                <Form.Control placeholder="% de vacas s/ total CAB./PROM/AÑO" className="karanda-input" value={porcentajeDeVacasTotal} onChange={(e) => setPorcentajeDeVacasTotal(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de extracción de vientre</Form.Label>
                                <Form.Control placeholder="Ingrese el % de extracción de vientre" className="karanda-input" value={porcentajeDeExtraccionVientre} onChange={(e) => setPorcentajeDeExtraccionVientre(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de reposición de vientres</Form.Label>
                                <Form.Control placeholder="Ingrese el % de reposición de vientres" className="karanda-input" value={porcentajeDeReposicionDeVientres} onChange={(e) => setPorcentajeDeReposicionDeVientres(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Total de terneros marcados</Form.Label>
                                <Form.Control placeholder="Ingrese el total de terneros marcados" className="karanda-input" value={totalTernerosMarcados} onChange={(e) => setTotalTernerosMarcados(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                    </Row>                                        
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de marcación</Form.Label>
                                <Form.Control placeholder="Ingrese el % de marcación" className="karanda-input" value={porcentajeMarcacion} onChange={(e) => setPorcentajeMarcacion(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de merma</Form.Label>
                                <Form.Control placeholder="Ingrese el % de merma" className="karanda-input" value={porcentajeMerma} onChange={(e) => setPorcentajeMerma(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Peso promedio destete (macho)</Form.Label>
                                <Form.Control placeholder="Peso promedio destete (macho)" className="karanda-input" value={pesoPromedioDesteteMacho} onChange={(e) => setPesoPromedioDesteteMacho(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Peso promedio destete (hembra)</Form.Label>
                                <Form.Control placeholder="Peso promedio destete (hembra)" className="karanda-input" value={pesoPromedioDesteteHembra} onChange={(e) => setPesoPromedioDesteteHembra(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>KG. destetado / vaca entorada</Form.Label>
                                <Form.Control placeholder="KG. destetado / vaca entorada" className="karanda-input" value={kgsDestetado} onChange={(e) => setKgsDestetado(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                                                
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Observación</Form.Label>
                                <Form.Control placeholder="Ingrese la observación" className="karanda-input" value={observacion} onChange={ (e) => setObservacion(e.target.value) } type="text" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handlePrevious }>Anterior</Button>
                <Button variant="primary" onClick={ internalHandleSave }>Registrar</Button>
            </Modal.Footer>        
        </>
    );

};

export default EficienciaReproductivaModal;