import React from "react";
import { Form, Pagination, Row, Col } from "react-bootstrap";
import styles from "./CustomPagination.module.css";

const CustomPagination = ({ page, pageSize, changePageSize, toPage, totalPages }) => {

    const validate = (e) => {
        e.preventDefault();
        changePageSize(e.target.value);
    }
    
    return (
        <>
            <Row>
                <Col>
                    <Pagination className="karanda-pagination">
                        <Pagination.First onClick={() => {toPage(1)}}>{"<<"}</Pagination.First>
                        <Pagination.Prev disabled={page == 1} onClick={() => { toPage(page - 1) }}>{"<"}</Pagination.Prev>
                        <Pagination.Item onClick={() => { toPage(1); }}>{1}</Pagination.Item>
                        <Pagination.Item active={true}>{page}</Pagination.Item>
                        <Pagination.Item onClick={() => { toPage(totalPages); }}>{totalPages}</Pagination.Item>
                        <Pagination.Next onClick={() => { page < totalPages ? toPage(page + 1) : console.log("") }}>{">"}</Pagination.Next>
                        <Pagination.Last onClick={() => {toPage(totalPages)}}>{">>"}</Pagination.Last>
                    </Pagination>                          
                </Col>                
                <Col md="auto">
                    <Row>
                        <Col>
                            <Form.Label className={styles.label}>Elementos por página</Form.Label>                        
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Control className={styles.input} step="5" min="5" max="50" size="sm" type="number" value={pageSize} onChange={validate}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )

}

export default CustomPagination;