import React, { useEffect, useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import styles from "./ProyeccionCard.module.css";
import { Icon, InlineIcon } from '@iconify/react';
import plusBoxMultiple from '@iconify/icons-mdi/plus-box-multiple';
import chevronUp from '@iconify/icons-bi/chevron-up';
import chevronDown from '@iconify/icons-bi/chevron-down';
import overflowMenuHorizontal from '@iconify/icons-carbon/overflow-menu-horizontal';

import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";
import PlanCuentasEditModal from "./PlanCuentasEditModal/PlanCuentasEditModal";

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {formatAmount} from "../../../core/amount";

const ProyeccionCard = ({ accounts, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);    

    const [showModal, setModalVisibility] = useState(false);
    const [showDelete, setModalDelete] = useState(false);
    const [showEdit, setModalEdit] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [toggled, setToggled] = useState(false);

    const openNewAccount = (e, i) => {
        setAccount(i);
        setModalVisibility(true);
    }

    const deleteAccount = (e, i) => {
        setAccount(i);
        setModalDelete(true);
    }

    const editAccount = (e, i) => {
        setAccount(i);
        setModalEdit(true);        
    }

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 3;
    }    

    const porcentaje = (proyectado, ejecutado) => {
        ejecutado = ejecutado == undefined ? 0 : ejecutado;
        return parseInt(((ejecutado * 100) / proyectado));
    }

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setModalDelete(false); }} data={account} callback={callback}/>
            <PlanCuentasEditModal show={showEdit} handleClose={() => { setModalEdit(false); }} current={account} callback={callback} />
            {
                accounts.map((i) => (
                    <>
                        {
                            i.cuentas == undefined || i.cuentas.length == 0 ? (
                                <Card className={styles.child}>
                                    <Card.Header>
                                        <span style={{fontSize: "24px"}}>{i.codigo + " - " + i.descripcion}</span>&nbsp;&nbsp;
                                        <Icon icon={overflowMenuHorizontal} width="30px"/>

                                            {
                                                i.projections.length > 0 ? (

                                                    <div style={{display: "flex", float: "right"}}>
                                                        <div style={{width: "40px"}}>
                                                            <CircularProgressbar styles={{ path: { stroke: porcentaje(i.projections[0].proyeccion, i.projections[0].ejecutado) <= 100 ? "#427C18" : "#B72B2B" }}} value={i.projections[0].ejecutado == undefined ? 0 : i.projections[0].ejecutado} maxValue={i.projections[0].proyeccion} text={porcentaje(i.projections[0].proyeccion, i.projections[0].ejecutado) + "%"} />
                                                        </div>
                                                        <div style={{marginLeft: "1em"}}>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Regular"}}>Proyectado</h5>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Black", color: "#183A7C"}}>{formatAmount(i.projections[0].proyeccion)}</h5>
                                                        </div>
                                                        <div  style={{marginLeft: "1.5em"}}>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Regular"}}>Ejecutado</h5>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Black", color: (i.projections[0].proyeccion >= (i.projections[0].ejecutado == undefined ? 0 : i.projections[0].ejecutado)) ? "#427C18" : "#B72B2B" }}>{i.projections[0].ejecutado == undefined ? formatAmount(0) : formatAmount(i.projections[0].ejecutado)}</h5>
                                                        </div>                                                
                                                    </div>
                                                
                                                ) : <></>
                                            }

                                    </Card.Header>
                                </Card>
                            ) : (
                                <Accordion>
                                    <Card className={styles.parent}>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => {setToggled(!toggled);}}>
                                            {
                                                toggled ? (
                                                    <Icon icon={chevronUp} width="30px"/>
                                                ) : (
                                                    <Icon icon={chevronDown} width="30px"/>
                                                )
                                            }
                                            &nbsp;&nbsp;
                                            
                                            <span style={{fontSize: "24px"}}>{i.codigo + " - " + i.descripcion}</span>&nbsp;&nbsp;

                                            <Icon icon={overflowMenuHorizontal} width="30px"/>

                                            
                                            {
                                                i.projections.length > 0 ? (

                                                    <div style={{display: "flex", float: "right"}}>
                                                        <div style={{width: "40px"}}>
                                                            <CircularProgressbar styles={{ path: { stroke: porcentaje(i.projections[0].proyeccion, i.projections[0].ejecutado) <= 100 ? "#427C18" : "#B72B2B" }}} value={i.projections[0].ejecutado == undefined ? 0 : i.projections[0].ejecutado} maxValue={i.projections[0].proyeccion} text={porcentaje(i.projections[0].proyeccion, i.projections[0].ejecutado) + "%"} />
                                                        </div>
                                                        <div style={{marginLeft: "1em"}}>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Regular"}}>Proyectado</h5>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Black", color: "#183A7C"}}>{formatAmount(i.projections[0].proyeccion)}</h5>
                                                        </div>
                                                        <div  style={{marginLeft: "1.5em"}}>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Regular"}}>Ejecutado</h5>
                                                            <h5 style={{fontSize: "14px", fontFamily: "Overlock-Black", color: (i.projections[0].proyeccion >= (i.projections[0].ejecutado == undefined ? 0 : i.projections[0].ejecutado)) ? "#427C18" : "#B72B2B"}}>{i.projections[0].ejecutado == undefined ? formatAmount(0) : formatAmount(i.projections[0].ejecutado)}</h5>
                                                        </div>                                                
                                                    </div>
                                                
                                                ) : <></>
                                            }

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className={styles.parentBody}>
                                                <ProyeccionCard accounts={i.cuentas} callback={callback} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            )
                        }
                    </>
                ))
            }
        </>
    );

};

export default ProyeccionCard;