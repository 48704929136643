import React from "react";
import CuentaCard from "./CuentaCard";

const PlanCuentasCard = ({ accounts, openNewAccount, deleteAccount, editAccount }) => {

    return (
        <>
            {
                accounts.map((i) => (
                    <CuentaCard level={1} cuenta={i} openNewAccount={openNewAccount} deleteAccount={deleteAccount} editAccount={editAccount}/>
                ))
            }
        </>
    );

};

export default PlanCuentasCard;