import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./ProfileModal.module.css";
import { MdImage, MdThumbUp, MdThumbDown } from "react-icons/md";

const pixelRatio = window.devicePixelRatio || 1;


const ProfileImgModal = ({ show, handleClose, getNewImg }) => {

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const setResizedImg = (previewCanvas, crop) => {
        if (!crop || !previewCanvas) {
            return;
        }
        getNewImg(previewCanvas.toDataURL());
        handleClose();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Button className={styles.inputContainer}>
                            Eliga una imagen <MdImage style={{ marginBottom: '1.5vh' }} />
                            <input className={styles.inputFile} type="file"
                                accept=".jpg, .png, .jpeg"
                                onChange={onSelectFile}
                            />
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex' }}>
                        <Col xs={12} md={6}>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="container text-center">
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0)
                                    }}
                                />
                            </div>
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!completedCrop?.width || !completedCrop?.height ? <MdThumbDown disabled /> : <MdThumbUp style={{ color: '#1879f8', cursor: 'pointer'}}
                                                                                                             onClick={() =>
                                                                                                                setResizedImg(previewCanvasRef.current, completedCrop)
                                                                                                             } />
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProfileImgModal;