import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Empresas.module.css";
import History from "../../components/history";
import { Button, Form, Table } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import usersGroup from '@iconify/icons-mdi/users-group';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import roundAssignment from '@iconify/icons-ic/round-assignment';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import CompanyModal from "./CompanyModal";
import DeleteConfirmation from "./DeleteConfirmation";
import CompanyView from "./CompanyView";
import Estancias from "./Estancias";
import Propietarios from "./Propietarios";

const Empresas = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [company, setCompany] = useState(0);
    const [showRegisterModal, setRegisterModalVisibility] = useState(false);
    const [showOwners, setOwnersVisibility] = useState(false);    
    const [edit, setEdit] = useState(false);
    const [showCompany, setCompanyVisibility] = useState(false);
    const [showFarms, setFarmsVisibility] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Panel de control",
            path: "/control-panel"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveCompanies = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "company", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setLoading(false);
                setCompanies(data);
            }
            else {
                setLoading(false);
                setCompanies([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setCompanies([]);
        });
    }

    const handleEdit = (e, i) => {
        setCompany(i);
        setEdit(true);
        setCompanyVisibility(true);
    }

    const handleDelete = (e, i) => {
        setCompany(i);
        setDeleteVisibility(true);
    }    

    const openCompany = (e, i) => {
        setCompany(i);
        setCompanyVisibility(true);
    }

    const openOwners = (e, i) => {
        setCompany(i);
        setOwnersVisibility(true);
    }

    const openFarms = (e, i) => {
        setCompany(i);
        setFarmsVisibility(true);
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveCompanies();
    }, []);

    return (
        <>
            <Propietarios show={showOwners} handleClose={() => {setOwnersVisibility(false);} } data={company} callback={retrieveCompanies} setVisibility={setOwnersVisibility} />
            <Estancias show={showFarms} handleClose={() => {setFarmsVisibility(false);} } data={company} callback={retrieveCompanies} setVisibility={setFarmsVisibility}/>
            <CompanyView show={showCompany} edit={edit} handleClose={() => {setCompanyVisibility(false); setEdit(false); }} data={company} callback={retrieveCompanies} />
            <DeleteConfirmation show={showDelete} handleClose={() => {setDeleteVisibility(false);}} data={company} callback={retrieveCompanies}/>
            <CompanyModal show={showRegisterModal} handleClose={() => {setRegisterModalVisibility(false); }} callback={retrieveCompanies} />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Gestión de empresas"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Gestión de empresas</h1>
                    <section className={styles.actions}>
                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={() => {setRegisterModalVisibility(true);}}>Registrar empresa</Button>
                        {
                            loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (

                                companies.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    isMobile() ? (
                                        companies.map((i) => (
                                            <></>
                                        ))
                                    ) : (
                                        <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                            <thead>
                                                <th>Perfil</th>
                                                <th>Nombre de la empresa</th>
                                                <th>RUC</th>
                                                <th>Fecha de ingreso</th>
                                                <th>Fecha de constitución</th>
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    companies.map((i) => (
                                                        <tr>
                                                            <td></td>
                                                            <td>{i.nombre}</td>
                                                            <td>{i.ruc}</td>
                                                            <td>{i.fecha_creacion}</td>
                                                            <td>{i.fecha_ingreso}</td>
                                                            <td>
                                                                <Icon className={styles.icon} icon={usersGroup} height="20px" onClick={(e) => {openOwners(e, i);}}/>&nbsp;&nbsp;
                                                                <Icon className={styles.icon} icon={roundAssignment} height="20px" onClick={(e) => {openFarms(e, i);}}/>&nbsp;&nbsp;
                                                                <Icon className={styles.icon} icon={eyeFilled} height="20px" onClick={(e) => {openCompany(e, i);}}/>&nbsp;&nbsp;
                                                                <Icon className={styles.icon} icon={editFilled} height="20px" onClick={(e) => { handleEdit(e, i); }} />&nbsp;&nbsp;
                                                                <Icon className={styles.icon} icon={bxsTrash} height="20px" onClick={(e) => {handleDelete(e, i);}} />&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                )
                            )
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Empresas;