import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Movimientos.module.css";
import MenuCard from "../../components/menu-card";
import History from "../../components/history";
import paddingTop20Filled from '@iconify/icons-fluent/padding-top-20-filled';
import paddingDown20Filled from '@iconify/icons-fluent/padding-down-20-filled';
import documentIcon from '@iconify/icons-jam/document';
import convertioIcon from '@iconify/icons-simple-icons/convertio';
import dataTransferDownload from '@iconify/icons-oi/data-transfer-download';
import dataTransferUpload from '@iconify/icons-oi/data-transfer-upload';
import {checkSession} from "../../core/session";
import cloudShowersHeavy from "@iconify/icons-fa-solid/cloud-showers-heavy";

const Movimientos = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]    

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isClient = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 4;
    }

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Movimientos de hacienda"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Movimientos de hacienda</h1>
                    <section className={styles.menuGroup}>
                        {
                            (isSupervisor() || isClient()) ? (
                                <MenuCard history={props.history} menu="Informes" representation={documentIcon} path={"/movimientos/informes"}/>
                            ) : <></>
                        }
                        <MenuCard history={props.history} menu="Entradas" representation={dataTransferDownload} path={"/movimientos/entradas"}/>
                        <MenuCard history={props.history} menu="Salidas" representation={dataTransferUpload} path={"/movimientos/salidas"}/>
                        <MenuCard history={props.history} menu="Recategorización" representation={convertioIcon} path={"/movimientos/recategorizaciones"}/>
                        
                    </section>
                    <section className={styles.menuGroup}>
                        <MenuCard history={props.history} menu="Faltantes" representation={paddingDown20Filled} path={"/movimientos/faltantes"}/>
                        <MenuCard history={props.history} menu="Sobrantes" representation={paddingTop20Filled} path={"/movimientos/sobrantes"}/> 
                        <MenuCard history={props.history} menu="Lluvias" representation={cloudShowersHeavy} path={"/movimientos/lluvias"}/>                       
                    </section>
                </section>
            </section>                
        </>
    );

};

export default Movimientos;