import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import History from "../../components/history";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import TimeFilter from "../../components/time-filter"
import styles from "./Lluvia.module.css";
import { displayDate, buildQueryParams } from "../../core/date";
import { checkSession, isMobile } from "../../core/session";
import { Icon} from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import LluviaModal from "./LluviaModal";
import LluviaView from "./LluviaView";
import DeleteConfirmation from "./DeleteConfirmation";
import Notifications, {notify} from "react-notify-toast";
import CustomPagination from "../../components/pagination/CustomPagination";

const Lluvia = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [loadingXls, setLoadingXls] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [cookie, setCookies] = useCookies(['kUserSession']);
    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);
    const [showModal, setModalVisibility] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rainDataList, setRainDataList] = useState([]);
    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [rainData, setRainData] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos de hacienda",
            path: "/movimientos"
        }
    ];


    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const generarExcel = (e) => {
        e.preventDefault();
        setLoadingXls(true);
        let url = process.env.REACT_APP_API_URL + "rain/report";
        let currentFarm = cookie.kUserSession.data["farm"];
        let queryParams = {
            id_estancia: currentFarm.id,
            page: page,
            page_size: pageSize,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta
        };
        url += buildQueryParams(queryParams);
        fetch(url, prepareHeaders())
        .then(response => {
            const filename =  response.headers.get("content-disposition").split("filename=")[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                setLoadingXls(false);
                notify.show("Reporte generado exitosamente", "success");
            });
        }).catch(error => {
            notify.show("Ha ocurrido un error al generar el reporte", "error");
            console.error(error);
            setLoadingXls(false);            
        });        
    }    

    const isAdmin = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 2;
    }

    const isSupervisor = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 1;
    };

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    };

    const handleSearch = (e) => {
        setPage(1);
        setPageSize(5);
        retrieveRain();
    };

    const retrieveRain = () => {
        setLoading(true);
        setTotalPages(1);
        let url = process.env.REACT_APP_API_URL + "rain";
        let currentFarm = cookie.kUserSession.data["farm"];
        let queryParams = {
            id_estancia: currentFarm.id,
            page: page,
            page_size: pageSize,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta
        };
        url += buildQueryParams(queryParams);
        axios.get(url, prepareHeaders())
            .then(rs => {
                let { status } = rs;
                if (status == 200 || status == 206) {
                    let { content } = rs.data;
                    setTotalPages(rs.data.totalPages);
                    setRainDataList(content);
                }
                else {
                    setTotalPages(1);
                    setRainDataList([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setTotalPages(1);
                setRainDataList([]);
                setLoading(false);
            });

    };

    useEffect(() => {
        checkSession("lluvias", props.history, cookie.kUserSession);
        retrieveRain();
    }, []);

    const toPage = (page) => {
        setPage(page);
    };

    const openUserDetailView = (e, i) => {
        setRainData(i);
        setUserDetailVisibility(true);
    };

    const openUserEdit = (e, i) => {
        setRainData(i);
        setUserDetailVisibility(true);
        setEdit(true);
    };

    const handleDelete = (e, i) => {
        setRainData(i);
        setDeleteVisibility(true);
    };

    return (
        <>
            <Notifications />
            <DeleteConfirmation show={showDelete} handleClose={() => setDeleteVisibility(false)} data={rainData} callback={retrieveRain} />
            <LluviaView show={showUserDetail} edit={edit} handleClose={() => { setUserDetailVisibility(false); setEdit(false); }} data={rainData} callback={retrieveRain} />
            <LluviaModal show={showModal} handleClose={() => setModalVisibility(false)} callback={retrieveRain}/>
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Lluvias"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <section className={styles.side}> 
                            <Form className={styles.searchForm}>
                                <TimeFilter callback={callbackFiltroFechas} />
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" style={{ height: "fit-content", marginTop: "auto" }} onClick={handleSearch}>Buscar</Button>&nbsp;&nbsp;
                                    </Col>
                                </Row><br></br>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" disabled={loadingXls} style={{ height: "fit-content", marginTop: "auto" }} onClick={generarExcel}>
                                            Descargar Excel
                                            {
                                                loadingXls ? (
                                                    <>
                                                        &nbsp;&nbsp;<img src={loadingBtn} height="15" />
                                                    </>
                                                ) : <></>
                                            }
                                        </Button>                                
                                    </Col>
                                </Row>
                            </Form>                        
                        </section>
                    </Col>
                    <Col>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Lluvias</h1>
                            <section className={styles.actions}>
                                {
                                    isSupervisor() || isAdmin() ? (
                                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setModalVisibility(true); }}>Registrar lluvia</Button>
                                    ) : (<></>)
                                }
                                {
                                    loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                        rainDataList.length == 0 ? (
                                            <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div>
                                        ) : (
                                                isMobile() ? (
                                                    /*                                                 rainData.map((i) => (
                                                                                                        <UserCard expense={i} />
                                                                                                    )) */
                                                    <span>Vista no disponible</span>
                                                ) : (
                                                        <>
                                                            <Table responsive className="karanda-table" style={{ marginTop: "15px" }}>
                                                                <thead>
                                                                    <th >Fecha lluvia</th>
                                                                    <th >Milimetros</th>
                                                                    <th >Estancia</th>
                                                                    <th >Acciones</th>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        rainDataList.map((i) => (
                                                                            <tr>
                                                                                <td >{displayDate(i.fecha_lluvia)}</td>
                                                                                <td >{i.milimetros}</td>
                                                                                <td >{i.estancia === undefined || i.estancia === null ? 'No registrada' : i.estancia.nombre}</td>
                                                                                <td align="center">
                                                                                    <Icon className={styles.icon} onClick={(e) => { openUserDetailView(e, i); }} icon={eyeFilled} height="15px" />&nbsp;&nbsp;

                                                                                    {
                                                                                        isSupervisor() ? (
                                                                                            <>
                                                                                                <Icon className={styles.icon} onClick={(e) => { openUserEdit(e, i); }} icon={editFilled} height="15px" />&nbsp;&nbsp;
                                                                                                <Icon className={styles.icon} onClick={(e) => { handleDelete(e, i); }} icon={bxsTrash} height="15px" />&nbsp;&nbsp;
                                                                                            </>
                                                                                        ) : (<></>)
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={toPage} totalPages={totalPages}/>
                                                        </>
                                                    )
                                            )
                                    )
                                }
                            </section>
                        </section>                    
                    </Col>
                </Row>
            </section>
        </>
    );
};
export default Lluvia;