import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table, Spinner } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./Propietarios.module.css";
import { Icon, InlineIcon } from '@iconify/react';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import bxsUser from '@iconify/icons-bx/bxs-user';

const Propietarios = ({ show, handleClose, callback, data, setVisibility }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [owners, setOwners] = useState([]);

    const [name, setName] = useState(undefined);
    const [nameError, setNameError] = useState(false);

    const [lastName, setLastName] = useState(undefined);
    const [lastNameError, setLastNameError] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveOwners = () => {
        setLoading(true);
        console.log(data.id);
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + data.id, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                setOwners(rs.data);
                setLoading(false);
            }
            else {
                setOwners([]);
                setLoading(false);
            }

        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setLoading(false);
        });
    }    

    const handleSave = (e) => {
        let pass = true;
        if(name == undefined || name == null || name.length == 0){
            setNameError(true);
            pass = false;            
        }
        else {
            setNameError(false);
        }
        if(lastName == undefined || lastName == null || lastName.length == 0){
            setLastNameError(true);
            pass = false;            
        }
        else {
            setLastNameError(false);
        }
        if(pass){
            const postData = {
                name,
                lastName,
                company: data.id
            }
            save(postData);
        }        
    }

    const handleDelete = (e, i) => {
        deleteOwner(i.id);
    }

    const deleteOwner = (id) => {
        setLoading(true);
        axios.delete(process.env.REACT_APP_API_URL + "owners/" + id, prepareHeaders())
        .then(rs => {
            setLoading(false);
            retrieveOwners();
        })
        .catch(error => {
            console.error(error);
            retrieveOwners(false);
            notify.show("Ha ocurrido un error al eliminar la estancia", "error");
        });
    }

    const save = (postData) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "owners", postData, prepareHeaders())
        .then(rs => {
            setLoading(false);
            setName(undefined);
            setLastName(undefined);
            retrieveOwners();            
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            retrieveOwners();
            notify.show("Ha ocurrido un error al crear la estancia", "error");
        })
    }

    useEffect(() => {
        if (data != undefined) {
            console.log(data);
            retrieveOwners(data.id);
        }
    }, [data]);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Propietarios de {data.nombre}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={styles.searchForm}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>Nombres</b></Form.Label>
                                    <Form.Control className={nameError ? styles.error : ""} type="text" placeholder="Ingrese el nombre del propietario" value={name} onChange={(e) => {setName(e.target.value)}}/>
                                </Form.Group>                            
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>Apellidos</b></Form.Label>
                                    <Form.Control className={lastNameError ? styles.error : ""} type="text" placeholder="Ingrese el apellido del propietario" value={lastName} onChange={(e) => {setLastName(e.target.value)}}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className="karanda-btn" disabled={loading} onClick={(e) => {handleSave(e); }}>
                            {
                                loading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <span>Registrar</span>
                                )
                            }
                        </Button>
                    </Form>
                    {
                        loading ?   
                            <div style={{marginTop: "15px", textAlign: "center"}}>
                                <img src={loadingBtn} height="50"  />
                            </div> 
                            : 
                            (
                                owners.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) 
                                : 
                                (
                                    <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                        <thead>
                                            <th>Nombres</th>
                                            <th>Apellidos</th>
                                            <th>Acciones</th>
                                        </thead>
                                        <tbody>
                                            {
                                                owners.map((i) => (
                                                    <tr>
                                                        <td>{i.nombres}</td>
                                                        <td>{i.apellidos}</td>
                                                        <td>
                                                            <Icon className={styles.icon} icon={bxsTrash} height="20px" onClick={(e) => {handleDelete(e, i); }} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                )
                            )
                        }                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default Propietarios;