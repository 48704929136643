import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import { dateToRequestDate, buildQueryParams } from "../../../core/date";
import styles from "./TransferenciaEntradaModal.module.css";
import { format } from "../../../core/number";

const TransferenciaEntradaModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [observation, setObservation] = useState('');
    const [farm, setFarm] = useState(0);
    const [farmData, setFarmData] = useState([]);
    const [retirement, setRetirement] = useState(undefined);
    const [retirementData, setRetirementData] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [carimbo, setCarimbo] = useState(0);
    const [transCant, setTransCant] = useState(0);
    const [fechaTransfer, setFechaTransfer] = useState(undefined);
    const [pesoTotal, setPesoTotal] = useState(0);

    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }      

    const validateParams = () => {
        return true;
    }

    const save = (body) => {
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "movements/outcome/transfer";
        axios.post(url, body, prepareHeaders())
        .then(rs => {
            let data = rs.data;
            callback();
            setLoading(false);
            handleClose();
            notify.show("Transferencia registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar la transferencia", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            let currentFarm = cookie.kUserSession.data["farm"];
            let body = {
                data: {
                    cantidad: parseInt(transCant),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: fechaTransfer,
                    fecha_transferencia: fechaTransfer,
                    id_categoria: parseInt(categoria),
                    id_estancia: currentFarm.id,
                    id_estancia_involucrada: parseInt(farm),
                    retiro: retirement,
                    observacion: observation == '' ? null : observation,
                    peso_bruto_total: parseInt(pesoTotal),
                    peso_total_devastado: format(pesoTotal * 0.96, 2),
                    owner: owner ? owner.id : undefined                    
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveFarmByCompany();
        retrieveCategory();
        retrieveOwners();        
    }, []);

    const retrieveFarmByCompany = () => {
        let {company:{id}} = cookie.kUserSession.data;
        axios.get(process.env.REACT_APP_API_URL + "farm/" + id, prepareHeaders())
        .then(rs => {
            setFarmData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setFarmData([]);
        });
    }

    const retrieveRetirementByFarm = async (idRetiro) => {
        setFarm(idRetiro);
    }

    const retrieveCategory = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            setCategoryData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setCategoryData([]);
        });
    }

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva transferencia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha-nacimiento">
                                    <Form.Label>Fecha de transferencia</Form.Label>
                                    <Form.Control className="karanda-input" value={fechaTransfer} onChange={(e) => setFechaTransfer(e.target.value)} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="farm-source">
                                    <Form.Label>Estancia destino</Form.Label>
                                    <Form.Control className="karanda-input" value={farm} onChange={(e) => retrieveRetirementByFarm(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione estancia destino</option>
                                        {
                                            farmData != undefined && farmData.length > 0
                                            ? farmData.map((i)  => (
                                                <option value={i.id}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ninguna estancia disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>                            
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => { setOwner({ ...owner, id: e.target.value }); }}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="retirement">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control className="karanda-input" value={retirement} onChange={(e) => setRetirement(e.target.value)} type="text"/>
                                </Form.Group>             
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="category">
                                    <Form.Label>Categor&iacute;a</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => setCategoria(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione categor&iacute;a</option>
                                        {
                                            categoryData != undefined && categoryData.length > 0
                                            ? categoryData.map((i)  => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ninguna categoria disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" placeholder="Ingrese carimbo"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad-transf">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={transCant} onChange={(e) => setTransCant(e.target.value)} type="number" placeholder="Ingrese cantidad"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="peso-total">
                                    <Form.Label>Peso total (KG)</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoTotal} onChange={(e) => setPesoTotal(e.target.value)} type="number" placeholder="Ingrese cantidad"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Col md={12} style={{textAlign: "right"}}>
                            <span><strong>Peso promedio: </strong> {format(pesoTotal/(transCant == 0 ? 1 : transCant), 2)}</span>
                        </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: "right"}}>
                                <span><strong>Peso total devastado (4%): </strong> {format(pesoTotal * 0.96)}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observation}
                                                  onChange={(e) => setObservation(e.target.value)}
                                                  type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default TransferenciaEntradaModal;