import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./ProyeccionesGastos.module.css";
import History from "../../components/history";
import { Button, Table, Pagination, Form, Col, Row } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import ProyeccionCard from "./ProyeccionCard";
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import ProyeccionModal from "./ProyeccionCard/ProyeccionModal";

const ProyeccionesGastos = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [account, setAccount] = useState(undefined);

    const [mes, setMes] = useState(0);
    const [anho, setAnho] = useState(new Date().getFullYear());
    const [meses, setMeses] = useState([
        {
            code: 1,
            name: "Enero"
        },
        {
            code: 2,
            name: "Febrero"
        },
        {
            code: 3,
            name: "Marzo"
        },
        {
            code: 4,
            name: "Abril"
        },
        {
            code: 5,
            name: "Mayo"
        },
        {
            code: 6,
            name: "Junio"
        },
        {
            code: 7,
            name: "Julio"
        },
        {
            code: 8,
            name: "Agosto"
        },
        {
            code: 9,
            name: "Septiembre"
        },
        {
            code: 10,
            name: "Octubre"
        },
        {
            code: 11,
            name: "Noviembre"
        },
        {
            code: 12,
            name: "Diciembre"
        }

    ])

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Proyecciones",
            path: "/proyecciones"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 3;
    }

    const retrieveAccountingAccounts = () => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var currentCompany = cookie.kUserSession.data["company"];
        var params = "?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id + "&anho=" + anho;

        if(mes != 0){
            params += "&mes=" + mes;
        }

        axios.get(process.env.REACT_APP_API_URL + "expense/projection" + params, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs;
                setLoading(false);
                setAccounts(data);
            }
            else {
                setLoading(false);
                setAccounts([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setAccounts([]);
        });
        
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <ProyeccionModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrieveAccountingAccounts} parent={account}/>
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Proyecciones de gastos"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Proyecciones de gastos</h1>
                    <section className={styles.actions}>
                        {
                            isSupervisor() ? (
                                <>
                                    <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setAccount(undefined); setModalVisibility(true); }}>Nueva proyección</Button>&nbsp;&nbsp;
                                </>
                            ) : (<></>)
                        }
                        <Form style={{marginTop: "1.5em"}}>
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Mes</Form.Label>
                                        <Form.Control as="select" value={mes} onChange={(e) => {setMes(e.target.value);}}>
                                            <option value={0}>TODOS</option>
                                            {
                                                meses.map((i) => (
                                                    <option value={i.code}>{i.name}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>                          
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Año</Form.Label>
                                        <div style={{display: "flex"}}>
                                            <Form.Control type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />&nbsp;&nbsp;
                                            <Button onClick={retrieveAccountingAccounts} className={`karanda-btn ${styles.btnRegister}`}>Buscar</Button>
                                        </div>
                                    </Form.Group>
                                </Col>                                
                            </Row>
                        </Form>
                    </section>
                    <br></br>
                    {
                        loading ? (
                            <img src={loadingBtn} height="50" />
                        ) : (
                            <ProyeccionCard accounts={accounts} callback={retrieveAccountingAccounts} />
                        )
                    }
                </section>
            </section>
        </>
    );

};

export default ProyeccionesGastos;