import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import { dateToRequestDate } from "../../../core/date";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const LluviaModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [fechaLluvia, setFechaLluvia] = useState(new Date());
    const [milimetros, setMilimetros] = useState('');

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    };

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "rain", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            callback();
            setLoading(false);
            handleClose();
            notify.show("Datos de lluvia registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar los datos de la lluvia", "error");
        });
    };

    const handleSave = (e) => {
        let currentFarm = cookie.kUserSession.data["farm"];
        let body = {
            data: {
                milimetros: milimetros,
                fecha_lluvia: fechaLluvia,
                id_estancia: currentFarm.id
            }
        };
        save(body);
    };

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo registro de lluvia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha-lluvia">
                                    <Form.Label>Fecha de lluvia</Form.Label>
                                    <Form.Control className="karanda-input" value={fechaLluvia}
                                        onChange={(e) => setFechaLluvia(dateToRequestDate(e.target.value))} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="milimetros">
                                    <Form.Label>Milimetros</Form.Label>
                                    <Form.Control className="karanda-input" value={milimetros}
                                        onChange={(e) => setMilimetros(e.target.value)} type="text"
                                        placeholder="Ingrese cant. milimetros" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15" />
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default LluviaModal;