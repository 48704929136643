import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./NacimientoModal.module.css";

const NacimientoModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [cantidad, setCantidad] = useState(1);
    const [fechaNacimiento, setFechaNacimiento] = useState(undefined);
    const [retiro, setRetiro] = useState(undefined);
    const [potrero, setPotrero] = useState(undefined);
    const [categoria, setCategoria] = useState(undefined);
    const [carimbo, setCarimbo] = useState(0);
    const [observacion, setObservacion] = useState(undefined);
    const [categories, setCategories] = useState([]);
    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }      

    const validateParams = () => {
        return true;
    }


    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/entrance/birth", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Nacimiento registrado exitosamente", "success");
        })
        .catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear el nacimiento", "error");
        });
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    
    
    const handleSave = (e) => {
        if(validateParams()){
            let currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    birth_date: fechaNacimiento,
                    cantidad: parseInt(cantidad),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: new Date(),
                    id_categoria: parseInt(categoria),
                    id_estancia: parseInt(currentFarm.id),
                    potrero: potrero,
                    retiro: retiro,
                    observacion: observacion,
                    owner: owner ? owner.id : undefined
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveCategories();
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo nacimiento</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha de nacimiento</Form.Label>
                                    <Form.Control className="karanda-input" value={fechaNacimiento} onChange={(e) => { setFechaNacimiento(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control className="karanda-input" value={retiro} onChange={(e) => { setRetiro(e.target.value); }} type="text" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_cota">
                                    <Form.Label>Potrero</Form.Label>
                                    <Form.Control className="karanda-input" value={potrero} onChange={(e) => { setPotrero(e.target.value); }} type="text" placeholder="Ingrese el potrero" />
                                </Form.Group>
                            </Col>                        
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={ owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }}  type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observacion} onChange={(e) => {setObservacion(e.target.value);}} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default NacimientoModal;