import React, {useEffect} from "react";
import styles from "./UserCard.module.css";
import { Card } from "react-bootstrap";

const UserCard = ({ user }) => {

    useEffect(() => {
        console.log(user);
    }, [user]);

    const retrieveRol = (idRol) => {
        if(idRol == 1){
            return "SUPERVISOR";
        }
        else if(idRol == 2){
            return "ADMINISTRADOR";
        }
        else if(idRol == 3){
            return "ADMINISTRADOR INTERNO";
        }
        else if(idRol == 4){
            return "CLIENTE";
        }
        else {
            return "INDEFINIDO";
        }
    }    

    return (
        <div className={`col-md-12 ${styles.container}`}>
            <Card className={`text-center ${styles.card}`}>
                <Card.Body className={styles.cardBody}>
                    <h5 className={styles.label}>{retrieveRol(user.id_rol)}</h5>
                    <h5 className={styles.label}>Correo electrónico</h5>
                    <h5 className={styles.value}>{user.email}</h5>
                    <h5 className={styles.label}>Nombres y apellidos / Razón social</h5>
                    <h5 className={styles.value}>{`${user.datos_personales.nombres} ${user.datos_personales.apellidos}`}</h5>
                    <h5 className={styles.label}>Fecha constitución</h5>
                    <h5 className={styles.value}>{`${user.datos_personales.fecha_nacimiento == undefined ? "No registrado" : user.datos_personales.fecha_nacimiento}`}</h5>                    
                </Card.Body>
            </Card>
        </div>
    );

};

export default UserCard;