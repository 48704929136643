import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {
    Button,
    Form,
    FormGroup,
    Col
} from 'react-bootstrap';
import styles from './RecoverPassword.module.css'
import InputKaranda from '../../components/input';
import Notifications, { notify } from "react-notify-toast";
import logo from "../../assets/img/karanda.svg";

const RecoverPassword = ({ history }) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");
    //const [cookie, setCookies, removeCookie] = useCookies(['k-user']);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const goBackLogin = () => {
        history.push('/login');
    }

    const recoverUserCredential = (axiosConf, pathRecoverCredential) => {
        setLoading(true);
        axios.get(pathRecoverCredential, axiosConf).then(rs => {
            if(rs.status === 200) {
                notify.show("Se le enviará un link de confirmación a su correo electrónico", "success");
                setTimeout(() => goBackLogin(), 3000);
                setLoading(false);
            }
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            notify.show("Ocurrio un problema al tratar de recuperar su cuenta", "error");
        });
    }

    const handleRecoverCredential = (e) => {
        console.log(e);
        let axiosHeaderConf = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa"
            }
        };
        let pathRecoverCredential = process.env.REACT_APP_API_URL + 'authentication/recover?email=' + email;
        recoverUserCredential(axiosHeaderConf, pathRecoverCredential);
    }

    return (
        <>
            <Notifications />
            <div className={`col-md-12 ${styles.backgroundImgKaranda}`}>
                <div className={styles.loginContainer}>
                    <div className={`col-md-4 ${styles.loginBox}`}>
                        <div className={styles.imageContent}>
                            <img src={logo} className={styles.imgLogStyle} alt="Karanda" />
                        </div>
                        <Form className={styles.formLogin}>
                            <FormGroup className={styles.alingContentForm} controlId="formUsername">
                                <Col sm={12} xs={12}>
                                    <InputKaranda inputType={"mail"}
                                        placeHolder={"Ingrese su correo electrónico"}
                                        value={email}
                                        change={setEmail}
                                        onEnter={handleRecoverCredential}/>
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col sm={12} xs={12}>
                                    <div className={styles.alingContentForm}>
                                        <Button style={{ backgroundColor: "#4a6437", width: "100%" }}
                                            variant="success"
                                            size="sm"
                                            onClick={handleRecoverCredential}>
                                            {
                                                loading ? (
                                                    <img src={loadingBtn} height="15"/>
                                                ) : (<span>Recuperar</span>)
                                            }
                                        </Button>
                                    </div>
                                </Col>
                                <Col className={styles.alingContentForm} sm={12} xs={12}>
                                    <a onClick={goBackLogin} className={styles.retrievePasswordStyle}>Regresar</a>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RecoverPassword;