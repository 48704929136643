import React, { useState, useEffect } from "react";
import EficienciaFisicaModal from "../EficienciaFisicaModal";
import EficienciaReproductivaModal from "../EficienciaReproductivaModal";
import { Modal } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import Notifications, { notify } from "react-notify-toast";
import axios from "axios";

const IndiceModal = ( { show, handleClose, callback } ) => {

    const [ cookie, setCookies, removeCookie ] = useCookies(['kUserSession']);

    const [ eficienciaFisica, setEficienciaFisica ] = useState( undefined );
    const [ eficienciaReproductiva, setEficienciaReproductiva ] = useState( undefined );

    const [ periodo, setPeriodo ] = useState( 2022 );
    const [ observacion, setObservacion ] = useState(undefined);
    const [ step, setStep ] = useState(1);

    const [ loading, setLoading ] = useState(false);

    const handleNext = ( data ) => {
        setEficienciaFisica(data.eficienciaFisica);
        setPeriodo(data.periodo);
        setStep(2);
    }

    const handlePrevious = (e) => {
        e.preventDefault();
        setStep(1);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }        

    const handleSave = ( data ) => {
        setEficienciaReproductiva(data.eficienciaReproductiva);        
        setObservacion(data.observacion);

        const { id:idEstancia } = cookie.kUserSession.data["farm"];

        const body = {
            idEstancia,
            periodo,
            eficienciaFisica,
            eficienciaReproductiva: { ...data.eficienciaReproductiva },
            observacion: data.observacion
        }

        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "indices", body, prepareHeaders()).then(rs => {
            setLoading(false);
            callback();
            handleClose();
            notify.show("Índice registrado exitosamente", "success");
        }).
        catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar el índice", "error");
        });
    }

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">

                {
                    step === 1 
                    ? <EficienciaFisicaModal handleClose={handleClose} handleNext={handleNext} /> 
                    : <EficienciaReproductivaModal handlePrevious={handlePrevious} handleSave={handleSave} loading={loading} />
                }
                                
            </Modal>
        </>        

    )

};

export default IndiceModal;