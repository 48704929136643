import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const CustomerView = ({ show, handleClose, data, edit, callback }) => {

    const [loading, setLoading] = useState(false);
    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);    
    const [id, setId] = useState(data != undefined ? data.id : undefined);
    const [name, setName] = useState(data != undefined ? data.razon_social : undefined);
    const [documentNumber, setDocumentNumber] = useState(data != undefined ? data.ruc : undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }       

    useEffect(() => {
        setId(data != undefined ? data.id : undefined);
        setName(data != undefined ? data.razon_social : undefined);
        setDocumentNumber(data != undefined ? data.ruc : undefined);
    }, [data]);

    const handleUpdate = (e) => {
        var data = {
            data: {
                id: id,
                nombre: name,
                ruc: documentNumber
            }
        }
        update(data);
    }

    const update = (data) => {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "customer", data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                notify.show("Se ha actualizado la información exitosamente", "success");
                setLoading(false);
                callback();
                handleClose();
            }
            else {
                setLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                callback();
                handleClose();
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
        })
    }

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos del cliente</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Razón social</Form.Label>
                            <Form.Control 
                                disabled={!edit} type="text" value={name} placeholder="Ingrese el nombre del cliente"
                                onChange={(e) => {setName(e.target.value);}} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>RUC</Form.Label>
                            <Form.Control 
                                disabled={!edit} type="text" value={documentNumber} placeholder="Ingrese el ruc de la empresa"
                                onChange={(e) => {setDocumentNumber(e.target.value);}}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CustomerView;