import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const RecategorizacionView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id : undefined);
    
    const [observation, setObservation] = useState(data != undefined ? data.observacion : undefined);
    const [expenseDate, setExpenseDate] = useState(data != undefined ? data.fecha_creacion : undefined);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : undefined);
    const [cantidad, setCantidad] = useState(data != undefined ? data.cantidad : undefined);
    const [categoria, setCategoria] = useState(data != undefined ? data.categoria.nombre : undefined);
    const [tipo, setTipo] = useState(data != undefined ? data.tipo_movimiento : undefined);

    const [creationDate, setCreation] = useState(data != undefined ? data.fecha_creacion : undefined);
    const [userCreation, setUserCreation] = useState(undefined);
    const [userLastModification, setUserLastModification] = useState(undefined);

    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);        

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }      

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }        

    useEffect(() => {
        console.log(data);
        setId(data != undefined ? data.id : undefined);
        setObservation(data != undefined ? data.observacion : undefined);
        setExpenseDate(data != undefined ? data.fecha_creacion : undefined);

        setCarimbo(data != undefined ? data.carimbo : undefined);
        setCantidad(data != undefined ? data.cantidad : undefined);
        setCategoria(data != undefined ? data.categoria.nombre : undefined);
        setTipo(data != undefined ? data.tipo_movimiento : undefined);

        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);
        setOwner(data != undefined ? data.owner : undefined);              
    }, [data]);

    const handleUpdate = (e) => {
        e.preventDefault();
        let body = {

        }
    }

    useEffect(() => {
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos de la recategorizacion</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control disabled value={expenseDate} onChange={(e) => { setExpenseDate(e.target.value); }} type="datetime-local" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control disabled  as="select" value={ owner != undefined ? owner.id : undefined} >
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                                     
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control disabled value={categoria} type="text"/>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>Tipo de movimiento</Form.Label>
                                    <Form.Control disabled value={tipo == "S" ? "SALIDA" : "ENTRADA"} />
                                </Form.Group>                        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control disabled value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control disabled value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>       
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control disabled={!edit} value={observation} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="datetime-local" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default RecategorizacionView;