import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../../core/validators";
import {dateToRequestDate} from "../../../../core/date";
import styles from "./PlanCuentasModal.module.css";

const PlanCuentasModal = ({ show, handleClose, parent, callback }) => {

    const loadingBtn = require("../../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [accountType, setAccountType] = useState("P");

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const validateParams = () => {
        return true;
    }

    const save = (body) => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.post(process.env.REACT_APP_API_URL + "accountancy/chart_of_accounts?id_estancia=" + currentFarm.id, body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            handleClose();
            notify.show("Cuenta registrada exitosamente", "success");
            callback();
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear la cuenta", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            const body = {
                data: {
                    codigo: code,
                    descripcion: description,
                    tipo_cuenta: accountType,
                    cuenta_superior_id: parent != undefined ? parent.id : undefined
                }
            };
            console.log(body);
            save(body);
        }
    }

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva cuenta</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        parent != undefined ? (
                            <div style={{marginBottom: "10px"}}>
                                <span><b>Cuenta padre: </b>{parent.descripcion}</span>
                            </div>
                        ) : <></>
                    }
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="codigo">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control className="karanda-input" value={code} onChange={(e) => { setCode(e.target.value); }} type="number" placeholder="Ingrese el codigo"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="importe">
                                    <Form.Label>Tipo cuenta</Form.Label>
                                    <Form.Control as="select" className="karanda-input" value={accountType} onChange={(e) => { setAccountType(e.target.value); }}>
                                        <option value="P">Pasivo</option>
                                        <option value="A">Activo</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="cuenta">
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control className="karanda-input" value={description} onChange={(e) => {setDescription(e.target.value);}} type="text" placeholder="Ingrese la descripción"/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default PlanCuentasModal;