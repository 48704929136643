import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import {
    Button,
    Form,
    FormGroup,
    Col
} from 'react-bootstrap';
import styles from './ConfirmRegister.module.css';
import InputKaranda from '../../components/input';
import Notifications, { notify } from "react-notify-toast";
import logo from "../../assets/img/karanda.svg";

const ConfirmRegister = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [email, setEmail] = useState('');
    const [confirmToken, setConfirmToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [classValidPass, setClassValidPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const queryStr = useLocation().search;
    const queryString = require('query-string');
    const md5 = require('md5');

    useEffect(() => {
        const queryObj = queryString.parse(queryStr);
        setConfirmToken(queryObj.q);
    });

    const backToLogin = () => {
        let { history } = props;
        history.push('/login');
    }

    const validatePassword = (value) => {
        setConfPassword(value)
        setClassValidPass(newPassword == value);
        return newPassword == value;
    }

    const resetPassword = (axiosConf, data) => {
        if (classValidPass) {
            setLoading(true);
            let pathLogin = process.env.REACT_APP_API_URL + "user/confirmRegister";
            axios.post(pathLogin, data, axiosConf).then(rs => {
                notify.show("Cuenta registrada exitosamente", "success");
                setLoading(false);
                backToLogin();
            }).catch(error => {
                console.error(error.response);
                setLoading(false);
                notify.show("Ocurrio un error inesperado al tratar de confirmar la cuenta", "error");
            });
        } else {
            notify.show("Las contraseñas no coinciden", "error");            
            setClassValidPass(false);
        }

    }

    const handleRecoverPassword = () => {
        let dataRequest = { data: {} };
        dataRequest.data.email = email;
        dataRequest.data.new_password = md5(confPassword);
        dataRequest.data.access_token = confirmToken;
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa"
            }
        };
        resetPassword(axiosConfig, dataRequest);
    }

    return (
        <>
            <Notifications />
            <div className={`col-md-12 ${styles.backgroundImgKaranda}`}>
                <div className={styles.container}>
                    <div className={`col-md-4 ${styles.borederBox}`}>
                        <div className={styles.imageContent}>
                            <img src={logo} className={styles.imgLogStyle} alt="Karanda" />
                        </div>
                        <Form className={styles.formContent}>
                            <FormGroup className={styles.alingContentForm} controlId="formEmail">
                                <Col md={12} sm={12} xs={12}>
                                    <InputKaranda inputType={"email"}
                                        placeHolder={"E-mail"}
                                        value={email} onEnter={handleRecoverPassword}
                                        change={setEmail} />
                                </Col>
                            </FormGroup>
                            <FormGroup className={styles.alingContentForm} controlId="formPassword">
                                <Col md={12} sm={12} xs={12}>
                                    <InputKaranda inputType={"password"}
                                        placeHolder={"Nueva contraseña"}
                                        value={newPassword} onEnter={handleRecoverPassword}
                                        change={setNewPassword} />
                                </Col>
                            </FormGroup>
                            <FormGroup className={styles.alingContentForm} controlId="formPasswordConf">
                                <Col md={12} sm={12} xs={12}>
                                    <InputKaranda inputType={"password"}
                                        placeHolder={"Repetir contraseña"}
                                        value={confPassword} onEnter={handleRecoverPassword}
                                        change={validatePassword} />
                                </Col>
                            </FormGroup>
                            <FormGroup className={!classValidPass ? `${styles.alertPassword}` : `${styles.alertPasswordNone}`}>
                                <span style={confPassword === '' ? { display: 'none' } : {}}>Las contraseñas no coinciden</span>
                            </FormGroup>
                            <FormGroup>
                                <Col sm={12} xs={12}>
                                    <div className={styles.alingContentForm}>
                                        <Button style={{ backgroundColor: "#4a6437", width: "100%" }}
                                            variant="success"
                                            size="sm"
                                            onClick={handleRecoverPassword}>
                                            {
                                                loading ? (
                                                    <img src={loadingBtn} height="15" />
                                                ) : (<span>Confirmar cuenta</span>)
                                            }
                                        </Button>
                                    </div>
                                </Col>
                                <Col className={styles.alingContentForm} sm={12} xs={12}>
                                    <a onClick={backToLogin} className={styles.confirmPasswordStyle}>Regresar</a>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmRegister;