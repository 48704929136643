import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { HashRouter  as Router, Route, Switch, Link } from 'react-router-dom';
import Home from "./routes/Home";
import Login from "./routes/Login";
import LoginNextStep from "./routes/LoginNextStep";
import RecoverPassword from "./routes/RecoverPassword";
import PanelControl from "./routes/PanelControl";
import Usuarios from "./routes/Usuarios";
import Empresas from "./routes/Empresas";
import Clientes from "./routes/Clientes";
import Proveedores from "./routes/Proveedores";
import RecoverConfirm from "./routes/RecoverConfirm"
import ConfirmRegister from "./routes/ConfirmRegister";
import Profile from "./routes/Profile";
import Categorias from "./routes/Categorias";
import Gastos from "./routes/Gastos";
import Movimientos from "./routes/Movimientos";
import Proyecciones from "./routes/Proyecciones";
import GestionGastos from "./routes/GestionGastos";
import PlanCuentas from "./routes/PlanCuentas";
import InformeGastos from "./routes/InformeGastos";
import Entradas from "./routes/Entradas";
import Compras from "./routes/Compras";
import Nacimientos from "./routes/Nacimientos";
import TransferenciaEntrada from "./routes/TransferenciaEntrada";
import Ventas from "./routes/Ventas";
import Salidas from "./routes/Salidas";
import Mortandad from "./routes/Mortandad";
import TransferenciaSalida from "./routes/TransferenciaSalida";
import Consumo from "./routes/Consumo";
import Recategorizacion from "./routes/Recategorizacion";
import InformeMovimientos from "./routes/InformeMovimientos/InformeMovimientos";
import Indices from "./routes/Indices";
import ProyeccionesGastos from "./routes/ProyeccionesGastos";
import GestionIngresos from "./routes/GestionIngresos";
import Lluvia from "./routes/Lluvias";
import StockInicial from "./routes/StockInicial";
import Faltantes from "./routes/Faltantes";
import Sobrantes from "./routes/Sobrantes";

const router = (
    <Router>
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/recover" component={RecoverPassword} />
            <Route path="/recoverConfirm" component={RecoverConfirm} />
            <Route path="/registerConfirm" component={ConfirmRegister} />
            <Route exact path="/login/selection" component={LoginNextStep} />
            <Route exact path="/control-panel" component={PanelControl} />
            <Route exact path="/usuarios" component={Usuarios} />
            <Route exact path="/empresas" component={Empresas} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/clientes" component={Clientes} />
            <Route exact path="/proveedores" component={Proveedores} />
            <Route exact path="/categorias" component={Categorias} />
            <Route exact path="/stock" component={StockInicial} />            
            <Route exact path="/facturas" component={Gastos} />
            <Route exact path="/facturas/egresos" component={GestionGastos} />
            <Route exact path="/facturas/ingresos" component={GestionIngresos} />
            <Route exact path="/plan-cuentas" component={PlanCuentas} />
            <Route exact path="/informe-gastos" component={InformeGastos} />
            <Route exact path="/movimientos" component={Movimientos} />
            <Route exact path="/movimientos/informes" component={InformeMovimientos} />
            <Route exact path="/movimientos/recategorizaciones" component={Recategorizacion} />
            <Route exact path="/movimientos/faltantes" component={Faltantes} />
            <Route exact path="/movimientos/sobrantes" component={Sobrantes} />
            <Route exact path="/movimientos/entradas" component={Entradas} />
            <Route exact path="/movimientos/entradas/compras" component={Compras} />
            <Route exact path="/movimientos/entradas/nacimientos" component={Nacimientos} />
            <Route exact path="/movimientos/entradas/transferencias" component={TransferenciaEntrada} />
            <Route exact path="/movimientos/salidas" component={Salidas} />
            <Route exact path="/movimientos/salidas/ventas" component={Ventas} />
            <Route exact path="/movimientos/salidas/mortandad" component={Mortandad} />
            <Route exact path="/movimientos/salidas/transferencias" component={TransferenciaSalida} />
            <Route exact path="/movimientos/salidas/consumo" component={Consumo} />
            <Route exact path="/proyecciones" component={Proyecciones} />
            <Route exact path="/proyecciones/productividad" component={Indices} />
            <Route exact path="/proyecciones/gastos" component={ProyeccionesGastos} />
            <Route exact path="/movimientos/lluvias" component={Lluvia} />
            <Route exact path="/" component={Home}/>
        </Switch>
    </Router>
);

ReactDOM.render(router, document.getElementById("root"));
serviceWorker.unregister();