import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const MortandadView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [id, setId] = useState(data != undefined ? data.id_movimiento : undefined);
    const [idMortandad, setIdMortandad] = useState(data != undefined ? data.id_mortandad : undefined);

    const [cantidad, setCantidad] = useState(data != undefined ? data.cantidad : undefined);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : undefined);
    const [categoria, setCategoria] = useState(data != undefined ? data.categoria.id_categoria : undefined);
    const [categorias, setCategorias] = useState([]);
    const [estancia, setEstancia] = useState(data != undefined ? data.estancia.id : undefined);
    const [fechaMovimiento, setFechaMovimiento] = useState(data != undefined ? data.fecha_movimiento : undefined);
    const [retiro, setRetiro] = useState(data != undefined ? data.retiro.nombre : undefined);
    const [potrero, setPotrero] = useState(data != undefined ? data.potrero : undefined);
    const [nroCaravana, setNroCaravana] = useState(data != undefined ? data.nro_caravana : undefined);
    const [causaProbable, setCausaProbable] = useState(data != undefined ? data.causa_probable : undefined);
    const [observation, setObservation] = useState(data != undefined ? data.observation : undefined);

    const [creationDate, setCreation] = useState(data != undefined ? data.fecha_creacion : undefined);
    const [userCreation, setUserCreation] = useState(data != undefined ? data.usuario_creacion.email : undefined);
    const [userLastModification, setUserLastModification] = useState(data != undefined ? data.usuario_ultima_modificacion.email : undefined);
    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }      

    useEffect(() => {
        console.log(data);
        setId(data != undefined ? data.id_movimiento : undefined);
        setIdMortandad(data != undefined ? data.id_mortandad : undefined);

        setCantidad(data != undefined ? data.cantidad : undefined);
        setCarimbo(data != undefined ? data.carimbo : undefined);
        setCategoria(data != undefined ? data.categoria.id_categoria : undefined);
        setEstancia(data != undefined ? data.estancia.id : undefined);
        setFechaMovimiento(data != undefined ? data.fecha_movimiento : undefined);
        setRetiro(data != undefined ? data.retiro.nombre : undefined);
        setPotrero(data != undefined ? data.potrero : undefined);
        setNroCaravana(data != undefined ? data.nro_caravana : undefined);
        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined ? data.usuario_ultima_modificacion.email : undefined);

        setCausaProbable(data != undefined ? data.causa_probable : undefined);
        setObservation(data != undefined ? data.observation : undefined);
        setOwner(data != undefined ? data.owner : undefined);                
        
    }, [data]);

    const handleUpdate = (e) => {
        var currentFarm = cookie.kUserSession.data["farm"];
        var data = {
            data: {
                cantidad: cantidad,
                carimbo: carimbo,
                causa_probable: causaProbable,
                fecha_movimiento: fechaMovimiento,
                id_categoria: categoria,
                id_estancia: currentFarm.id,
                id_mortandad: idMortandad,
                id_movimiento: id,
                nro_caravana: nroCaravana,
                observacion: observation,
                potrero: potrero,
                retiro: retiro,
                owner: owner ? owner.id : undefined                
            }
        }
        update(data);        
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/entrance/decease", data, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                console.log(data);
                setCategorias(data);
            }
            else {
                setCategorias([]);
            }
        })
        .catch(error => {
            console.error(error);
            setCategorias([]);
        });
    }    

    useEffect(() => {
        retrieveCategories();
        retrieveOwners();        
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos del deceso</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control disabled={!edit} value={fechaMovimiento} onChange={(e) => { setFechaMovimiento(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>Caravana N°</Form.Label>
                                    <Form.Control disabled={!edit} value={nroCaravana} onChange={(e) => { setNroCaravana(e.target.value); }} type="text" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_cota">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control disabled={!edit} value={retiro} onChange={(e) => { setRetiro(e.target.value); }} type="text"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Potrero</Form.Label>
                                    <Form.Control disabled={!edit} value={potrero} onChange={(e) => {setPotrero(e.target.value)}} type="text"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control disabled={!edit} value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0} disabled selected>Seleccione</option>
                                        {
                                            categorias.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                                
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control disabled={!edit} value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Causa probable</Form.Label>
                                    <Form.Control disabled={!edit} value={causaProbable} onChange={(e) => { setCausaProbable(e.target.value); }} type="text" placeholder="Ingrese causa probable" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control disabled={!edit} value={observation} onChange={(e) => { setObservation(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="date-time" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default MortandadView;