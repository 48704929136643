import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./StockModal.module.css";

const StockModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(undefined);
    const [categories, setCategories] = useState([]);
    const [categoria, setCategoria] = useState(0);    
    const [cantidad, setCantidad] = useState(0);
    const [observacion, setObservacion] = useState(undefined);
    const [carimbo, setCarimbo] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const validateParams = () => {
/*         let haveError = false;
        if(!validateEmail(email)){
            setEmailError(true);
            haveError = true;
        }
        else { setEmailError(false); }
        if(!validateField(personType == "J", 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        if(!validateField(false, 3, 50, lastName)){
            setLastNameError(true);
            haveError = true;
        }
        else { setLastNameError(false); } */
/*         if(!validateDate(birthDay)){
            setBirthDayError(true);
            haveError = true;
        } */
/*         if(!validateField(true, 5, 10, documentNumber)){
            setDocumentNumberError(true);
            haveError = true;
        }
        else { setDocumentNumberError(false); }
        return !haveError; */
        return true;
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }    

    const save = (body) => {
        setLoading(true);
        console.log(body);
        axios.post(process.env.REACT_APP_API_URL + "movements/initial/stock", body, prepareHeaders())
        .then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Stock inicial registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            if(error.response != undefined){
                notify.show(error.response.data, "warning");
            }
            else {
                notify.show("Ha ocurrido un error al registrar el stock inicial", "error");
            }
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            var currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    cantidad: parseInt(cantidad),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: date,
                    id_categoria: parseInt(categoria),
                    id_estancia: currentFarm.id,
                    observacion: observacion
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveCategories();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><b>Registro de stock inicial para {cookie.kUserSession.data["farm"].nombre}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={date} onChange={(e) => { setDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">

                                        <option value={0} selected disabled>Seleccione la categoría</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }

                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observacion} onChange={(e) => { setObservacion(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default StockModal;