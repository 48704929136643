import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row, Card } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import Notifications, { notify } from "react-notify-toast";
import karanda from '../../assets/img/karanda-fond.png';
import Header from "../../components/header";
import History from "../../components/history";
import { checkSession } from "../../core/session";
import styles from "./Profile.module.css";
import ProfileImgModal from "./ProfileModal";
import {MdChildCare} from "react-icons/md";

const Profile = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const [updateLoading, setUpdateLoading] = useState(false);

    const [imageResize, setImageResize] = useState(undefined);
    const [imgModalShow, setImgModalShow] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveProfile = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "user/" + cookie.kUserSession.data.id, prepareHeaders())
            .then(rs => {
                console.log(rs);
                if (rs.status == 200) {
                    let { data } = rs;
                    console.log(data);
                    setLoading(false);
                    let {datos_personales:{imagen_perfil}} = data;
                    setImageResize(imagen_perfil === "" || imagen_perfil === undefined ? undefined : imagen_perfil);
                    setProfile(data);
                }
                else {
                    setLoading(false);
                    setProfile(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setProfile(undefined);
            })
    }


    useEffect(() => {
        checkSession("profile", props.history, cookie.kUserSession);
        retrieveProfile();
    }, []);

    const resolvePersonType = (personType) => {
        if (personType == "F") {
            return "Persona Física";
        }
        else if (personType == "J") {
            return "Persona Jurídica";
        }
        return "";
    }

    const handleUpdate = () => {
        var data = prepareUpdate();
        update(data, profile.id);
    }

    const prepareUpdate = () => {
        var data = {
            data: {
                id: profile.id,
                apellidos: profile.datos_personales.apellidos,
                nombres: profile.datos_personales.nombres,
                nro_documento: profile.datos_personales.nro_documento,
                fecha_nacimiento: profile.datos_personales.fecha_nacimiento,
                foto: imageResize === undefined ? undefined : window.btoa(imageResize)
            }
        }
        return data;
    }

    const update = (data, id) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "user/profile", data, prepareHeaders())
            .then(rs => {
                console.log(rs);
                if (rs.status == 200) {
                    let { data } = rs;
                    notify.show("Se ha actualizado la información exitosamente", "success");
                    setUpdateLoading(false);
                    setEdit(false);
                }
                else {
                    setUpdateLoading(false);
                    setEdit(false);
                    notify.show("Ha ocurrido un error al actualizar la información", "error");
                }
            })
            .catch(error => {
                console.error(error);
                setEdit(false);
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
            })
    }

    return (
        <>
            <ProfileImgModal show={imgModalShow}
                handleClose={() => setImgModalShow(!imgModalShow)}
                getNewImg={setImageResize} />
            <Notifications />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Perfil"} history={props.history} />
                <section className={styles.content}>
                    <Form>
                        <Row>
                            <Col>
                                <h1 className={styles.title}>Perfil &nbsp;</h1>
                            </Col>
                            <Col className="text-right">
                                <Form.Check
                                    value={edit}
                                    type="switch"
                                    id="custom-switch"
                                    label="Editar"
                                    onChange={() => { setEdit(!edit); }}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <section className={styles.actions}>
                        {
                            loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                profile == undefined ? (<span>No se han encontrado datos del usuario</span>) : (
                                    <>
                                        <h5>{resolvePersonType(profile.datos_personales.tipo_persona)}</h5>
                                        {
                                            profile.datos_personales.tipo_persona == "F" ? (
                                                <Form style={{ display: 'flex' }}>
                                                    <Col md={8}>
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Nombres</Form.Label>
                                                                    <Form.Control
                                                                        type="text" value={profile.datos_personales.nombres} disabled={!edit}
                                                                        onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, nombres: e.target.value } }) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Apellidos</Form.Label>
                                                                    <Form.Control
                                                                        type="text" value={profile.datos_personales.apellidos} disabled={!edit}
                                                                        onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, apellidos: e.target.value } }) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Correo electrónico</Form.Label>
                                                                    <Form.Control type="text" value={profile.email} disabled />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Fecha de nacimiento</Form.Label>
                                                                    <Form.Control
                                                                        type="date" value={profile.datos_personales.fecha_nacimiento} disabled={!edit}
                                                                        onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, fecha_nacimiento: e.target.value } }) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>N° Documento</Form.Label>
                                                                    <Form.Control
                                                                        type="text" value={profile.datos_personales.nro_documento} disabled={!edit}
                                                                        onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, nro_documento: e.target.value } }) }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={4} className="text-center">
                                                        <Card.Body style={{float: 'right'}}>
                                                            <div>
                                                                {imageResize === undefined ? <MdChildCare className="rounded-circle" style={{fontSize: '6.4em', color: '#e9ecef'}}/> : <Image alt="profile" src={imageResize} style={{ height: '100%', width: '50%', opacity: edit ? '' : '0.6'}} roundedCircle />}
                                                            </div>
                                                            <div>
                                                                <Form.Label onClick={() => setImgModalShow(edit ? !imgModalShow : imgModalShow)} style={{cursor: edit ? 'pointer' : 'default', color: edit ? '' : '#e9ecef'}}>Cambiar foto de perfil</Form.Label>
                                                            </div>
                                                        </Card.Body>
                                                    </Col>
                                                </Form>
                                            ) : (
                                                    <Form style={{ display: 'flex' }}>
                                                        <Col md={8}>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Razón social</Form.Label>
                                                                        <Form.Control
                                                                            type="text" value={profile.datos_personales.apellidos} disabled={!edit}
                                                                            onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, apellidos: e.target.value } }) }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Correo electrónico</Form.Label>
                                                                        <Form.Control type="text" value={profile.email} disabled />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Fecha de constitución</Form.Label>
                                                                        <Form.Control
                                                                            type="date" value={profile.datos_personales.fecha_nacimiento} disabled={!edit}
                                                                            onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, fecha_nacimiento: e.target.value } }) }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>RUC</Form.Label>
                                                                        <Form.Control
                                                                            type="text" value={profile.datos_personales.nro_documento} disabled={!edit}
                                                                            onChange={(e) => { setProfile({ ...profile, datos_personales: { ...profile.datos_personales, nro_documento: e.target.value } }) }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={4} className="text-center">
                                                            <Card.Body style={{float: 'right'}}>
                                                                <div>
                                                                    {imageResize === undefined ? <MdChildCare className="rounded-circle" style={{fontSize: '6.4em', color: '#e9ecef'}}/> : <Image alt="profile" src={imageResize} style={{ height: '100%', width: '50%', opacity: edit ? '' : '0.6' }} roundedCircle />}
                                                                </div>
                                                                <div>
                                                                    <Form.Label onClick={() => setImgModalShow(edit ? !imgModalShow : imgModalShow)} style={{cursor: edit ? 'pointer' : 'default', color: edit ? '' : '#e9ecef'}}>Cambiar foto de perfil</Form.Label>
                                                                </div>
                                                            </Card.Body>
                                                        </Col>
                                                    </Form>
                                                )
                                        }

                                    </>
                                )
                            )
                        }
                        {
                            edit ? (

                                <Button className="karanda-btn" onClick={handleUpdate}>
                                    {
                                        updateLoading ? (
                                            <img src={loadingBtn} height="15" />
                                        ) : (<span>Actualizar</span>)
                                    }
                                </Button>

                            ) : <></>
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Profile;