import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./UserModal.module.css";

const UserModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [personType, setPersonType] = useState("F");
    const [name, setName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [birthDay, setBirthDay] = useState(undefined);
    const [documentNumber, setDocumentNumber] = useState(undefined);
    const [role, setRole] = useState(1);

    const [nameError, setNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [documentNumberError, setDocumentNumberError] = useState(false);
    const [birthDayError, setBirthDayError] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const validateParams = () => {
        let haveError = false;
        if(!validateEmail(email)){
            setEmailError(true);
            haveError = true;
        }
        else { setEmailError(false); }
        if(!validateField(personType == "J", 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        if(!validateField(false, 3, 50, lastName)){
            setLastNameError(true);
            haveError = true;
        }
        else { setLastNameError(false); }
/*         if(!validateDate(birthDay)){
            setBirthDayError(true);
            haveError = true;
        } */
        if(!validateField(true, 5, 10, documentNumber)){
            setDocumentNumberError(true);
            haveError = true;
        }
        else { setDocumentNumberError(false); }
        return !haveError;
    }

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "user", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Usuario registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear el usuario", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            const body = {
                data: {
                    nombres: personType == "F" ? name : null,
                    apellidos: lastName,
                    email: email,
                    estado: "A",
                    tipo_persona: personType,
                    rol: role,
                    nro_documento: documentNumber,
                    contactos: [],
                    fecha_nacimiento: birthDay != undefined ? dateToRequestDate(birthDay) : undefined
                }
            };
            save(body);
        }
    }

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo usuario</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="personType">
                                    <Form.Label>Tipo de persona</Form.Label>
                                    <Form.Control as="select" value={personType} onChange={(e) => { setPersonType(e.target.value); }}>
                                        <option value="F">Persona física</option>
                                        <option value="J">Persona juridica</option>
                                    </Form.Control>
                                </Form.Group>                               
                            </Col>
                            <Col>
                                <Form.Group controlId="email">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control className={emailError ? styles.error : ""} value={email} type="email" onChange={(e) => {setEmail(e.target.value);}} placeholder="Ingrese el correo electrónico" />
                                </Form.Group>                            
                            </Col>
                        </Row>
                        {
                            personType == "F" ? (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="name">
                                                <Form.Label>Nombres</Form.Label>
                                                <Form.Control className={nameError ? styles.error : ""} value={name} onChange={(e) => {setName(e.target.value);}} type="text" placeholder="Ingrese el nombre" />
                                            </Form.Group>                                        
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="lastname">
                                                <Form.Label>Apellidos</Form.Label>
                                                <Form.Control className={lastNameError ? styles.error : ""} value={lastName} onChange={(e) => {setLastName(e.target.value);}} type="text" placeholder="Ingrese el apellido" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="bday">
                                                <Form.Label>Fecha de nacimiento</Form.Label>
                                                <Form.Control className={birthDayError ? styles.error : ""} value={birthDay} onChange={(e) => {setBirthDay(e.target.value);}} type="date" />
                                            </Form.Group>                                        
                                        </Col>  
                                        <Col>
                                            <Form.Group controlId="documentNumber">
                                                <Form.Label>Nro de documento</Form.Label>
                                                <Form.Control className={documentNumberError ? styles.error : ""} value={documentNumber} onChange={(e) => {setDocumentNumber(e.target.value);}} type="text" placeholder="Ingrese su número de documento" />
                                            </Form.Group>                                        
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="role">
                                                <Form.Label>Rol</Form.Label>
                                                <Form.Control as="select" value={role} onChange={(e) => { setRole(e.target.value); }}>
                                                    <option value={2}>ADMINISTRADOR</option>                                                    
                                                    <option value={3}>ADMINISTRADOR INTERNO</option>
                                                    <option value={4}>CLIENTE</option>                                                    
                                                    <option value={1}>SUPERVISOR</option>
                                                </Form.Control>
                                            </Form.Group>                                           
                                        </Col>                                              
                                    </Row>
                                </>

                            ) : (
                                <>
                                    <Form.Group controlId="lastname">
                                        <Form.Label>Razón social</Form.Label>
                                        <Form.Control className={lastNameError ? styles.error : ""} value={lastName} onChange={(e) => {setLastName(e.target.value);}} type="text" placeholder="Ingrese la razón social" />
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="bday">
                                                <Form.Label>Fecha de constitucion</Form.Label>
                                                <Form.Control className={birthDayError ? styles.error : ""} value={birthDay} onChange={(e) => {setBirthDay(e.target.value);}} type="date" />
                                            </Form.Group>                                        
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="documentNumber">
                                                <Form.Label>RUC</Form.Label>
                                                <Form.Control className={documentNumberError ? styles.error : ""} value={documentNumber} onChange={(e) => {setDocumentNumber(e.target.value);}} type="text" placeholder="Ingrese el RUC" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="role">
                                                <Form.Label>Rol</Form.Label>
                                                <Form.Control as="select" value={role} onChange={(e) => { setRole(e.target.value); }}>
                                                    <option value={2}>ADMINISTRADOR</option>                                                    
                                                    <option value={3}>ADMINISTRADOR INTERNO</option>
                                                    <option value={4}>CLIENTE</option>                                                    
                                                    <option value={1}>SUPERVISOR</option>
                                                </Form.Control>
                                            </Form.Group>                                           
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }                               
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default UserModal;