import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Usuarios.module.css";
import History from "../../components/history";
import { Button, Form, Table, Pagination, Image } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import roundAssignment from '@iconify/icons-ic/round-assignment';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import UserModal from "./UserModal";
import UserView from "./UserView";
import UserCard from "./UserCard";
import UserAsignations from "./UserAsignations";
import DeleteConfirmation from "./DeleteConfirmation";
import {MdImage} from "react-icons/md";

const Usuarios = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [showAsignations, setAsignationsVisibility] = useState(false);
    const [companiesLoading, setCompaniesLoading] = useState(false);    
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(0);    
    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [user, setUser] = useState(undefined);
    const [rol, setRol] = useState(0);
    const [edit, setEdit] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Panel de control",
            path: "/control-panel"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveCompanies = () => {
        setCompaniesLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "company", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                setCompaniesLoading(false);
                setCompanies(data);
            }
            else {
                setCompaniesLoading(false);
                setCompanies([]);
            }
        })
        .catch(error => {
            console.error(error);
            setCompaniesLoading(false);
            setCompanies([]);
        });
    }


    const retrieveUsers = () => {
        setLoading(true);
        var url =  "user?order=ASC&order_by=idUsuario&page=" + page + "&page_size=" + pageSize;
        if(rol != 0){
            url += "&rol=" + rol;
        }
        if(company != 0){
            console.log(company);
            url += "&empresa=" + company;
        }
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { content } = rs.data;
                setLoading(false);
                setTotalPages(rs.data.totalPages);
                setUsers(content);
            }
            else {
                setLoading(false);
                setTotalPages(1);
                setUsers([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setTotalPages(1);
            setUsers([]);
        });
    }

    const retrievePersonType = (personType) => personType == "F" ? "Persona física" : "Persona jurídica";

    const retrieveRol = (idRol) => {
        if(idRol == 1){
            return "SUPERVISOR";
        }
        else if(idRol == 2){
            return "ADMINISTRADOR";
        }
        else if(idRol == 3){
            return "ADMINISTRADOR INTERNO";
        }
        else if(idRol == 4){
            return "CLIENTE";
        }
        else {
            return "INDEFINIDO";
        }
    }

    const openUserDetailView = (e, i) => {
        setUser(i);
        setUserDetailVisibility(true);
    }    

    const openUserEdit = (e, i) => {
        setUser(i);
        setUserDetailVisibility(true);
        setEdit(true);
    }

    const openUserAsignations = (e, i) => {
        setUser(i);
        setAsignationsVisibility(true);
    }

    const handleDelete = (e, i) => {
        setUser(i);
        setDeleteVisibility(true);
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveUsers();
        retrieveCompanies();
    }, []);

    useEffect(() => {
        retrieveUsers();
    }, [page, pageSize]);

    const toPage = (page) => {
        console.log("Setting page to ", page);
        setPage(page);
    }

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => {setDeleteVisibility(false);}} data={user} callback={retrieveUsers}/>
            <UserView show={showUserDetail} edit={edit} handleClose={() => {setUserDetailVisibility(false); setEdit(false); }} data={user} callback={retrieveUsers}/>
            <UserModal show={showModal} handleClose={() => {setModalVisibility(false); }} callback={retrieveUsers}/>
            <UserAsignations show={showAsignations} handleClose={() => {setAsignationsVisibility(false);} } data={user} callback={retrieveUsers}/>
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Gestión de usuarios"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Gestión de usuarios</h1>
                    <section className={styles.actions}>
                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => {setModalVisibility(true);}}>Registrar usuario</Button>
                        <Form className={styles.searchForm}>
                            <Form.Row style={{ margin: "0px" }}>
                                <Form.Group style={{ margin: "0px" }}>
                                    <Form.Label htmlFor="rol" className={styles.label}>Rol</Form.Label>
                                    <Form.Control id="rol" className={styles.input} value={rol} onChange={(e) => {setRol(e.target.value)}} as="select" style={{ margin: "0px" }}>
                                        <option value={0}>TODOS</option>
                                        <option value={1}>SUPERVISOR</option>
                                        <option value={2}>ADMINISTRADOR</option>
                                        <option value={3}>ADMINISTRADOR INTERNO</option>
                                        <option value={4}>CLIENTE</option>
                                    </Form.Control>
                                </Form.Group>&nbsp;&nbsp;&nbsp;
                                <Form.Group style={{ margin: "0px" }}>
                                    <Form.Label htmlFor="empresa" className={styles.label}>Empresa</Form.Label>
                                    <Form.Control id="empresa" className={styles.input} value={company} onChange={(e) => {setCompany(e.target.value);}} as="select" style={{ margin: "0px" }}>
                                        <option value={0}>TODAS</option>
                                        {
                                            companies.map((i) => (
                                                <option value={i.id}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>&nbsp;&nbsp;&nbsp;
                                <Button className="karanda-btn" style={{height: "fit-content", marginTop: "auto"}} onClick={retrieveUsers}>Buscar</Button>
                            </Form.Row>
                        </Form>
                        {
                            loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (

                                users.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    isMobile() ? (
                                        users.map((i) => (
                                            <UserCard user={i}/>
                                        ))
                                    ) : (
                                        <>
                                            <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                                <thead>
                                                    <th>Perfil</th>
                                                    <th>Correo electr&oacute;nico</th>
                                                    <th>Rol</th>
                                                    <th>Fecha de constitución</th>
                                                    <th>Tipo de persona</th>
                                                    <th>Nombres y apellidos / Razón social</th>
                                                    <th>Acciones</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        users.map((i) => (
                                                            <tr>
                                                                <td>{i.datos_personales.imagen_perfil === "" || i.datos_personales.imagen_perfil === undefined ? <MdImage className="rounded-circle" style={{fontSize: '3em', color: '#e9ecef'}}/> : <Image alt="profile" src={i.datos_personales.imagen_perfil} style={{ height: 'auto', width: '15%'}} roundedCircle />}</td>
                                                                <td style={{textAlign: "left"}}>{i.email}</td>
                                                                <td>{retrieveRol(i.id_rol)}</td>
                                                                <td>{i.datos_personales.fecha_nacimiento}</td>
                                                                <td>{retrievePersonType(i.datos_personales.tipo_persona)}</td>
                                                                <td>{i.datos_personales.tipo_persona == "F" ? i.datos_personales.nombres + " " + i.datos_personales.apellidos : i.datos_personales.apellidos}</td>
                                                                <td>
                                                                    <div style={{display: 'inline-flex'}}>
                                                                        {
                                                                            i.id_rol == 1 ? (<></>) : (
                                                                                <Icon
                                                                                    className={styles.icon} 
                                                                                    onClick={(e) => {openUserAsignations(e, i);}} 
                                                                                    icon={roundAssignment} height="1.4em"
                                                                                />
                                                                            )
                                                                        }
                                                                        <Icon className={styles.icon} onClick={(e) => {openUserDetailView(e, i);}} icon={eyeFilled} height="1.4em" />
                                                                        <Icon className={styles.icon} onClick={(e) => {openUserEdit(e, i);}} icon={editFilled} height="1.4em" />
                                                                        <Icon className={styles.icon} onClick={(e) => {handleDelete(e, i);}} icon={bxsTrash} height="1.4em" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                            <Pagination className="karanda-pagination">
{/*                                                 <Pagination.First />
                                                <Pagination.Prev /> */}

                                                    {
                                                        [...Array(totalPages).keys()].map( (i) => (
                                                            <Pagination.Item onClick={() => {toPage(i+1);}} active={(i+1) == page}>{i+1}</Pagination.Item>
                                                        ))
                                                    }
                                                
{/*                                                 <Pagination.Next />
                                                <Pagination.Last /> */}
                                            </Pagination>
                                        </>
                                    )
                                )
                            )
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Usuarios;