import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import { dateToRequestDate } from "../../../core/date";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const LluviaView = ({ show, handleClose, data, edit, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [fechaLluvia, setFechaLluvia] = useState(new Date());
    const [milimetros, setMilimetros] = useState('');
    const [idLluvia, setIdLluvia] = useState(null);

    useEffect(() => {
        setFechaLluvia(data != undefined ? dateToRequestDate(data.fecha_lluvia) : new Date());
        setMilimetros(data != undefined ? data.milimetros : "0");
        setIdLluvia(data != undefined ? data.id_lluvia : 0);
    }, [data]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    };

    const update = (data) => {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "rain", data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs;
                callback();
                notify.show("Se ha actualizado la información exitosamente", "success");
                setLoading(false);
            }
            else {                
                callback();
                setLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
            }
            handleClose();
        })
        .catch(error => {
            callback();
            console.error(error.response);
            setLoading(false);
            notify.show("Ha ocurrido un error fatal al actualizar la información", "error");
            handleClose();
        })        
    }

    const handleUpdate = (e) => {
        let currentFarm = cookie.kUserSession.data["farm"];
        var body = {
            data: {
                milimetros: milimetros,
                fecha_lluvia: fechaLluvia,
                id_estancia: currentFarm.id,
                id_lluvia: idLluvia
            }
        }
        update(body);
    }

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo registro de lluvia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha-lluvia">
                                    <Form.Label>Fecha de lluvia</Form.Label>
                                    <Form.Control disabled={!edit} className="karanda-input" value={fechaLluvia}
                                        onChange={(e) => setFechaLluvia(dateToRequestDate(e.target.value))} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="milimetros">
                                    <Form.Label>Milimetros</Form.Label>
                                    <Form.Control disabled={!edit} className="karanda-input" value={milimetros}
                                        onChange={(e) => setMilimetros(e.target.value)} type="text"
                                        placeholder="Ingrese cant. milimetros" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    { edit
                     ? <Button variant="primary"
                        onClick={handleUpdate}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15" />
                            ) : (<span>{edit ? 'Actualizar' : 'Registrar' }</span>)
                        }
                    </Button>
                     : <></>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default LluviaView;