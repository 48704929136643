import React, { useState, useEffect } from "react";
import styles from "./TimeFilter.module.css";
import { Form, Col, Row } from "react-bootstrap";
import {currentMonth, currentYear, dateToRequestDate} from "../../core/date";

const TimeFilter = ({ callback }) => {

    const [tipoFiltroTiempo, setTipoFiltroTiempo] = useState(0);
    const [fechaInicio, setFechaInicio] = useState(undefined);
    const [fechaFin, setFechaFin] = useState(undefined);
    const [mes, setMes] = useState(currentMonth());
    const [year, setYear] = useState(currentYear());

    const resolverFiltros = () => {
        if(tipoFiltroTiempo == 0){
            return (<></>);
        }
        else if(tipoFiltroTiempo == 1){
            return (
                <>
                    <Row>
                        <Col md={12}>
                            <Form.Group style={{marginBottom: "15px"}}>
                                <Form.Label htmlFor="fechaInicio" className={styles.label}>Fecha inicio</Form.Label>
                                <Form.Control id="fechaInicio" className={"karanda-input"} value={fechaInicio} onChange={(e) => {setFechaInicio(e.target.value); }} type="date"/>
                            </Form.Group>                                
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group style={{marginBottom: "15px"}}>
                                <Form.Label htmlFor="fechaFin" className={styles.label}>Fecha fin</Form.Label>
                                <Form.Control id="fechaFin" className={"karanda-input"} value={fechaFin} onChange={(e) => {setFechaFin(e.target.value); }} type="date"/>
                            </Form.Group>                                
                        </Col>                    
                    </Row>
                </>
            );
        }
        else if(tipoFiltroTiempo == 2){
            return (
                <>
                    <Row>
                        <Col md={12}>
                            <Form.Group style={{marginBottom: "15px"}}>
                                <Form.Label htmlFor="mes" className={styles.label}>Mes</Form.Label>
                                <Form.Control id="mes" className={"karanda-input"} as="select" value={mes} onChange={(e) => {setMes(e.target.value);}}>
                                    <option value={1}>Enero</option>
                                    <option value={2}>Febrero</option>
                                    <option value={3}>Marzo</option>
                                    <option value={4}>Abril</option>
                                    <option value={5}>Mayo</option>
                                    <option value={6}>Junio</option>
                                    <option value={7}>Julio</option>
                                    <option value={8}>Agosto</option>
                                    <option value={9}>Septiembre</option>
                                    <option value={10}>Octubre</option>
                                    <option value={11}>Noviembre</option>
                                    <option value={12}>Diciembre</option>
                                </Form.Control>
                            </Form.Group>                                
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group style={{marginBottom: "15px"}}>
                                <Form.Label htmlFor="year" className={styles.label}>Año</Form.Label>
                                <Form.Control id="year" className={"karanda-input"} type="number" value={year} onChange={(e) => {setYear(e.target.value); }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            );
        }
        else if(tipoFiltroTiempo == 3){
            return (
                <Row>
                    <Col md={12}>
                        <Form.Group style={{marginBottom: "15px"}}>
                            <Form.Label htmlFor="year" className={styles.label}>Año</Form.Label>
                            <Form.Control id="year" className={"karanda-input"} type="number" value={year} onChange={(e) => {setYear(e.target.value); }}/>
                        </Form.Group>                                
                    </Col>
                </Row>
            );
        }        
    }

    useEffect(() => {
        if(year != undefined && mes != undefined){

            var inicio = new Date();
            inicio.setUTCFullYear(year);
            inicio.setDate(1);
            inicio.setUTCMonth(mes-1);
            inicio = dateToRequestDate(inicio);

            var fin = new Date(year, mes, 0);
            fin = dateToRequestDate(fin);

            setFechaInicio(inicio);
            setFechaFin(fin);            
        }
        else if(year != undefined && mes == undefined){

            var inicio = new Date();
            inicio.setUTCFullYear(year);
            inicio.setDate(1);
            inicio.setUTCMonth(0);
            inicio = dateToRequestDate(inicio);

            var fin = new Date(year, 12, 0);
            fin = dateToRequestDate(fin);

            setFechaInicio(inicio);
            setFechaFin(fin);
        }
    }, [mes, year]);

    useEffect(() => {
        if(year != undefined && mes != undefined){

            var inicio = new Date();
            inicio.setUTCFullYear(year);
            inicio.setUTCDate(1);
            inicio.setUTCMonth(mes-1);
            inicio = dateToRequestDate(inicio);

            var fin = new Date(year, mes, 0);
            fin = dateToRequestDate(fin);

            setFechaInicio(inicio);
            setFechaFin(fin);
        }
    }, []);

    useEffect(() => {
        callback(fechaInicio, fechaFin);
    }, [fechaInicio, fechaFin]);

    useEffect(() => {
        if(tipoFiltroTiempo == 3){
            setMes(undefined);
        }
        else if(tipoFiltroTiempo == 0){
            setFechaInicio(undefined);
            setFechaFin(undefined);
        }
    }, [tipoFiltroTiempo]);

    const changeTipoFiltroTiempo = (e) => {
        setTipoFiltroTiempo(e.target.value);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Form.Group style={{marginBottom: "15px"}}>
                        <Form.Label htmlFor="account" className={styles.label}>Tipo de filtro de tiempo</Form.Label>
                        <Form.Control value={tipoFiltroTiempo} onChange={changeTipoFiltroTiempo} id="account" className={"karanda-input"} as="select">
                            <option value={0}>Ninguno</option>
                            <option value={1}>Rango de fechas</option>
                            <option value={2}>Mes específico</option>
                            <option value={3}>Año específico</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            { resolverFiltros() }
        </>
    );

};

export default TimeFilter;