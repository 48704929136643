export const checkSession = (source, history, cookie) => {
    if(cookie == null || cookie == undefined || cookie.data == null || cookie.data == undefined){
        if(source != "login"){
            history.push("/login");
            return;
        }
    }
    else {
        const {data} = cookie;
        if(data["company"] == undefined || data["farm"] == undefined){
            history.push("/login/selection");
            return;
        }
        else {
            if(source == "login" || source == "selection"){
                history.push("/");
                return;
            }
        }
    }

}

export const isMobile = () => {
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
