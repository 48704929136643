import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Gastos.module.css";
import MenuCard from "../../components/menu-card";
import History from "../../components/history";
import moneyDeposit from '@iconify/icons-vaadin/money-deposit';
import documentIcon from '@iconify/icons-jam/document';
import taskList from '@iconify/icons-jam/task-list';
import {checkSession} from "../../core/session";


const Gastos = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]    

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isClient = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 4;
    }    

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 3;
    }    

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Ingresos/Egresos"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Ingresos/Egresos</h1>
                    <section className={styles.menuGroup}>
                        {
                            (isSupervisor() || isInternalAdmin() || isClient()) ? (
                                <MenuCard history={props.history} menu="Informes" representation={documentIcon} path={"/informe-gastos"}/>
                            ) : (<></>)
                        }
                        <MenuCard history={props.history} menu="Plan de cuentas" representation={taskList} path={"/plan-cuentas"}/>
                        <MenuCard history={props.history} menu="Ingresos" representation={moneyDeposit} path={"/facturas/ingresos"}/>
                        <MenuCard history={props.history} menu="Egresos" representation={moneyDeposit} path={"/facturas/egresos"}/>
                    </section>
                </section>
            </section>                
        </>
    );

};

export default Gastos;