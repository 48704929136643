import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Indices.module.css";
import History from "../../components/history";
import { Button, Form, Row } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import { displayDate } from "../../core/date";
import TimeFilter from "../../components/time-filter"
import {formatAmount} from "../../core/amount";
import { TipoVenta } from "../../core/tipoVenta";
import EficienciaFisica from "./EficienciaFisica";
import EficienciaReproductiva from "./EficienciaReproductiva";
import Indice from "./Indice";
import IndiceModal from "./IndiceModal";
import Notifications from "react-notify-toast";

const Indices = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [anho, setAnho] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const [indices, setIndices] = useState([]);
    const [ show, setShow ] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Proyecciones",
            path: "/proyecciones"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const findIndices = () => {
        setLoading(true);
        let query = anho == undefined ? "" : "periodo=" + anho;
        axios.get(process.env.REACT_APP_API_URL + "indices?" + query, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs;
                setLoading(false);
                setIndices(data);
            }
            else {
                setLoading(false);
                setIndices([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setIndices([]);
        });
    }

    useEffect(() => {
        checkSession("productividad", props.history, cookie.kUserSession);
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        findIndices();
    }

    return (
        <>
            <Notifications />
            <Header history={props.history} />
            <IndiceModal show={show} handleClose = { (e) => { setShow(false); } } callback={ () => { findIndices() } }/>
            <section className={styles.body}>
                <History items={history} current={"Productividad"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Indices</h1>
                    <section className={styles.actions}>
                        <Form className={styles.searchForm}>                            
                            <Form.Row style={{ margin: "0px"}}>
                                <Form.Group style={{margin: "0px"}}>
                                    <Form.Label className={styles.label}>Periodo {anho != undefined ? ("(" + (anho-1) + "/" + anho + ")") : undefined}</Form.Label>
                                    <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                </Form.Group>                          
                                &nbsp;&nbsp;&nbsp;
                                <Button className="karanda-btn" style={{ height: "fit-content", marginTop: "auto" }} onClick={handleSearch}>Buscar</Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button className="karanda-btn" style={{ height: "fit-content", marginTop: "auto" }}  onClick={(e) => { setShow(true); }}>Registrar índice</Button>
                            </Form.Row>
                        </Form>
                        {
                            loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : 
                            (
                                indices.length == 0 ? <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div> : 
                                (
                                    indices.map((indice) => (
                                        <Indice indice={indice} callback={ () => { findIndices() }}/>
                                    ))
                                )
                            )
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Indices;