import React from "react";
import {displayDate} from "../../../../core/date";
import {formatAmount} from "../../../../core/amount";

const ComparativaPreview = ( { rows, level, indices } ) => {

    const spaces = () => {
        return {
            marginLeft: level + "em"
        };
    }

    const sum = (row, index) => {
        var value = row.amounts[index];
        if(row.cuentas != undefined){
            for(var i = 0; i < row.cuentas.length; i++){
                value += sum(row.cuentas[i], index);
            }
        }
        return value;
    }

    return (
        <>
            {
                rows == undefined ? <></> : (
                    <>
                        {
                            rows.map((i) => (
                                <>
                                    <tr>
                                        <td>
                                            <span style={spaces()}>{i.codigo + " - " + i.descripcion}</span>
                                        </td>
                                        {
                                            i.amounts.map((j, index) => (
                                                <>
                                                    <td align="right">
                                                        <span style={{textAlign: "right"}}>{formatAmount(j)}</span>
                                                    </td>
                                                    <td align="right" style={{background: "#D4EFDF"}}>
                                                        <span style={{textAlign: "right"}}>{formatAmount(j/indices[index])}</span>
                                                    </td>
                                                </>
                                            ))
                                        }
                                    </tr>
                                    {
                                        i.cuentas == undefined ? <></> : (
                                            <ComparativaPreview level={level+1} rows={i.cuentas} indices={indices}/>
                                        )
                                    }
                                    {
                                        i.cuentas != undefined ? (
                                            <tr style={{background: "#28B463"}}>
                                                <td><span style={spaces()}><b>{"TOTAL " + i.codigo + " - " + i.descripcion}</b></span></td>
                                                {
                                                    i.amounts.map((j, index) => (
                                                        <>
                                                            <td align="right">
                                                                <span style={{textAlign: "right"}}>{formatAmount(sum(i, index))}</span>
                                                            </td>                                                        
                                                            <td align="right">
                                                                <span style={{textAlign: "right"}}>{formatAmount(sum(i, index)/indices[index])}</span>
                                                            </td>                                                            
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                        )
                                        : <></>
                                    }
                                </>
                            ))
                        }
                    </>
                )
            }
        </>
    );

}

export default ComparativaPreview;