import React from "react";
import { Card, Button, Form, Row, Col, Nav, Tab } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import { notify } from "react-notify-toast";
import axios from "axios";

const Indice = ( { indice, callback } ) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const { eficienciaFisica, eficienciaReproductiva } =  indice;
    
    const { precipitacion, hectareasUtilizadas, cantidadCabezasPromedio, unidadGanaderaTotales  } = eficienciaFisica;
    const { cargaCabHa, cargaUgHa, cargaKgHa, totalKgProducidos, produccionKgHa, produccionKgCab } = eficienciaFisica;
    const { produccionKgUg, gananciaDiario, totalVendidasConsumidasDonadas } = eficienciaFisica;
    const { porcentajeExtraccion, porcentajeMortandad, porcentajeEficiencia } = eficienciaFisica;


    const { totalHembrasServicio, totalHembrasPrenhadas, porcentajePrenhez,  porcentajeVacasTotal } = eficienciaReproductiva;
    const { porcentajeExtraccionVientre, porcentajeReposicionVientre, totalTernerosMarcados } = eficienciaReproductiva;
    const { porcentajeMarcacion, porcentajeMerma, pesoPromedioDesteteMachos, pesoPromedioDesteteHembras, kgDestetado } = eficienciaReproductiva;

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }        

    const handleDelete = (e) => {
        e.preventDefault();
        axios.delete(process.env.REACT_APP_API_URL + "indices/" + indice.id, prepareHeaders()).
        then(res => {
            callback();
            notify.show("Indice eliminado exitosamente", "success");
        }).
        catch(error => {
            console.error(error);
            notify.show("Ha ocurrido un error al eliminar el índice", "error");
        });   
    }

    return (
        <Card style={{ marginTop: "1em" }}>    
            <Card.Body>
            <Card.Title>Periodo { indice.periodo }</Card.Title>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Eficiencia física</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Eficiencia reproductiva</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Precipitación (mm)</Form.Label>
                                                <Form.Control placeholder="Precipitación en mm" className="karanda-input" value={precipitacion} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Hectáreas utilizadas</Form.Label>
                                                <Form.Control placeholder="Ingrese las hectáreas utilizadas" className="karanda-input" value={hectareasUtilizadas} type="number" />
                                            </Form.Group>
                                        </Col>                                                
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Cantidad total de cabezas</Form.Label>
                                                <Form.Control placeholder="Ingrese el total de cabezas" className="karanda-input" value={cantidadCabezasPromedio} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>U.G Totales</Form.Label>
                                                <Form.Control placeholder="U.G Totales" className="karanda-input" value={unidadGanaderaTotales} type="number" />
                                            </Form.Group>
                                        </Col>                        
                                    </Row>                    
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Carga CAB./HA</Form.Label>
                                                <Form.Control placeholder="Ingrese la carga CAB./HA" className="karanda-input" value={cargaCabHa} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Carga U.G/HA</Form.Label>
                                                <Form.Control placeholder="Ingrese la carga U.G/HA" className="karanda-input" value={cargaUgHa} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Carga KGS/HA</Form.Label>
                                                <Form.Control placeholder="Ingrese la carga KGS/HA" className="karanda-input" value={cargaKgHa} type="number" />
                                            </Form.Group>
                                        </Col>                        
                                    </Row>                                        
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Total de kilos producidos</Form.Label>
                                                <Form.Control placeholder="Ingrese el total de kilos producidos" className="karanda-input" value={totalKgProducidos} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Producción KGS/HA</Form.Label>
                                                <Form.Control placeholder="Ingrese la producción KG/HA" className="karanda-input" value={produccionKgHa} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Producción KGS/CAB</Form.Label>
                                                <Form.Control placeholder="Ingrese la producción KG/CAB" className="karanda-input" value={produccionKgCab} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Producción KGS/U.G</Form.Label>
                                                <Form.Control placeholder="Ingrese la producción KG/U.G" className="karanda-input" value={produccionKgUg} type="number" />
                                            </Form.Group>                        
                                        </Col>                                                
                                    </Row>                    
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Ganancia diario</Form.Label>
                                                <Form.Control placeholder="Ingrese la ganancia diaria" className="karanda-input" value={gananciaDiario} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Total CAB. vendidas / consumidas / donadas</Form.Label>
                                                <Form.Control placeholder="Total CAB. vendidas / consumidas / donadas" className="karanda-input" value={totalVendidasConsumidasDonadas} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de extracción</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de extracción" className="karanda-input" value={porcentajeExtraccion} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de mortandad</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de mortandad" className="karanda-input" value={porcentajeMortandad} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de eficiencia</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de eficiencia" className="karanda-input" value={porcentajeEficiencia} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                    </Row>                    
                                </Form>                                  
                            </Tab.Pane>                            
                            <Tab.Pane eventKey="second">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Total de hembras en servicio</Form.Label>
                                                <Form.Control placeholder="Total de hembras en servicio" className="karanda-input" value={totalHembrasServicio} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Total de hembras preñadas</Form.Label>
                                                <Form.Control placeholder="Total de hembras preñadas" className="karanda-input" value={totalHembrasPrenhadas} type="number" />
                                            </Form.Group>
                                        </Col>                        
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de preñez general</Form.Label>
                                                <Form.Control placeholder="Ingrese en % de preñez general" className="karanda-input" value={porcentajePrenhez} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de vacas s/ total CAB./PROM/AÑO</Form.Label>
                                                <Form.Control placeholder="% de vacas s/ total CAB./PROM/AÑO" className="karanda-input" value={porcentajeVacasTotal} type="number" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de extracción de vientre</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de extracción de vientre" className="karanda-input" value={porcentajeExtraccionVientre} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de reposición de vientres</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de reposición de vientres" className="karanda-input" value={porcentajeReposicionVientre} type="number" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Total de terneros marcados</Form.Label>
                                                <Form.Control placeholder="Ingrese el total de terneros marcados" className="karanda-input" value={totalTernerosMarcados} type="number" />
                                            </Form.Group>
                                        </Col>
                                    </Row>                                        
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de marcación</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de marcación" className="karanda-input" value={porcentajeMarcacion} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>% de merma</Form.Label>
                                                <Form.Control placeholder="Ingrese el % de merma" className="karanda-input" value={porcentajeMerma} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Peso promedio destete (macho)</Form.Label>
                                                <Form.Control placeholder="Peso promedio destete (macho)" className="karanda-input" value={pesoPromedioDesteteMachos} type="number" />
                                            </Form.Group>                        
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Peso promedio destete (hembra)</Form.Label>
                                                <Form.Control placeholder="Peso promedio destete (hembra)" className="karanda-input" value={pesoPromedioDesteteHembras} type="number" />
                                            </Form.Group>                        
                                        </Col>                        
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>KG. destetado / vaca entorada</Form.Label>
                                                <Form.Control placeholder="KG. destetado / vaca entorada" className="karanda-input" value={kgDestetado} type="number" />
                                            </Form.Group>                        
                                        </Col>                                                
                                    </Row>
                                </Form>                                
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <Card.Text>{indice.observacion}</Card.Text>
            <Button variant="primary" onClick={ handleDelete }>Eliminar</Button>
            </Card.Body>
        </Card>        
    )

};

export default Indice;