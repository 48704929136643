import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const CompraView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [idCompra, setIdCompra] = useState(data != undefined ? data.id_compra : undefined);
    const [idMovimiento, setIdMovimiento] = useState(data != undefined ? data.id_movimiento : undefined);
    const [cantidad, setCantidad] = useState(data != undefined ? data.cantidad : undefined);
    const [cantidadRecibida, setCantidadRecibida] = useState(data != undefined ? data.cantidad_recibida : undefined);
    const [cantidadSegunGuia, setCantidadSegunGuia] = useState(data != undefined ? data.cantidad_segun_guia : undefined);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : undefined);
    const [categoria, setCategoria] = useState(data != undefined ? data.categoria.id_categoria : 0);
    const [fechaCompra, setFechaCompra] = useState(data != undefined ? data.fecha_compra : undefined);
    const [fechaCreacion, setFechaCreacion] = useState(data != undefined ? data.fecha_creacion : undefined);
    const [fechaMovimiento, setFechaMovimiento] = useState(data != undefined ? data.fecha_movimiento : undefined);
    const [nroCota, setNroCota] = useState(data != undefined ? data.nro_cota : undefined);
    const [nroGuia, setNroGuia] = useState(data != undefined ? data.nro_guia : undefined);
    const [observacion, setObservacion] = useState(data != undefined ? data.observacion : undefined);
    const [pesoTotal, setPesoTotal] = useState(data != undefined ? data.peso_total : undefined);
    const [proveedor, setProveedor] = useState(data != undefined ? data.proveedor.id : 0);
    const [rangoCaravanaDesde, setRangoCaravanaDesde] = useState(data != undefined ? data.rango_caravana_desde : undefined);
    const [rangoCaravanaHasta, setRangoCaravanaHasta] = useState(data != undefined ? data.rango_caravana_hasta : undefined);
    const [userCreation, setUserCreation] = useState(data != undefined ? data.usuario_creacion : undefined);
    const [userLastModification, setUserLastModification] = useState(data != undefined ? data.usuario_ultima_modificacion : undefined);
    const [providers, setProviders] = useState([]);
    const [categories, setCategories] = useState([]);
    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }    

    const retrieveProviders = () => {
        axios.get(process.env.REACT_APP_API_URL + "provider", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setProviders(data);
            }
            else {
                setProviders([]);
                setProveedor(0);
            }            
        })
        .catch(error => {
            console.error(error);
            setProviders([]);
            setProveedor(0);
        });
    }

    useEffect(() => {

        setIdCompra(data != undefined ? data.id_compra : undefined);
        setIdMovimiento(data != undefined ? data.id_movimiento : undefined);
        setCantidad(data != undefined ? data.cantidad : undefined);
        setCantidadRecibida(data != undefined ? data.cantidad_recibida : undefined);
        setCantidadSegunGuia(data != undefined ? data.cantidad_segun_guia : undefined);
        setCarimbo(data != undefined ? data.carimbo : undefined);
        setCategoria(data != undefined ? data.categoria.id_categoria : 0);
        setFechaCompra(data != undefined ? data.fecha_compra : undefined);
        setFechaCreacion(data != undefined ? data.fecha_creacion : undefined);
        setFechaMovimiento(data != undefined ? data.fecha_movimiento : undefined);
        setNroCota(data != undefined ? data.nro_cota : undefined);
        setNroGuia(data != undefined ? data.nro_guia : undefined);
        setObservacion(data != undefined ? data.observacion : undefined);
        setPesoTotal(data != undefined ? data.peso_total : undefined);
        setProveedor(data != undefined ? data.proveedor.id : undefined);
        setRangoCaravanaDesde(data != undefined ? data.rango_caravana_desde : undefined);
        setRangoCaravanaHasta(data != undefined ? data.rango_caravana_hasta : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);
        setOwner(data != undefined ? data.owner : undefined);
    }, [data]);

    const handleUpdate = (e) => {
        var currentFarm = cookie.kUserSession.data["farm"];
        var data = {
            data: {
                id_movimiento: parseInt(idMovimiento),
                id_compra: parseInt(idCompra),
                cantidad: parseInt(cantidadRecibida),
                cantidad_recibida: parseInt(cantidadRecibida),
                cantidad_segun_guide: parseInt(cantidadSegunGuia),
                carimbo: parseInt(carimbo),
                fecha_movimiento: fechaCompra,
                id_categoria: parseInt(categoria),
                id_estancia: parseInt(currentFarm.id),
                id_proveedor: parseInt(proveedor),
                nro_guia: parseInt(nroGuia),
                numero_cuota: parseInt(nroCota),
                observacion: observacion,
                peso_total: parseInt(pesoTotal),
                rango_caravana_desde: parseInt(rangoCaravanaDesde),
                rango_caravana_hasta: parseInt(rangoCaravanaHasta),
                owner: owner ? owner.id : undefined
            }
        }
        update(data);        
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/entrance/purcharse", data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    useEffect(() => {
        retrieveProviders();
        retrieveCategories();
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos de la compra</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control disabled={!edit} value={fechaCompra} onChange={(e) => { setFechaCompra(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>N° Guía</Form.Label>
                                    <Form.Control disabled={!edit} value={nroGuia} onChange={(e) => { setNroGuia(e.target.value); }} type="number" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_cota">
                                    <Form.Label>N° Cota</Form.Label>
                                    <Form.Control disabled={!edit} value={nroCota} onChange={(e) => { setNroCota(e.target.value); }} type="number" placeholder="Ingrese la cota" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="proveedor">
                                    <Form.Label>Proveedor</Form.Label>
                                    <Form.Control disabled={!edit} as="select" value={proveedor} onChange={(e) => {setProveedor(e.target.value)}}>
                                        <option value={0}>Seleccione el proveedor</option>
                                        {
                                            providers.map((i) => (
                                                <option value={i.id}>{i.razon_social}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>     
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                                                    
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control disabled={!edit} value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0}>Seleccione la categoría</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control disabled={!edit} value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad s/ guía</Form.Label>
                                    <Form.Control disabled={!edit} value={cantidadSegunGuia} onChange={(e) => { setCantidadSegunGuia(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_recibida">
                                    <Form.Label>Cantidad recibida</Form.Label>
                                    <Form.Control disabled={!edit} value={cantidadRecibida} onChange={(e) => { setCantidadRecibida(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="peso_total">
                                    <Form.Label>Peso total (KG)</Form.Label>
                                    <Form.Control disabled={!edit} value={pesoTotal} onChange={(e) => { setPesoTotal(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Rango de caravanas</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Incio</Form.Label>
                                                <Form.Control disabled={!edit} value={rangoCaravanaHasta} onChange={ (e) => {setRangoCaravanaHasta(e.target.value); }} type="number"/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Fin</Form.Label>
                                                <Form.Control disabled={!edit} value={rangoCaravanaDesde} onChange={ (e) => {setRangoCaravanaDesde(e.target.value); } } type="number"/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control disabled={!edit} value={observacion} onChange={(e) => { setObservacion(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={fechaCreacion} disabled type="date-time" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CompraView;