import React, {useState, useEffect} from "react";
import Header from "../../components/header";
import styles from "./PanelControl.module.css";
import MenuCard from "../../components/menu-card";
import cowIcon from '@iconify/icons-whh/cow';
import History from "../../components/history";
import usersIcon from '@iconify/icons-fa-solid/users';
import initContainer from '@iconify/icons-eos-icons/init-container';
import bxsBusiness from '@iconify/icons-bx/bxs-business';
import {checkSession} from "../../core/session";
import { useCookies } from 'react-cookie';

const PanelControl = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]

    useEffect(() => {
        checkSession("control-panel", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Panel de control"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Panel de control</h1>
                    <section className={styles.menuGroup}>
                        <MenuCard history={props.history} menu="Gestión de usuarios" representation={usersIcon} path={"/usuarios"}/>
                        <MenuCard history={props.history} menu="Gestión de empresas" representation={bxsBusiness} path={"/empresas"}/>
                        <MenuCard history={props.history} menu="Categorías" representation={cowIcon} path={"/categorias"}/>
                        <MenuCard history={props.history} menu="Stock inicial" representation={initContainer} path={"/stock"}/>
                    </section>
                </section>
            </section>                
        </>
    );

};

export default PanelControl;