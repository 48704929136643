import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./VentaModal.module.css";
import { format } from "../../../core/number";

const VentaModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [observation, setObservation] = useState(undefined);

    const [date, setDate] = useState(undefined);
    const [cantidad, setCantidad] = useState(1);
    const [carimbo, setCarimbo] = useState(0);
    const [tipoVenta, setTipoVenta] = useState("P");
    const [categoria, setCategoria] = useState(0);
    const [categories, setCategories] = useState([]);
    const [cliente, setCliente] = useState(0);
    const [retiro, setRetiro] = useState(undefined);
    const [nroGuia, setNroGuia] = useState(undefined);
    const [customers, setCustomers] = useState([]);
    const [pesoBrutoTotal, setPesoBrutoTotal] = useState(1000);
    const [pesoTotalDevastado, setPesoTotalDevastado] = useState(undefined);
    const [pesoTotalFrigorifico, setPesoTotalFrigorifico] = useState(0);
    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }   
    
    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }

    const retrieveCustomers = () => {
        //setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "customer", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                console.log(data);
                //setLoading(false);
                setCustomers(data);
            }
            else {
                //setLoading(false);
                setCustomers([]);
            }
        })
        .catch(error => {
            console.error(error);
            //setLoading(false);
            setCustomers([]);
        });
    }    

    const validateParams = () => {
        return true;
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/outcome/sale", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Venta registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar la venta", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            var currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    cantidad: parseInt(cantidad),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: date,
                    id_categoria: parseInt(categoria),
                    id_cliente: parseInt(cliente),
                    id_estancia: currentFarm.id,
                    retiro: retiro,
                    nro_guia: nroGuia,
                    observacion: observation,
                    peso_bruto_total: parseInt(pesoBrutoTotal),
                    peso_total_devastado: pesoBrutoTotal * 0.04,
                    peso_total_frigorifico: parseInt(pesoTotalFrigorifico),
                    tipo_venta: tipoVenta,
                    owner: owner ? owner.id : undefined
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveCustomers();
        retrieveCategories();
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva venta</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={date} onChange={(e) => { setDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>N° Guía</Form.Label>
                                    <Form.Control className="karanda-input" value={nroGuia} onChange={(e) => { setNroGuia(e.target.value); }} type="text" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_cota">
                                    <Form.Label>Tipo de venta</Form.Label>
                                    <Form.Control className="karanda-input" value={tipoVenta} onChange={(e) => { setTipoVenta(e.target.value); }} as="select">
                                        <option value={"P"}>EN PIE</option>
                                        <option value={"E"}>FERIA</option>
                                        <option value={"F"}>FRIGORIFICO</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control className="karanda-input" type="text" placeholder="Seleccione la zona" value={retiro} onChange={(e) => {setRetiro(e.target.value); }}/>
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0} selected disabled>Seleccione la categoría</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="proveedor">
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Control as="select" className="karanda-input" value={cliente} onChange={(e) => {setCliente(e.target.value); }}>
                                        <option value={0} selected disabled>Seleccione el cliente</option>
                                        {
                                            customers.map((i) => (
                                                <option value={i.id}>{i.razon_social}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={ owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="cantidad_recibida">
                                    <Form.Label>Peso total</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoBrutoTotal} onChange={(e) => { setPesoBrutoTotal(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="peso_total_destino">
                                    <Form.Label>Peso total destino</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoTotalFrigorifico} onChange={(e) => { setPesoTotalFrigorifico(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: "right"}}>
                                <span><strong>Peso promedio: </strong> {format(pesoBrutoTotal / cantidad, 2)}</span>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: "right"}}>
                                <span><strong>Peso total devastado (4%): </strong> {format(pesoBrutoTotal * 0.96, 2)}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: "right"}}>
                                <span><strong>Rendimiento (%): </strong>{format((pesoTotalFrigorifico * 100) / (pesoBrutoTotal * 0.96), 2) }</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control className="karanda-input" value={observation} onChange={ (e) => { setObservation(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default VentaModal;