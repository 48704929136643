import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./CustomerModal.module.css";

const CustomerModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    
    const [name, setName] = useState(undefined);
    const [sexo, setSexo] = useState("M");
    const [marcaInicio, setMarcaInicio] = useState(false);
    const [tipoAnimal, setTipoAnimal] = useState(0);
    const [animales, setAnimales] = useState([]);

    const [nameError, setNameError] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const validateParams = () => {
        let haveError = false;
        if(!validateField(false, 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        return !haveError;
    }

    const save = (body) => {
        setSaveLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "category", body, prepareHeaders()).then(rs => {
            setSaveLoading(false);
            callback();
            handleClose();
            notify.show("Categoría registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setSaveLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear la categoría", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            const body = {
                data: {
                    id_tipo_animal: parseInt(tipoAnimal),
                    marca_inicio: marcaInicio,
                    nombre: name,
                    sex: sexo
                }
            };
            save(body);
        }
    }

    const retrieveAnimales = () => {
        axios.get(process.env.REACT_APP_API_URL + "animal_type", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs.data;
                setAnimales(data);
            }
            else {
                setAnimales([]);
            }
        })
        .catch(error => {
            console.error(error);
            setAnimales([]);
        })
    }    

    useEffect(() => {
        retrieveAnimales();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva categoría</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control className="karanda-input" type="text" value={name} onChange={(e) => {setName(e.target.value);}} placeholder="Ingrese el nombre de la categoría" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sexo</Form.Label>
                            <Form.Control className="karanda-input" value={sexo} onChange={(e) => {setSexo(e.target.value);}} as="select">
                                <option value="M">Macho</option>
                                <option value="H">Hembra</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tipo de animal</Form.Label>
                            <Form.Control className="karanda-input" as="select" value={tipoAnimal} onChange={(e) => {setTipoAnimal(e.target.value);}}>
                                <option value={0}>Seleccione</option>
                                {
                                    animales.map((i) => (
                                        <option value={i.id}>{i.nombre}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label="Marca inicio"
                            value={marcaInicio}
                            checked={marcaInicio}
                            onChange={(e) => {setMarcaInicio(e.target.checked); }}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            saveLoading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CustomerModal;