import React from "react";
import styles from "./History.module.css";

const History = ({ history, items, current }) => {

    const go = (e, path) => history.push(path);

    return (
        <div className={styles.container}>
            {
                items.map((item) => (
                    <span className={styles.link} onClick={(e) => {go(e, item.path);}}> {`${item.name} > `}</span>
                ))
            }
            <span className={styles.current}>{current}</span>
        </div>
    );

};

export default History;