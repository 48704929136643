import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table, BreadcrumbItem } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./UserAsignations.module.css";
import { Icon, InlineIcon } from '@iconify/react';
import bxsTrash from '@iconify/icons-bx/bxs-trash';

const UserAsignations = ({ show, handleClose, callback, data }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [companiesLoading, setCompaniesLoading] = useState(false);
    const [farmsLoading, setFarmsLoading] = useState(false);

    const [loadingDeleteAsignation, setLoadingDeleteAsignation] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);

    const [company, setCompany] = useState(0);
    const [farm, setFarm] = useState(0);

    const [loadingAssign, setLoadingAssign] = useState(false);

    const [asignations, setAsignations] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveAsignations = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "user/asignations/" + data.id, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs.data;
                setLoading(false);
                setAsignations(data);
            }
            else {
                setLoading(false);
                setAsignations([]);                
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setAsignations([]);
        })
    }

    const handleAsign = (e) => {
        var postData = {
            data: {
                farm_id: farm,
                user_id: data.id
            }
        }
        asignFarm(postData);
    }

    const asignFarm = (postData) => {
        setLoadingAssign(true);
        axios.post(process.env.REACT_APP_API_URL + "farm/asign", postData, prepareHeaders())
        .then(rs => {
            setLoadingAssign(false);
            retrieveAsignations();
        })
        .catch(error => {
            console.error(error);
            setLoadingAssign(false);
            retrieveAsignations();
            notify.show("Ha ocurrido un error al asignar la estancia", "error");
        })
    }

    const retrieveCompanies = () => {
        setCompaniesLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "company", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setCompaniesLoading(false);
                    setCompany(data[0].id);
                    setCompanies(data);
                }
                else {
                    setCompaniesLoading(false);
                    setCompany(0);
                    setCompanies([]);
                }
            })
            .catch(error => {
                console.error(error);
                setCompaniesLoading(false);
                setCompany(0);
                setCompanies([]);
            });
    }

    const retrieveFarms = (companyId) => {
        setFarmsLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "farm/" + companyId, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                let filters = [];
                for(var i = 0; i < data.length; i++){
                    var contain = false;
                    for(var j = 0; j < asignations.length; j++){
                        if(data[i].id == asignations[j].estancia.id){
                            contain = true;
                            break;
                        }
                    }
                    if(!contain){ filters.push(data[i]); }
                }
                setFarm(filters.length >= 1 ? filters[0].id : undefined);
                setFarms(filters);
                setFarmsLoading(false);
            }
            else {
                setFarm(0);
                setFarms([]);
                setFarmsLoading(false);
            }

        })
        .catch(error => {
            console.error(error);
            setFarm(0);
            setFarms([]);
            setFarmsLoading(false);
        });
    }    

/*     const validateParams = () => {
        let haveError = false;
        if(!validateEmail(email)){
            setEmailError(true);
            haveError = true;
        }
        else { setEmailError(false); }
        if(!validateField(personType == "J", 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        if(!validateField(false, 3, 50, lastName)){
            setLastNameError(true);
            haveError = true;
        }
        else { setLastNameError(false); } */
/*         if(!validateDate(birthDay)){
            setBirthDayError(true);
            haveError = true;
        } */
/*         if(!validateField(true, 5, 10, documentNumber)){
            setDocumentNumberError(true);
            haveError = true;
        }
        else { setDocumentNumberError(false); }
        return !haveError;
    }
 */
    /* const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "user", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Usuario registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear el usuario", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            const body = {
                data: {
                    nombres: personType == "F" ? name : null,
                    apellidos: lastName,
                    email: email,
                    estado: "A",
                    tipo_persona: personType,
                    rol: role,
                    nro_documento: documentNumber,
                    contactos: [],
                    fecha_nacimiento: birthDay != undefined ? dateToRequestDate(birthDay) : undefined
                }
            };
            save(body);
        }
    } */

    const deleteAsignation = (asignationId) => {
        setLoadingDeleteAsignation(true);
        axios.delete(process.env.REACT_APP_API_URL + "farm/asign/" + asignationId, prepareHeaders())
        .then(rs => {
            setLoadingDeleteAsignation(false);
            notify.show("Se ha eliminado la asignación exitosamente", "success");
            retrieveAsignations();
        })
        .catch(error => {
            console.error(error);
            setLoadingDeleteAsignation(false);
            notify.show("Ha ocurrido un error al eliminar la asignación", "error");
        });        
    }

    useEffect(() => {
        if(data != undefined){
            retrieveAsignations();
        }
    }, [data]);

    useEffect(() => {
        retrieveCompanies();
        if(company != undefined){
            retrieveFarms(company);
        }
    }, [asignations]);

    useEffect(() => {
        if (company != undefined) {
            retrieveFarms(company);
        }
    }, [company]);    

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Empresas / Estancias asignadas</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={styles.searchForm}>
                        <Row>
                            <Col>
                                <Form.Group controlId="empresa">
                                    <Form.Label>Empresa</Form.Label>
                                    <Form.Control as="select" value={company} onChange={(e) => {setCompany(e.target.value); }}>
                                        <option value={0} selected disabled>Seleccione la empresa</option>
                                        {
                                            companies.map((i) => (
                                                <option value={i.id}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                               
                            </Col>
                            <Col>
                                <Form.Group controlId="estancia">
                                    <Form.Label>Estancia</Form.Label>
                                    <Form.Control as="select" value={farm} onChange={(e) => { setFarm(e.target.value); }}>
                                        <option value={0} selected disabled>Seleccione la estancia</option>
                                        {
                                            farms.map((i) => (
                                                <option value={i.id}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className="karanda-btn" onClick={(e) => {handleAsign(e); }}>Asignar</Button>
                    </Form>
                    {
                        loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (
                            asignations.length == 0 ? (
                                <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                            ) : (
                                <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                    <thead>
                                        <th>Empresa</th>
                                        <th>Estancia</th>
                                        <th>Fecha asignación</th>
                                        <th>Asignador</th>
                                        <th>Acciones</th>
                                    </thead>
                                    <tbody>
                                        {
                                            asignations.map((i) => (
                                                <tr>
                                                    <td>{i.empresa.nombre}</td>
                                                    <td>{i.estancia.nombre}</td>
                                                    <td>{i.fecha_creacion}</td>
                                                    <td>{i.usuario_creador.email}</td>
                                                    <td>
                                                        <Icon onClick={(e) => {deleteAsignation(i.id);}} className={styles.icon} icon={bxsTrash} height="20px" />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            )
                        )
                        }                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default UserAsignations;