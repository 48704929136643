import React from "react";
import {displayDate} from "../../../../core/date";
import {formatAmount} from "../../../../core/amount";

const ConsolidadoEmpresasPreview = ( { rows, level, indices } ) => {

    const spaces = () => {
        return {
            marginLeft: level + "em"
        };
    }

    const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const sum = (invoices) => {
        var value = 0;
        for(var i = 0; i < invoices.length; i++){
            value += invoices[i].amount;
        }
        return value;
    }

    const sumOfMonth = (i, month) => {
        var sum = 0;
        sum += i.amounts[month-1];
        if(i.cuentas != undefined){
            for(var j = 0; j < i.cuentas.length; j++){
                sum += sumOfMonth(i.cuentas[j], month);
            }
        }
        return sum;
    }

    const total = (amounts) => {
        var sum = 0;
        for(var i = 0; i < amounts.length; i++){
            sum += amounts[i];
        }
        return sum;
    }

    const totalTotal = (row) => {
        var total = 0;
        for(var i = 0; i < MONTHS.length; i++){
            total += sumOfMonth(row, i+1);
        }
        return total;
    }

    return (
        <>
            {
                rows == undefined ? <></> : (
                    <>
                        {
                            rows.map((i) => (
                                <>
                                    <tr>
                                        <td>
                                            <span style={spaces()}>{i.codigo + " - " + i.descripcion}</span>
                                        </td>
                                        {
                                            i.amounts.map((j, index) => (
                                                <>
                                                    <td align="right">
                                                        <span style={{textAlign: "right"}}>{formatAmount(j)}</span>
                                                    </td>
                                                    <td align="right" style={{background: "#D4EFDF"}}>
                                                        <span style={{textAlign: "right"}}>{formatAmount(j/indices[index])}</span>
                                                    </td>
                                                </>
                                            ))
                                        }
                                        <td align="right">
                                            <span style={{textAlign: "right"}}>{formatAmount(total(i.amounts))}</span>
                                        </td>
                                        <td align="right" style={{background: "#D4EFDF"}}>
                                            <span style={{textAlign: "right"}}>{formatAmount(total(i.amounts)/indices[indices.length-1])}</span>
                                        </td>                                        
                                    </tr>
                                    {
                                        i.cuentas == undefined ? <></> : (
                                            <ConsolidadoEmpresasPreview level={level+1} rows={i.cuentas} indices={indices}/>
                                        )
                                    }
                                    {
                                        i.cuentas != undefined ? (
                                            <tr style={{background: "#28B463"}}>
                                                <td><span style={spaces()}><b>{"TOTAL " + i.codigo + " - " + i.descripcion}</b></span></td>
                                                {
                                                    MONTHS.map((j, index) => (
                                                        <>
                                                            <td align="right">
                                                                <span style={{textAlign: "right"}}>{formatAmount(sumOfMonth(i, j))}</span>
                                                            </td>                                                        
                                                            <td align="right">
                                                                <span style={{textAlign: "right"}}>{formatAmount(sumOfMonth(i, j)/indices[index])}</span>
                                                            </td>                                                            
                                                        </>
                                                    ))
                                                }
                                                <td align="right">
                                                    <span style={{textAlign: "right"}}>{formatAmount(totalTotal(i))}</span>
                                                </td>
                                                <td align="right">
                                                    <span style={{textAlign: "right"}}>{formatAmount(totalTotal(i)/indices[indices.length-1])}</span>
                                                </td>
                                            </tr>
                                        )
                                        : <></>
                                    }
                                </>
                            ))
                        }
                    </>
                )
            }
        </>
    );

}

export default ConsolidadoEmpresasPreview;