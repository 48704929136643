import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate, buildQueryParams} from "../../../core/date";
import styles from "./ConsumoModal.module.css";

const ConsumoModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [observation, setObservation] = useState('');
    const [fechaConsumo, setFechaConsumo] = useState(undefined);
    const [carabana, setCarabana] = useState('');
    const [retiro, setRetiro] = useState(0);
    const [retirementData, setRetirementData] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [destino, setDestino] = useState("I");
    const [carimbo, setCarimbo] = useState(0);
    const [pesoVivo, setPesoVivo] = useState(0);
    const [pesoGancho, setPesoGancho] = useState(0);
    const [cantidad, setCantidad] = useState(0);
    const [consumidorName, setConsumidor] = useState(undefined);
    const [cantidadKilos, setCantidadKilos] = useState(0);
    const [consumos, setConsumos] = useState([]);

    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    

    const validateParams = () => {
        return true;
    }

    

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/outcome/consuption", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            callback();       
            setLoading(false);    
            handleClose();
            notify.show("Consumo registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar el consumo", "error");
        });
    }

    const buildConsumos = () => {
        var consumosEntity = [];
        for(var i = 0; i < consumos.length; i++){
            var consumoEntity = {
                cantidad_kilos: consumos[i].cantidad,
                consumidor: consumos[i].consumidorName
            };
            consumosEntity.push(consumoEntity);
        }
        return consumosEntity;
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            let currentFarm = cookie.kUserSession.data["farm"];
            let body = {
                data: {
                    cantidad: parseInt(cantidad),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: fechaConsumo,
                    id_categoria: parseInt(categoria),
                    id_estancia: currentFarm.id,
                    id_retiro: parseInt(retiro),
                    nro_caravana: carabana,
                    observacion: observation == '' ? null : observation,
                    peso_al_gancho: parseInt(pesoGancho),
                    peso_vivo: parseInt(pesoVivo),
                    tipo: destino,
                    consumos: buildConsumos(),
                    owner: owner ? owner.id : undefined                    
                }
            };
            save(body);
        }
    }

    useEffect(() => {
        retrieveRetirementByFarm();
        retrieveCategory();
        retrieveOwners();        
    }, []);

    const retrieveRetirementByFarm = async () => {
        let {farm:{id}} = cookie.kUserSession.data;
        let url = process.env.REACT_APP_API_URL + "retiros";
        url += buildQueryParams({id_estancia: id});
        await axios.get(url, prepareHeaders())
        .then(rs => {
            setRetirementData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setRetirementData([]);
        });
    }

    const retrieveCategory = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            setCategoryData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setCategoryData([]);
        });
    }

    const agregarConsumidor = (e) => {
        e.preventDefault();
        if(consumidorName != undefined && cantidadKilos != undefined){
            var consumidorObj = {
                consumidorName: consumidorName,
                cantidad: cantidadKilos
            };
            setConsumos([...consumos, consumidorObj]);
        }
    }

    const sumTotal = () => {
        var total = 0;
        for(var i = 0; i < consumos.length; i++){
            total += parseFloat(consumos[i].cantidad);
        }
        return total;
    }

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo consumo</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha-consumo">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={fechaConsumo}
                                                  onChange={(e) => setFechaConsumo(e.target.value)} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="nro-carabana">
                                    <Form.Label>Caravana N°</Form.Label>
                                    <Form.Control className="karanda-input" value={carabana} onChange={(e) => setCarabana(e.target.value)} type="text" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={ owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                             
                            <Col>
                                <Form.Group controlId="retiro">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control className="karanda-input" value={retiro} onChange={(e) => setRetiro(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione retiro</option>
                                        {
                                            retirementData != undefined && retirementData.length > 0
                                            ? retirementData.map((i)  => (
                                                <option value={i.id_retiro}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ningun retiro disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categor&iacute;a</Form.Label>
                                    <Form.Control className="karanda-input"value={categoria} onChange={(e) => setCategoria(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione categor&iacute;a</option>
                                        {
                                            categoryData != undefined && categoryData.length > 0
                                            ? categoryData.map((i)  => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ninguna categoria disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="destino">
                                    <Form.Label>Destino</Form.Label>
                                    <Form.Control className="karanda-input" value={destino} onChange={(e) => setDestino(e.target.value)} as="select">
                                        <option value="I">INTERNO</option>
                                        <option value="C">CONTRATISTA</option>
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Peso vivo</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoVivo} onChange={(e) => setPesoVivo(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Peso al gancho</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoGancho} onChange={(e) => setPesoGancho(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidad} onChange={(e) => setCantidad(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>                   
                        </Row>
                        <>
                            {
                                destino === "C" ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Consumidor</Form.Label>
                                                    <Form.Control type="text" className="karanda-input" value={consumidorName} onChange={(e) => { setConsumidor(e.target.value); }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Cantidad de kilos</Form.Label>
                                                    <div style={{display: "flex"}}>
                                                        <Form.Control className="karanda-input" type="number" value={cantidadKilos} onChange={(e) => {setCantidadKilos(e.target.value); }}/>&nbsp;&nbsp;
                                                        <Button onClick={agregarConsumidor}>Agregar</Button>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)
                            }
                        </>
                        <>
                            {
                                consumos.length > 0 ? (
                                    <Row>
                                        <Table>
                                            <thead>
                                                <th>Consumidor</th>
                                                <th>Cantidad (KG)</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    consumos.map((i) => (
                                                        <tr>
                                                            <td>{i.consumidorName}</td>
                                                            <td style={{textAlign: "right"}}>{i.cantidad}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style={{textAlign: "right"}}>TOTAL:</td>
                                                    <td style={{textAlign: "right"}}>{sumTotal()}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Row>                                                  
                                ) : (
                                    <></>
                                )
                            }
                        </>                            
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control className="karanda-input" value={observation}
                                    onChange={(e) => setObservation(e.target.value)} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ConsumoModal;