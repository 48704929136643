import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const TransferenciaEntradaView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id_transferencia : undefined);
    const [idMovimiento, setIdMovimiento] = useState(data != undefined ? data.id_movimiento : undefined);
    const [amount, setAmount] = useState(data != undefined ? data.importe : undefined);
    const [documentNumber, setDocumentNumber] = useState(data != undefined ? data.numero_factura : undefined);
    const [account, setAccount] = useState(data != undefined ? data.cuenta_contable : undefined);
    const [provider, setProvider] = useState(data != undefined ? data.proveedor : undefined);
    const [observation, setObservation] = useState(data != undefined ? data.observacion : undefined);
    const [expenseDate, setExpenseDate] = useState(data != undefined ? data.fecha_transferencia : undefined);
    const [estanciaOrigen, setEstanciaOrigen] = useState(data != undefined ? data.estancia_involucrada.id : undefined);
    const [cantidad, setCantidad] = useState(data != undefined ? data.cantidad : undefined);
    const [pesoTotal, setPesoTotal] = useState(data != undefined ? data.peso_bruto_total : undefined);
    const [retiro, setRetiro] = useState(data != undefined ? data.retiro.nombre : undefined);
    const [categoria, setCategoria] = useState(data != undefined ? data.categoria.id_categoria : undefined);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : undefined);
    const [farms, setFarms] = useState([]);
    const [categories, setCategories] = useState([]);

    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);    

    const [creationDate, setCreation] = useState(undefined);
    const [userCreation, setUserCreation] = useState(undefined);
    const [userLastModification, setUserLastModification] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }      

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    


    useEffect(() => {
        console.log(data);
        setId(data != undefined ? data.id_transferencia : undefined);
        setIdMovimiento(data != undefined ? data.id_movimiento : undefined);
        setAmount(data != undefined ? data.importe : undefined);
        setDocumentNumber(data != undefined ? data.numero_factura : undefined);
        setAccount(data != undefined ? data.cuenta_contable : undefined);
        setProvider(data != undefined ? data.proveedor : undefined);
        setObservation(data != undefined ? data.observacion : undefined);
        setExpenseDate(data != undefined ? data.fecha_transferencia : undefined);
        setEstanciaOrigen(data != undefined ? data.estancia_involucrada.id : undefined);
        setCantidad(data != undefined ? data.cantidad : undefined);
        setPesoTotal(data != undefined ? data.peso_bruto_total : undefined);
        setRetiro(data != undefined ? data.retiro.nombre : undefined);
        setCategoria(data != undefined ? data.categoria.id_categoria : undefined);
        setCarimbo(data != undefined ? data.carimbo : undefined);

        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);
        setOwner(data != undefined ? data.owner : undefined);        
    }, [data]);

    const handleUpdate = (e) => {
        var currentFarm = cookie.kUserSession.data["farm"];
        var data = {
            data: {
                id_movimiento: idMovimiento,
                id_transferencia: id,
                cantidad: parseInt(cantidad),
                carimbo: parseInt(carimbo),
                fecha_movimiento: expenseDate,
                fecha_transferencia: expenseDate,
                id_categoria: parseInt(categoria),
                id_estancia: parseInt(currentFarm.id),
                estancia_origen: parseInt(estanciaOrigen),
                retiro: retiro,
                observacion: observation,
                peso_bruto_total: parseInt(pesoTotal),
                peso_total_devastado: Math.round(pesoTotal * 0.96),
                owner: owner ? owner.id : undefined
            }
        }
        update(data);        
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/entrance/transfer", data, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }

    const retrieveFarms = () => {
        let currentCompany = cookie.kUserSession.data["company"];
        console.log("CURRENT_farm", currentCompany);        
        axios.get(process.env.REACT_APP_API_URL + "farm/" + currentCompany.id, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs.data;
                setFarms(data);
            }
            else {
                setFarms([]);
            }
        })
        .catch(error => {
            console.error(error);
            setFarms([]);
        })
    }
    
    useEffect(() => {
        retrieveFarms();
        retrieveCategories();
        retrieveOwners();        
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos de la transferencia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="fecha_gasto">
                                <Form.Label>Fecha de transferencia</Form.Label>
                                <Form.Control disabled={!edit} value={expenseDate} onChange={(e) => { setExpenseDate(e.target.value); }} type="date" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="categoria">
                                <Form.Label>Estancia origen</Form.Label>
                                <Form.Control disabled={!edit} value={estanciaOrigen} onChange={(e) => { setEstanciaOrigen(e.target.value); }} as="select">
                                {
                                    farms.map((i) => (
                                        <option value={i.id}>{i.nombre}</option>
                                    ))
                                }
                                </Form.Control>
                            </Form.Group>                        
                        </Col>                 
                        <Col>
                            <Form.Group controlId="owner">
                                <Form.Label>Propietario</Form.Label>
                                <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                    <option value={0} selected disabled>Seleccione el propietario</option>
                                    {
                                        owners.map((i) => (
                                            <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>                                    
                        <Col>
                            <Form.Group controlId="categoria">
                                <Form.Label>Zona</Form.Label>
                                <Form.Control disabled={!edit} value={retiro} onChange={(e) => { setRetiro(e.target.value); }} type="text" />
                            </Form.Group>                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="categoria">
                                <Form.Label>Categoría</Form.Label>
                                <Form.Control disabled={!edit} value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                    {
                                        categories.map((i) => (
                                            <option value={i.id_categoria}>{i.nombre}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group controlId="carimbo">
                                <Form.Label>Carimbo</Form.Label>
                                <Form.Control disabled={!edit} value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" placeholder="Ingrese carimbo"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="cantidad_sobre_guia">
                                <Form.Label>Cantidad recibida</Form.Label>
                                <Form.Control disabled={!edit} value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" placeholder="Ingrese cantidad"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="cantidad_sobre_guia">
                                <Form.Label>Peso total (KG)</Form.Label>
                                <Form.Control disabled={!edit} value={pesoTotal} onChange={(e) => { setPesoTotal(e.target.value); }} type="number" placeholder="Ingrese cantidad"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="cantidad_sobre_guia">
                                <Form.Label>Peso promedio</Form.Label>
                                <Form.Control disabled={!edit} value={Math.round(pesoTotal / cantidad)} type="number" placeholder="Ingrese cantidad"/>
                            </Form.Group>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="observacion">
                                <Form.Label>Observación</Form.Label>
                                <Form.Control disabled={!edit} value={observation} onChange={(e) => { setObservation(e.target.value); }} type="text" />
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        edit ? (<></>) : (
                            <>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="creador">
                                            <Form.Label>Creador</Form.Label>
                                            <Form.Control value={userCreation} disabled type="email" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="modificador">
                                            <Form.Label>Modificador</Form.Label>
                                            <Form.Control value={userLastModification} disabled type="email" />
                                        </Form.Group>                                
                                    </Col>                     
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="creacion">
                                            <Form.Label>Creación</Form.Label>
                                            <Form.Control value={creationDate} disabled type="datetime-local" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>                                    
                        )
                    }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default TransferenciaEntradaView;