import React, {useState, useEffect} from "react";
import { Table } from "react-bootstrap";
import ConsolidadoEmpresasPreview from "./ConsolidadoEmpresasPreview";
import {formatAmount} from "../../../core/amount";
import styles from "./ReportConsolidadoEmpresasPreview.module.css";

const ReportConsolidadoEmpresasPreview = ( {data} ) => {

    const [gastosFijos, setGastosFijos] = useState([]);
    const [gastosVariables, setGastosVariables] = useState([]);
    const [ingresos, setIngresos] = useState([]);
    const [inversion, setInversion] = useState([]);

    const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const NAMED_MONTHS = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

    useEffect(() => {

        if(data != undefined){

            setGastosFijos(calcularMontosGeneral(data.rows, 2000000));
            setGastosVariables(calcularMontosGeneral(data.rows, 3000000));
            setIngresos(calcularMontosGeneral(data.rows, 1000000));
            setInversion(calcularMontosGeneral(data.rows, 4000000));       
        }

    }, [data]);

    const calcularMontosGeneral = (rows, cuenta) => {
        var gastosFijosVar = [];
        for(var i = 0; i < rows.length; i++){
            if(rows[i].codigo == cuenta){
                for(var j = 0; j < MONTHS.length; j++){
                    gastosFijosVar.push(rows[i].amounts[j]);
                }
                if(rows[i].cuentas != undefined){
                    gastosFijosVar = calcularMontos(rows[i].cuentas, gastosFijosVar);
                }
                break;
            }
        }
        gastosFijosVar.push(total(gastosFijosVar));
        return gastosFijosVar;
    }

    const total = (amounts) => {
        var total = 0;
        for(var i = 0; i < amounts.length; i++){
            total += amounts[i];
        }
        return total;
    }

    const calcularMontos = (cuentas, montos) => {
        for(var i = 0; i < cuentas.length; i++){
            for(var j = 0; j < cuentas[i].amounts.length; j++){
                montos[j] += cuentas[i].amounts[j];
            }
            if(cuentas[i].cuentas != undefined){
                montos = calcularMontos(cuentas[i].cuentas, montos);
            }
        }
        return montos;
    }

    
    return (
        <>
            {
                data == undefined ? <></> : 
                <div>
                    <Table responsive className={styles.previewTable}>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    data.indicesCabeza.slice(2).map((i) => (
                                        <>
                                            <th>MES</th>
                                            <th>Inc/Cab</th>
                                        </>
                                    ))
                                }
                                <th>TOTAL</th>
                                <th>Inc/Cab</th>
                            </tr>
                            <tr style={{background: "#DAF7A6"}}>
                                <th>CANTIDAD CABEZAS</th>
                                {
                                    data.indicesCabeza.slice(1).map((i, index) => (
                                        <>
                                            <th>{index == data.indicesCabeza.slice(1).length-1 ? "Total consolidado" : "TOTAL " + NAMED_MONTHS[index] }</th>
                                            <th>{i}</th>
                                        </>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <ConsolidadoEmpresasPreview rows={data.rows} indices={data.indicesCabeza.slice(1)} level={0}/>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{background: "#808000"}} ><b>Total Gastos Fijos + Gastos Variables</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index])}</span>
                                            </td>            
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index])/data.indicesCabeza.slice(1)[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Gastos + Inversión</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index] + inversion[index])}</span>
                                            </td>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index] + inversion[index])/data.indicesCabeza.slice(1)[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Ingresos - Egresos</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(ingresos[index] - (i + gastosVariables[index] + inversion[index]))}</span>
                                            </td>                                                        
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((ingresos[index] - (i + gastosVariables[index] + inversion[index]) )/data.indicesCabeza.slice(1)[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            }
        </>
    );

}

export default ReportConsolidadoEmpresasPreview;