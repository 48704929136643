import React from "react";
import {formatAmount} from "../../../../core/amount";

const GeneralPreview = ( { rows, level } ) => {

    const spaces = () => {
        return {
            marginLeft: level + "em"
        };
    }

    const sum = (row) => {
        var value = row.amount;
        if(row.cuentas != undefined){
            for(var i = 0; i < row.cuentas.length; i++){
                value += sum(row.cuentas[i]);
            }
        }
        return value;
    }

    return (
        <>
            {
                rows == undefined ? <></> : (
                    <>
                        {
                            rows.map((i) => (
                                <>
                                    <tr>
                                        <td>
                                            <span style={spaces()}>{i.codigo + " - " + i.descripcion}</span>
                                        </td>
                                        <td align="right">
                                            <span style={{textAlign: "right"}}>{formatAmount(i.amount)}</span>
                                        </td>
                                    </tr>
                                    {
                                        i.cuentas == undefined ? <></> : (
                                            <GeneralPreview level={level+1} rows={i.cuentas}/>
                                        )
                                    }
                                    {
                                        i.cuentas != undefined ? (
                                            <tr style={{background: "#28B463"}}>
                                                <td>
                                                    <span style={spaces()}><b>{"TOTAL " + i.codigo + " - " + i.descripcion}</b></span>
                                                </td>
                                                <td align="right">
                                                    <span style={{textAlign: "right"}}>{formatAmount(sum(i))}</span>
                                                </td>
                                            </tr>
                                        )
                                        : <></>
                                    }
                                </>
                            ))
                        }
                    </>
                )
            }
        </>
    );

}

export default GeneralPreview;