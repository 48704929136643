import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import {MdImage} from "react-icons/md";

const UserView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id : undefined);
    const [personType, setPersonType] = useState(data != undefined ? data.datos_personales.tipo_persona : "F");
    const [name, setName] = useState(data != undefined ? data.datos_personales.nombres : undefined);
    const [lastName, setLastName] = useState(data != undefined ? data.datos_personales.apellidos : undefined);
    const [email, setEmail] = useState(data != undefined ? data.email : undefined);
    const [birthDay, setBirthDay] = useState(data != undefined ? data.datos_personales.fecha_nacimiento : undefined);
    const [documentNumber, setDocumentNumber] = useState(data != undefined ? data.datos_personales.nro_documento : undefined);
    const [role, setRole] = useState(data != undefined ? data.id_rol : 1);
    const [creationDate, setCreation] = useState(undefined);
    const [lastSession, setLastSession] = useState(undefined);
    const [userCreation, setUserCreation] = useState(undefined);
    const [userLastModification, setUserLastModification] = useState(undefined);
    const [profileImgUsr, setprofileImgUsr] = useState(data != undefined ? data.datos_personales.imagen_perfil : '');

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }      

    useEffect(() => {
        console.log(data);
        setId(data != undefined ? data.id : undefined);
        setPersonType(data != undefined ? data.datos_personales.tipo_persona : "F");
        setName(data != undefined ? data.datos_personales.nombres : undefined);
        setLastName(data != undefined ? data.datos_personales.apellidos : undefined);
        setEmail(data != undefined ? data.email : undefined);
        setBirthDay(data != undefined ? data.datos_personales.fecha_nacimiento : undefined);
        setDocumentNumber(data != undefined ? data.datos_personales.nro_documento : undefined);
        setRole(data != undefined ? data.id_rol : 1);
        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setLastSession(data != undefined ? data.ultima_sesion : undefined);
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : undefined);
        setprofileImgUsr(data != undefined ? data.datos_personales.imagen_perfil : '');
    }, [data]);

    const handleUpdate = (e) => {
        var data = {
            data: {
                id: id,
                email: email,
                rol: role,
                nombres: name,
                apellidos: lastName,
                estado: "A",
                fecha_nacimiento: birthDay,
                tipo_persona: personType,
                nro_documento: documentNumber,
                contactos: []
            }
        }
        update(data);        
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "user", data, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{profileImgUsr === "" || profileImgUsr === undefined ? <span><MdImage className="rounded-circle" style={{fontSize: '1.2em', color: '#e9ecef', marginBottom: '0.4em'}}/></span> : <Image alt="profile" src={profileImgUsr} style={{ height: '6%', width: '6%', marginBottom: '0.5em'}} roundedCircle />} <b>Datos del usuario</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="personType">
                                    <Form.Label>Tipo de persona</Form.Label>
                                    <Form.Control disabled={!edit} as="select" value={personType} onChange={(e) => { setPersonType(e.target.value); }}>
                                        <option value="F">Persona física</option>
                                        <option value="J">Persona juridica</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="email">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control disabled value={email} type="email" onChange={(e) => { setEmail(e.target.value); }} placeholder="Ingrese el correo electrónico" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            personType == "F" ? (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="name">
                                                <Form.Label>Nombres</Form.Label>
                                                <Form.Control disabled={!edit} value={name} onChange={(e) => { setName(e.target.value); }} type="text" placeholder="Ingrese el nombre" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="lastname">
                                                <Form.Label>Apellidos</Form.Label>
                                                <Form.Control disabled={!edit} value={lastName} onChange={(e) => { setLastName(e.target.value); }} type="text" placeholder="Ingrese el apellido" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="bday">
                                                <Form.Label>Fecha de nacimiento</Form.Label>
                                                <Form.Control disabled={!edit} value={birthDay} onChange={(e) => { setBirthDay(e.target.value); }} type="date" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="documentNumber">
                                                <Form.Label>Nro de documento</Form.Label>
                                                <Form.Control disabled={!edit} value={documentNumber} onChange={(e) => { setDocumentNumber(e.target.value); }} type="text" placeholder="Ingrese su número de documento" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="role">
                                                <Form.Label>Rol</Form.Label>
                                                <Form.Control as="select" disabled={!edit} value={role} onChange={(e) => { setRole(e.target.value); }}>
                                                    <option value={2}>ADMINISTRADOR</option>
                                                    <option value={3}>ADMINISTRADOR INTERNO</option>
                                                    <option value={4}>CLIENTE</option>
                                                    <option value={1}>SUPERVISOR</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>

                            ) : (
                                    <>
                                        <Form.Group controlId="lastname">
                                            <Form.Label>Razón social</Form.Label>
                                            <Form.Control disabled={!edit} value={lastName} onChange={(e) => { setLastName(e.target.value); }} type="text" placeholder="Ingrese la razón social" />
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="bday">
                                                    <Form.Label>Fecha de constitucion</Form.Label>
                                                    <Form.Control disabled={!edit} value={birthDay} onChange={(e) => { setBirthDay(e.target.value); }} type="date" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="documentNumber">
                                                    <Form.Label>RUC</Form.Label>
                                                    <Form.Control disabled={!edit} value={documentNumber} onChange={(e) => { setDocumentNumber(e.target.value); }} type="text" placeholder="Ingrese el RUC" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="role">
                                                    <Form.Label>Rol</Form.Label>
                                                    <Form.Control disabled={!edit} as="select" value={role} onChange={(e) => { setRole(e.target.value); }}>
                                                        <option value={2}>ADMINISTRADOR</option>
                                                        <option value={3}>ADMINISTRADOR INTERNO</option>
                                                        <option value={4}>CLIENTE</option>
                                                        <option value={1}>SUPERVISOR</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                )
                        }
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="date" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="lastSession">
                                                <Form.Label>Última sesión</Form.Label>
                                                <Form.Control value={lastSession} disabled type="date" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default UserView;