import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./CompraModal.module.css";

const CompraModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [provider, setProvider] = useState(undefined);;
    const [date, setDate] = useState(undefined);
    const [providers, setProviders] = useState([]);
    const [cantidad, setCantidad] = useState(0);
    const [categories, setCategories] = useState([]);
    const [categoria, setCategoria] = useState(0);    
    const [cantidadSobreGuia, setCantidadSobreGuia] = useState(0);
    const [cantidadRecibida, setCantidadRecibida] = useState(0);
    const [pesoTotal, setPesoTotal] = useState(0);
    const [rangoInicio, setRangoInicio] = useState(undefined);
    const [rangoFin, setRangoFin] = useState(undefined);
    const [observacion, setObservacion] = useState(undefined);
    const [nroGuia, setNroGuia] = useState(undefined);
    const [nroCota, setNroCota] = useState(undefined);
    const [carimbo, setCarimbo] = useState(undefined);

    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const validateParams = () => {
        return true;
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            setCategories([]);
        });
    }    

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }

    const retrieveProviders = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "provider", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setLoading(false);
                    setProviders(data);
                    setProvider(data[0]);
                }
                else {
                    setLoading(false);
                    setProviders([]);
                    setProvider(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setProviders([]);
                setProvider(undefined);
            });
    }

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/entrance/purcharse", body, prepareHeaders())
        .then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            notify.show("Compra registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar la compra", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            var currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    cantidad: parseInt(cantidadRecibida),
                    cantidad_recibida: parseInt(cantidadRecibida),
                    cantidad_segun_guide: parseInt(cantidadSobreGuia),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: date,
                    id_categoria: parseInt(categoria),
                    id_proveedor: parseInt(provider.id),
                    id_estancia: currentFarm.id,
                    nro_guia: parseInt(nroGuia),
                    numero_cuota: parseInt(nroCota),
                    observacion: observacion,
                    peso_total: parseInt(pesoTotal),
                    rango_caravana_desde: parseInt(rangoInicio),
                    rango_caravana_hasta: parseInt(rangoFin),
                    owner: owner ? owner.id : undefined
                }
            };
            save(body);
        }
    }

    useEffect(() => {
        retrieveProviders();
        retrieveCategories();
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva compra</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={date} onChange={(e) => { setDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>N° Guía</Form.Label>
                                    <Form.Control className="karanda-input" value={nroGuia} onChange={(e) => { setNroGuia(e.target.value); }} type="number" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_cota">
                                    <Form.Label>N° Cota</Form.Label>
                                    <Form.Control className="karanda-input" value={nroCota} onChange={(e) => { setNroCota(e.target.value); }} type="number" placeholder="Ingrese la cota" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="proveedor">
                                    <Form.Label>Proveedor</Form.Label>
                                    <Form.Control as="select" value={provider != undefined ? provider.id : undefined} className="karanda-input" onChange={(e) => {setProvider({...provider, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el proveedor</option>
                                        {
                                            providers.map((i) => (
                                                <option value={i.id}>{i.razon_social}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={ owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                                                        
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">

                                        <option value={0} selected disabled>Seleccione la categoría</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }

                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad s/ guía</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidadSobreGuia} onChange={(e) => { setCantidadSobreGuia(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_recibida">
                                    <Form.Label>Cantidad recibida</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidadRecibida} onChange={(e) => { setCantidadRecibida(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="peso_total">
                                    <Form.Label>Peso total (KG)</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoTotal} onChange={(e) => { setPesoTotal(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Rango de caravanas</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Inicio</Form.Label>
                                                <Form.Control className="karanda-input" value={rangoInicio} onChange={(e) => { setRangoInicio(e.target.value); }} type="number"/>
                                            </Form.Group>                                    
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Fin</Form.Label>
                                                <Form.Control className="karanda-input" value={rangoFin} onChange={(e) => { setRangoFin(e.target.value); }} type="number"/>
                                            </Form.Group>                                    
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observacion} onChange={(e) => { setObservacion(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CompraModal;