import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Faltantes.module.css";
import History from "../../components/history";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import { displayDate } from "../../core/date";
import FaltantesModal from "./FaltantesModal";
import FaltantesView from "./FaltantesView";
import DeleteConfirmation from "./DeleteConfirmation";
import TimeFilter from "../../components/time-filter"
import CustomPagination from "../../components/pagination/CustomPagination";
import { formatAmount } from "../../core/amount";

const Faltantes = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);

    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [expense, setExpense] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const [faltantes, setFaltantes] = useState([]);

    const [category, setCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [carimbo, setCarimbo] = useState(0);

    const [owners, setOwners] = useState([]);
    const [owner, setOwner] = useState(undefined);    

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos de hacienda",
            path: "/movimientos"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    }

    const handleSearch = (e) => {
        setPage(1);
        setPageSize(5);
        retrieveFaltantes();
    }

    const retrieveFaltantes = () => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var url = "movements/missings?page=" + page + "&page_size=" + pageSize + "&id_estancia=" + currentFarm.id;
        if (fechaDesde != undefined && fechaHasta != undefined) {
            url += "&fecha_desde=" + fechaDesde + "&fecha_hasta=" + fechaHasta;
        }
        if(owner != undefined && owner.id != 0){
            url += "&id_propietario=" + owner.id;
        }        
        if(category != 0){
            url += "&id_categoria=" + category
         }
        if(carimbo != 0){
            url += "&carimbo=" + carimbo;
        }
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
            .then(rs => {
                if (rs.status == 206) {
                    let { content } = rs.data;
                    setLoading(false);
                    setTotalPages(rs.data.totalPages);
                    setFaltantes(content);
                }
                else {
                    setLoading(false);
                    setTotalPages(1);
                    setFaltantes([]);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setTotalPages(1);
                setFaltantes([]);
            });
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
            }
            else {
                setOwners([]);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
        });
    }        

    const isSupervisor = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isAdmin = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 2;
    }

    const isInternalAdmin = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 3;
    }

    const isClient = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        return cookie.kUserSession.data.rol_id == 4;
    }

    const openUserDetailView = (e, i) => {
        setExpense(i);
        setUserDetailVisibility(true);
    }

    const openUserEdit = (e, i) => {
        setExpense(i);
        setUserDetailVisibility(true);
        setEdit(true);
    }

    const handleDelete = (e, i) => {
        setExpense(i);
        setDeleteVisibility(true);
    }

    useEffect(() => {
        checkSession("purchases", props.history, cookie.kUserSession);
        retrieveFaltantes();
        retrieveCategories();
        retrieveOwners();        
    }, []);

    useEffect(() => {
        retrieveFaltantes();
    }, [page, pageSize]);

    const toPage = (page) => {
        setPage(page);
    };

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setCategories(data);
                }
                else {
                    setCategories([]);
                    setCategory(undefined);
                }            
            })
            .catch(error => {
                console.error(error);
                setCategories([]);
                setCategory(undefined);
            });
    }

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setDeleteVisibility(false); }} data={expense} callback={retrieveFaltantes} />
            <FaltantesView show={showUserDetail} edit={edit} handleClose={() => { setUserDetailVisibility(false); setEdit(false); }} data={expense} callback={retrieveFaltantes} />
            <FaltantesModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrieveFaltantes} />
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Faltantes"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={12}>
                        <section className={styles.side}>
                            <Form className={styles.searchForm}>
                                <TimeFilter callback={callbackFiltroFechas} />
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label className={styles.label}>Propietario</Form.Label>
                                            <Form.Control as="select" value={owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    owners.map((i) => (
                                                        <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>                                   
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginBottom: "15px" }}>
                                            <Form.Label className={styles.label}>Categoria</Form.Label>
                                            <Form.Control as="select" value={category} className="karanda-input" onChange={(e) => setCategory(e.target.value)}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    categories.map((i) => (
                                                        <option value={i.id_categoria}>{i.nombre}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginBottom: "15px" }}>
                                            <Form.Label className={styles.label}>Carimbo</Form.Label>
                                            <Form.Control className="karanda-input" value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" placeholder="Ingrese carimbo" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" onClick={handleSearch}>Buscar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </section>
                    </Col>
                    <Col md={10} xs={12}>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Faltantes</h1>
                            <section className={styles.actions}>
                                {
                                    isSupervisor() || isAdmin() ? (
                                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setModalVisibility(true); }}>Registrar faltante</Button>
                                    ) : (<></>)
                                }
                                {
                                    loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                        faltantes.length == 0 ? (
                                            <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div>
                                        ) : (
                                                isMobile() ? (
                                                    /*                                                 purchases.map((i) => (
                                                                                                        <UserCard expense={i} />
                                                                                                    )) */
                                                    <span>Vista no disponible</span>
                                                ) : (
                                                        <>
                                                            <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                                                <thead>
                                                                    <th>Fecha</th>
                                                                    <th>Categoría</th>
                                                                    <th>Cantidad</th>
                                                                    <th>CBO</th>
                                                                    <th>Observación</th>
                                                                    <th>Acciones</th>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        faltantes.map((i) => (
                                                                            <tr>
                                                                                <td>{displayDate(i.fecha_movimiento)}</td>
                                                                                <td>{i.categoria.nombre}</td>
                                                                                <td>{i.cantidad}</td>
                                                                                <td>{i.carimbo == 99 ? "" : i.carimbo}</td>
                                                                                <td>{i.observacion}</td>
                                                                                <td>
                                                                                    <Icon className={styles.icon} onClick={(e) => { openUserDetailView(e, i); }} icon={eyeFilled} height="15px" />&nbsp;&nbsp;

                                                                                    {
                                                                                        isSupervisor() ? (
                                                                                            <>
                                                                                                <Icon className={styles.icon} onClick={(e) => { openUserEdit(e, i); }} icon={editFilled} height="15px" />&nbsp;&nbsp;
                                                                                                <Icon className={styles.icon} onClick={(e) => { handleDelete(e, i); }} icon={bxsTrash} height="15px" />&nbsp;&nbsp;
                                                                                            </>
                                                                                        ) : (<></>)
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={toPage} totalPages={totalPages}/>
                                                        </>
                                                    )
                                            )
                                    )
                                }
                            </section>
                        </section>                    
                    </Col>
                </Row>

            </section>
        </>
    );

};

export default Faltantes;