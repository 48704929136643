import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./CustomerModal.module.css";

const CustomerModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    
    const [name, setName] = useState(undefined);
    const [ruc, setRuc] = useState(undefined);


    const [nameError, setNameError] = useState(false);
    const [rucError, setRucError] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const validateParams = () => {
        let haveError = false;
        if(!validateField(false, 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        if(!validateField(false, 5, 50, ruc)){
            setRucError(true);
            haveError = true;
        }
        else { setRucError(false); }
        return !haveError;
    }

    const save = (body) => {
        setSaveLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "customer", body, prepareHeaders()).then(rs => {
            setSaveLoading(false);
            callback();
            handleClose();
            notify.show("Cliente registrado exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setSaveLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear el cliente", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            const body = {
                data: {
                    empresa: cookie.kUserSession.data.company.id,
                    razon_social: name,
                    ruc: ruc
                }
            };
            save(body);
        }
    }

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nuevo cliente</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Razón social</Form.Label>
                            <Form.Control className={nameError ? styles.error : ""} type="text" value={name} onChange={(e) => {setName(e.target.value);}} placeholder="Ingrese el nombre de la empresa" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>RUC</Form.Label>
                            <Form.Control className={rucError ? styles.error : ""} type="text" value={ruc} onChange={(e) => {setRuc(e.target.value);}} placeholder="Ingrese el ruc de la empresa"/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            saveLoading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CustomerModal;