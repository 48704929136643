import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./PlanCuentas.module.css";
import History from "../../components/history";
import { Button  } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import PlanCuentasCard from "./PlanCuentasCard";
import axios from 'axios';
import {checkSession} from "../../core/session";
import PlanCuentasModal from "./PlanCuentasCard/PlanCuentasModal";
import DeleteConfirmation from "./PlanCuentasCard/DeleteConfirmation";
import PlanCuentasEditModal from "./PlanCuentasCard/PlanCuentasEditModal";

const PlanCuentas = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [showDelete, setModalDelete] = useState(false);
    const [showEdit, setModalEdit] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Ingresos/Egresos",
            path: "/facturas"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const openNewAccount = (e, i) => {
        setAccount(i);
        setModalVisibility(true);
    }

    const deleteAccount = (e, i) => {
        setAccount(i);
        setModalDelete(true);
    }

    const editAccount = (e, i) => {
        setAccount(i);
        setModalEdit(true);        
    }


    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 3;
    }

    const retrieveAccountingAccounts = () => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/chart_of_accounts?id_estancia=" + currentFarm.id, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                setLoading(false);
                setAccounts(data);
            }
            else {
                setLoading(false);
                setAccounts([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setAccounts([]);
        });
        
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setModalDelete(false); }} data={account} callback={retrieveAccountingAccounts}/>
            <PlanCuentasEditModal show={showEdit} handleClose={() => { setModalEdit(false); }} current={account} callback={retrieveAccountingAccounts} />
            <PlanCuentasModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrieveAccountingAccounts} parent={account}/>

            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Plan de cuentas"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Plan de cuentas</h1>
                    <section className={styles.actions}>
                        {
                            isSupervisor() || isInternalAdmin() ? (
                                <>
                                    <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setAccount(undefined); setModalVisibility(true); }}>Nueva cuenta</Button>&nbsp;&nbsp;
                                </>
                            ) : (<></>)
                        }
                        {/* <Button className={`karanda-btn ${styles.btnRegister}`}>Descargar</Button> */}
                    </section>
                    <br></br>
                    {
                        loading ? (
                            <img src={loadingBtn} height="50" />
                        ) : (
                            <PlanCuentasCard accounts={accounts} openNewAccount={openNewAccount} deleteAccount={deleteAccount} editAccount={editAccount}/>
                        )
                    }
                </section>
            </section>
        </>
    );

};

export default PlanCuentas;