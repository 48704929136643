import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

const GastoDetail = ({ show, handleClose, data }) => {

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Detalles de la factura</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        data != undefined ? (
                            <Table>
                                <thead>
                                    <th>Cuenta contable</th>
                                    <th>Monto</th>
                                </thead>
                                <tbody>
                                    {
                                        data.map((i) => (
                                            <tr>
                                                <td>{i.cuenta_contable.codigo + " - " + i.cuenta_contable.descripcion}</td>
                                                <td style={{textAlign: "right"}}>{"Gs. " + new Intl.NumberFormat("de-DE").format(i.amount)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        ) : <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default GastoDetail;