import React from "react";
import { Table } from "react-bootstrap";
import GeneralPreview from "./GeneralPreview";
import styles from "./ReportGeneralPreview.module.css";

const ReportGeneralPreview = ( {data} ) => {
    
    return (
        <>
            {
                data == undefined ? <></> : 
                <div>
                    <Table responsive className={styles.previewTable}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <GeneralPreview rows={data.rows} level={0}/>
                        </tbody>
                    </Table>
                </div>
            }
        </>
    );

}

export default ReportGeneralPreview;