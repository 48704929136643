import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const DeleteConfirmation = ({ show, handleClose, data, callback }) => {

    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id_compra : undefined);
    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    useEffect(() => {
        setId(data != undefined ? data.id_compra : undefined);
    }, [data]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }
    
    const deleteUser = (e) => {
        setLoading(true);
        axios.delete(process.env.REACT_APP_API_URL + "movements/entrance/purcharse/" + id, prepareHeaders())
        .then(rs => {
            setLoading(false);
            handleClose();
            notify.show("Se ha eliminado el registro de la compra exitosamente", "success");
            callback();
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al eliminar el registro", "error");
        });
    }

    return (
        <>  
            <Notifications />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Confirmar eliminación</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Está seguro que desea eliminar la compra {id} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                    <Button variant="outline-secondary" onClick={deleteUser}>Eliminar</Button>            
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default DeleteConfirmation;