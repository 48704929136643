import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Autosuggest from "react-autosuggest";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate, today} from "../../../core/date";
import styles from "./ExpenseModal.module.css";
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { Icon, InlineIcon } from '@iconify/react';

const ExpenseModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [amount, setAmount] = useState(undefined);
    const [documentNumber, setDocumentNumber] = useState(undefined);;
    const [account, setAccount] = useState(0);
    const [descripcion, setDescripcion] = useState(undefined);
    const [provider, setProvider] = useState(0);
    const [observation, setObservation] = useState(undefined);
    const [expenseDate, setExpenseDate] = useState(today());
    const [providers, setProviders] = useState([]);
    const [details, setDetails] = useState([]);

    const [name, setName] = useState("");

    const [suggestions, setSuggestions] = useState([]);      

    const searchSuggestions = ( {value} ) => {
        setSuggestions(getSuggestions(value));
    }

    const clearSuggestions = () => {
        setSuggestions([]);
    }

    const onChangeName = (event, { newValue } ) => {
        setName(newValue);
    };     

    const inputProps = {
        placeholder: "Escriba la cuenta",
        value: name,
        onChange: onChangeName,
        autocomplete: false
    };    

    const getSuggestions = value => {
        const inputValue = value.toLowerCase();
        return accounts.filter(lang =>
          (lang.descripcion.toLowerCase() + lang.codigo).includes(inputValue)
        );
    };     

    const getSuggestionValue = (suggestion) => {
        setAccount(suggestion.id);
        return suggestion.codigo + " - " + suggestion.descripcion;
    }    

    const renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.codigo + " - " + suggestion.descripcion}</span>
        );
    }    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const searchAccount = (id) => {
        for(var i = 0; i < accounts.length; i++){
            if(parseInt(accounts[i].id) === parseInt(id)){
                return accounts[i];
            }
        }
        return {};
    }

    const validateParams = () => {
/*         let haveError = false;
        if(!validateEmail(email)){
            setEmailError(true);
            haveError = true;
        }
        else { setEmailError(false); }
        if(!validateField(personType == "J", 3, 50, name)){
            setNameError(true);
            haveError = true;
        }
        else { setNameError(false); }
        if(!validateField(false, 3, 50, lastName)){
            setLastNameError(true);
            haveError = true;
        }
        else { setLastNameError(false); } */
/*         if(!validateDate(birthDay)){
            setBirthDayError(true);
            haveError = true;
        } */
/*         if(!validateField(true, 5, 10, documentNumber)){
            setDocumentNumberError(true);
            haveError = true;
        }
        else { setDocumentNumberError(false); }
        return !haveError; */
        return true;
    }

    const retrieveAccountingAccounts = () => {
        setAccountsLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/accounts?id_estancia=" + currentFarm.id + "&tipo=A", prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let { data } = rs.data;
                    setAccountsLoading(false);
                    setAccounts(data);
                    setAccount(data[0]);
                }
                else {
                    setAccountsLoading(false);
                    setAccounts([]);
                    setAccount(0);
                }
            })
            .catch(error => {
                console.error(error);
                setAccountsLoading(false);
                setAccounts([]);
                setAccount(0);
            });        
    }    

    const retrieveProviders = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "customer", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setLoading(false);
                    setProviders(data);
                }
                else {
                    setLoading(false);
                    setProviders([]);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setProviders([]);
            });
    }

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "expense", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();            
            handleClose();
            clean();
            notify.show("Factura registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            const message = error?.response?.data?.data?.ref_description ?? "Ha ocurrido un error al crear la factura";            
            notify.show(message, "error");
        });
    }

    const clean = () => {
        setAccount(0);
        setProvider(0);
        setDetails([]);
        setDocumentNumber("");
        setObservation("");
        setAmount("");
    }

    const cleanDetailHeader = () => {
        setAmount("");
        setAccount(0);
    }

    const buildDetails = () => {
        var detailsEntity = [];
        for(var i = 0; i < details.length; i++){
            var detailEntity = {
                importe: details[i].amount,
                descripcion: details[i].descripcion,
                cuenta_contable_id: parseInt(details[i].account.id),
                cuenta_contable_codigo: details[i].account.codigo
            };
            detailsEntity.push(detailEntity);
        }
        return detailsEntity;
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            var currentFarm = cookie.kUserSession.data["farm"];
            var account = searchAccount(account);
            const body = {
                data: {
                    fecha_gasto: expenseDate,
                    numero_factura: documentNumber,
                    observacion: observation,
                    id_proveedor: parseInt(provider),
                    id_estancia: currentFarm.id,
                    details: buildDetails(),
                    tipo_factura: "I"
                }
            };
            save(body);
        }
    }

    const sumDetails = () => {
        var total = 0;
        for(var i = 0; i < details.length; i++){
            total += parseInt(details[i].amount);
        }
        return total;
    }

    const addDetail = (e) => {
        e.preventDefault();
        if(account != undefined && amount != undefined && account != 0 && (descripcion != undefined && descripcion.trim().length > 0)){
            var detail = {
                account: searchAccount(account),
                descripcion: descripcion,
                amount: amount
            }
            setDetails([...details, detail]);
            cleanDetailHeader();
        }
    }

    const removeFromDetails = (e, detail) => {
        e.preventDefault();
        var detailsNew = [];
        for(var i = 0; i < details.length; i++){
            if(details[i].account.id != detail.account.id){
                detailsNew.push(details[i]);
            }
        }
        setDetails(detailsNew);
    }

    useEffect(() => {
        retrieveProviders();
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva factura</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={expenseDate} onChange={(e) => { setExpenseDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="proveedor">
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Control as="select" value={provider} className="karanda-input" onChange={(e) => {setProvider(e.target.value);}}>
                                        <option value={0} selected disabled>Seleccione el cliente</option>
                                        {
                                            providers.map((i) => (
                                                <option value={i.id}>{i.razon_social}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="factura">
                                    <Form.Label>N° Factura</Form.Label>
                                    <Form.Control className="karanda-input" value={documentNumber} type="text" placeholder="Ingrese el numero de factura" onChange={(e) => {setDocumentNumber(e.target.value); }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="cuenta">
                                    <Form.Label>Cuenta contable</Form.Label>

{/*                                     <Form.Control as="select" value={account} className="karanda-input" onChange={(e) => {setAccount(e.target.value);}}>
                                        <option value={0} selected>Seleccione la cuenta</option>
                                        {
                                            accounts.map((i) => (
                                                <option value={i.id}>{i.codigo + " - " + i.descripcion}</option>
                                            ))
                                        }
                                    </Form.Control> */}

                                    <Autosuggest 
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={searchSuggestions}
                                        onSuggestionsClearRequested={clearSuggestions}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                    />

                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control className="karanda-input" value={descripcion} onChange={(e) => {setDescripcion(e.target.value)}} placeholder="Ingrese la descripción" type="text"/>
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="importe">
                                    <Form.Label>Importe</Form.Label>
                                    <div style={{display: "flex"}}>
                                        <Form.Control className="karanda-input" value={amount} onChange={(e) => { setAmount(e.target.value); }} type="number" placeholder="Ingrese el importe" />&nbsp;&nbsp;
                                        <Button onClick={addDetail}>Agregar</Button>
                                    </div>
                                </Form.Group>                        
                            </Col>
                        </Row>
                        <Row>
                            {
                                details.length > 0 ? (
                                    <Table>
                                        <thead>
                                            <th>Cuenta contable</th>
                                            <th>Descripción</th>
                                            <th>Importe</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {
                                                details.map((i) => (
                                                    <tr>
                                                        <td>{i.account.codigo + " - " + i.account.descripcion}</td>
                                                        <td>{i.descripcion}</td>
                                                        <td style={{textAlign: "right"}}>{"Gs. " + new Intl.NumberFormat("de-DE").format(i.amount)}</td>
                                                        <td>
                                                            <Icon icon={bxsTrash} style={{cursor: "pointer"}} width="20px" onClick={(e) => {removeFromDetails(e, i);}}/>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td style={{textAlign: "right"}}>TOTAL: </td>
                                                <td style={{textAlign: "right"}}>{"Gs. " + new Intl.NumberFormat("de-DE").format(sumDetails())}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                )
                                : (<></>)
                            }
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observation}
                                    type="text" placeholder="Observacion"
                                    onChange={(e) => setObservation(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ExpenseModal;