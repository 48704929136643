import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./InformeGastos.module.css";
import History from "../../components/history";
import { Button, Form, Table, Pagination, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import Notifications, {notify} from "react-notify-toast";
import TimeFilter from "../../components/time-filter"
import {formatAmount} from "../../core/amount";
import { displayDate } from "../../core/date";
import GastoDetail from "./GastoDetail";
import ReportAccountsPreview from "./ReportAccountsPreview";
import ReportConsolidadoPreview from "./ReportConsolidadoPreview/ReportConsolidadoPreview";
import ReportConsolidadoMensualPreview from "./ReportConsolidadoMensualPreview";
import ReportGeneralPreview from "./ReportGeneralPreview";
import ReportComparativaPreview from "./ReportComparativaPreview";
import ReportConsolidadoEmpresasPreview from "./ReportConsolidadoEmpresasPreview";
import ReportMensualPreview from "./ReportMensualPreview";

const InformeGastos = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [data, setData] = useState(undefined);
    const [loadingPrev, setLoadingPrev] = useState(false);
    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [showAsignations, setAsignationsVisibility] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(undefined);

    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [expense, setExpense] = useState(undefined);
    const [showExpenseDetail, setShowExpenseDetail] = useState(false);
    const [edit, setEdit] = useState(false);

    const [loadingXls, setLoadingXls] = useState(false);

    const [provider, setProvider] = useState(undefined);
    const [providers, setProviders] = useState([]);
    const [tipoInforme, setTipoInforme] = useState("G");
    const [mes, setMes] = useState(0);

    const [mesInicial, setMesInicial] = useState(1);
    const [mesFinal, setMesFinal] = useState(1);

    const [anho, setAnho] = useState(new Date().getFullYear());
    const [anho2, setAnho2] = useState(new Date().getFullYear());    
    const [meses, setMeses] = useState([
        {
            code: 1,
            name: "Enero"
        },
        {
            code: 2,
            name: "Febrero"
        },
        {
            code: 3,
            name: "Marzo"
        },
        {
            code: 4,
            name: "Abril"
        },
        {
            code: 5,
            name: "Mayo"
        },
        {
            code: 6,
            name: "Junio"
        },
        {
            code: 7,
            name: "Julio"
        },
        {
            code: 8,
            name: "Agosto"
        },
        {
            code: 9,
            name: "Septiembre"
        },
        {
            code: 10,
            name: "Octubre"
        },
        {
            code: 11,
            name: "Noviembre"
        },
        {
            code: 12,
            name: "Diciembre"
        }

    ])    

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Ingresos/Egresos",
            path: "/facturas"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    }

    const retrieveAccountingAccounts = () => {
        setAccountsLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/accounts?id_estancia=" + currentFarm.id, prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let { data } = rs.data;
                    setAccountsLoading(false);
                    setAccounts(data);
                }
                else {
                    setAccountsLoading(false);
                    setAccounts([]);
                    setAccount(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setAccountsLoading(false);
                setAccounts([]);
                setAccount(undefined);
            });        
    }

    const retrieveProviders = () => {
        axios.get(process.env.REACT_APP_API_URL + "provider", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setProviders(data);
                }
                else {
                    setProviders([]);
                    setProvider(undefined);
                }            
            })
            .catch(error => {
                console.error(error);
                setProviders([]);
                setProvider(undefined);
            });
    }    

    const preview = (e) => {
        e.preventDefault();
        setLoadingPrev(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var currentCompany = cookie.kUserSession.data["company"];
        var url = "";
        if(tipoInforme == "G"){
            url =  "expense/report/accounts/preview?id_estancia=" + currentFarm.id;
            if(fechaDesde != undefined && fechaHasta != undefined){
                url += "&fecha_inicio=" + fechaDesde + "&fecha_fin=" + fechaHasta;
            }
            if(account != undefined && account.id != 0){
                url += "&accounting_account=" + account.id;
            }
            if(provider != undefined && provider.id != 0){
                url += "&provider=" + provider.id;
            }
        }
        else if(tipoInforme === "C"){
            url =  "expense/report/consolidado/preview?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(mes != 0){
                url += "&mes=" + mes;
            }
            if(anho != 0){
                url += "&anho=" + anho;
            }
        }
        else if(tipoInforme === "CP"){
            url =  "expense/report/comparativa/preview?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&periodo_1=" + anho;
            }
            if(anho2 != 0){
                url += "&periodo_2=" + anho2;
            }            
        }
        else if(tipoInforme === "CM"){
            url =  "expense/report/consolidado/mensual/preview?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
            if(mesInicial != 0){
                url += "&mes_inicio=" + mesInicial;
            }                        
            if(mesFinal != 0){
                url += "&mes_fin=" + mesFinal;
            }
        }
        else if(tipoInforme == "CE"){
            url =  "expense/report/consolidado/empresas/preview?id_empresa=" + currentCompany.id + "&id_estancia=" + currentFarm.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
        }
        else if(tipoInforme == "GN"){
            url =  "expense/report/general/preview?id_estancia=" + currentFarm.id;
            if(fechaDesde != undefined && fechaHasta != undefined){
                url += "&fecha_inicio=" + fechaDesde + "&fecha_fin=" + fechaHasta;
            }
        }        
        else if(tipoInforme == "M"){
            url =  "expense/report/mensual/preview?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
            if(mesInicial != 0){
                url += "&mes_inicio=" + mesInicial;
            }                        
            if(mesFinal != 0){
                url += "&mes_fin=" + mesFinal;
            }
        }
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(res => {
            setData(res.data);
            setLoadingPrev(false);
        })
        .catch(error => {
            setLoadingPrev(false);
            setData(undefined);
        });
    }

    const generarExcel = (e) => {
        e.preventDefault();
        setLoadingXls(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var currentCompany = cookie.kUserSession.data["company"];
        var url = "";
        if(tipoInforme == "G"){
            url =  "expense/report/accounts?id_estancia=" + currentFarm.id;
            if(fechaDesde != undefined && fechaHasta != undefined){
                url += "&fecha_inicio=" + fechaDesde + "&fecha_fin=" + fechaHasta;
            }
            if(account != undefined && account.id != 0){
                url += "&accounting_account=" + account.id;
            }
            if(provider != undefined && provider.id != 0){
                url += "&provider=" + provider.id;
            }
        }
        else if(tipoInforme === "C"){
            url =  "expense/report/consolidado?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(mes != 0){
                url += "&mes=" + mes;
            }
            if(anho != 0){
                url += "&anho=" + anho;
            }
        }
        else if(tipoInforme === "CP"){
            url =  "expense/report/comparativa?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&periodo_1=" + anho;
            }
            if(anho2 != 0){
                url += "&periodo_2=" + anho2;
            }            
        }
        else if(tipoInforme === "CM"){
            url =  "expense/report/consolidado/mensual?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
            if(mesInicial != 0){
                url += "&mes_inicio=" + mesInicial;
            }                        
            if(mesFinal != 0){
                url += "&mes_fin=" + mesFinal;
            }
        }
        else if(tipoInforme == "CE"){
            url =  "expense/report/consolidado/empresas?id_empresa=" + currentCompany.id + "&id_estancia=" + currentFarm.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
        }
        else if(tipoInforme == "GN"){
            url =  "expense/report/general?id_estancia=" + currentFarm.id;
            if(fechaDesde != undefined && fechaHasta != undefined){
                url += "&fecha_inicio=" + fechaDesde + "&fecha_fin=" + fechaHasta;
            }            
        }
        else if(tipoInforme == "M"){
            url =  "expense/report/mensual?id_estancia=" + currentFarm.id + "&id_empresa=" + currentCompany.id;
            if(anho != 0){
                url += "&anho=" + anho;
            }
            if(mesInicial != 0){
                url += "&mes_inicio=" + mesInicial;
            }                        
            if(mesFinal != 0){
                url += "&mes_fin=" + mesFinal;
            }
        }        

        fetch(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(response => {
            const filename =  response.headers.get("content-disposition").split("filename=")[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                setLoadingXls(false);
                notify.show("Reporte generado exitosamente", "success");
            });
        }).catch(error => {
            notify.show("Ha ocurrido un error al generar el reporte", "error");
            console.error(error);
            setLoadingXls(false);            
        });        
    }

    const verDetalles = (i) => {
        setExpense(i.details);
        setShowExpenseDetail(true);
    } 

    useEffect(() => {
        checkSession("expenses", props.history, cookie.kUserSession);
        retrieveProviders();
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <GastoDetail show={showExpenseDetail} handleClose={(e) => {setShowExpenseDetail(false);}} data={expense}/>
            <Notifications />
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Informe de gastos"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <section className={styles.side}>
                            <Form className={styles.searchForm}>
                                {
                                    tipoInforme === "G" ? (
                                        <>
                                            <TimeFilter callback={callbackFiltroFechas}/>
                                            <Row>
                                                <Col>
                                                    <Form.Group style={{marginBottom: "15px"}}>
                                                        <Form.Label htmlFor="account" className={styles.label}>Cuenta contable</Form.Label>
                                                        <Form.Control id="account" className={"karanda-input"} as="select" value={account != undefined ? account.id : undefined} onChange={(e) => {setAccount({...account, id: e.target.value});}}>
                                                        <option value={0}>      TODOS      </option>
                                                        {
                                                            accounts.map((i) => (
                                                                <option value={i.id}>{i.codigo + " - " + i.descripcion}</option>
                                                            ))
                                                        }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group style={{marginBottom: "15px"}}>
                                                        <Form.Label className={styles.label}>Proveedor</Form.Label>
                                                        <Form.Control as="select" value={provider != undefined ? provider.id : undefined} className="karanda-input" onChange={(e) => {setProvider({...provider, id: e.target.value});}}>
                                                            <option value={0}>      TODOS      </option>
                                                            {
                                                                providers.map((i) => (
                                                                    <option value={i.id}>{i.razon_social}</option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>                                            
                                                </Col>
                                            </Row>
                                        </>                                    
                                    ) : (
                                        <>
                                            {
                                                tipoInforme === "C" ? (
                                                    <Row>
                                                        <Col>
                                                            <Form.Group style={{marginBottom: "15px"}}>
                                                                <Form.Label className={styles.label}>Periodo {anho != undefined ? ("(" + (anho-1) + "/" + anho + ")") : undefined}</Form.Label>
                                                                <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                                            </Form.Group>                          
                                                        </Col>
                                                    </Row>
                                                ) : 
                                                (
                                                    <>
                                                        {
                                                            tipoInforme === "CM" ? (
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Group style={{marginBottom: "15px"}}>
                                                                                <Form.Label className={styles.label}>Periodo {anho != undefined ? ("(" + (anho-1) + "/" + anho + ")") : undefined}</Form.Label>
                                                                                <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                                                            </Form.Group>                          
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Group style={{marginBottom: "15px"}}>
                                                                                <Form.Label className={styles.label}>Mes inicial ({mesInicial >= 7 ? (anho-1) : (anho)})</Form.Label>
                                                                                <Form.Control as="select" className="karanda-input" value={mesInicial} onChange={(e) => {setMesInicial(e.target.value)}}>
                                                                                    <option value={1}>Enero</option>
                                                                                    <option value={2}>Febrero</option>
                                                                                    <option value={3}>Marzo</option>
                                                                                    <option value={4}>Abril</option>
                                                                                    <option value={5}>Mayo</option>
                                                                                    <option value={6}>Junio</option>
                                                                                    <option value={7}>Julio</option>
                                                                                    <option value={8}>Agosto</option>
                                                                                    <option value={9}>Septiembre</option>
                                                                                    <option value={10}>Octubre</option>
                                                                                    <option value={11}>Noviembre</option>
                                                                                    <option value={12}>Diciembre</option>
                                                                                </Form.Control>
                                                                            </Form.Group>                          
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Group style={{marginBottom: "15px"}}>
                                                                                <Form.Label className={styles.label}>Mes final ({mesFinal >= 7 ? (anho-1) : (anho)})</Form.Label>
                                                                                <Form.Control as="select" className="karanda-input" value={mesFinal} onChange={(e) => {setMesFinal(e.target.value)}}>
                                                                                    <option value={1}>Enero</option>
                                                                                    <option value={2}>Febrero</option>
                                                                                    <option value={3}>Marzo</option>
                                                                                    <option value={4}>Abril</option>
                                                                                    <option value={5}>Mayo</option>
                                                                                    <option value={6}>Junio</option>
                                                                                    <option value={7}>Julio</option>
                                                                                    <option value={8}>Agosto</option>
                                                                                    <option value={9}>Septiembre</option>
                                                                                    <option value={10}>Octubre</option>
                                                                                    <option value={11}>Noviembre</option>
                                                                                    <option value={12}>Diciembre</option>                                                                            
                                                                                </Form.Control>
                                                                            </Form.Group>                          
                                                                        </Col>                                                                
                                                                    </Row>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        tipoInforme === "GN" ? (
                                                                            <TimeFilter callback={callbackFiltroFechas}/>
                                                                        ) :
                                                                        <>
                                                                            {
                                                                                tipoInforme === "CE" ? (
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Group style={{marginBottom: "15px"}}>
                                                                                                <Form.Label className={styles.label}>Año</Form.Label>
                                                                                                <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </Row>                                                                                
                                                                                ) : 
                                                                                <>
                                                                                    {
                                                                                        tipoInforme === "CP" ? (
                                                                                            <>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Group style={{marginBottom: "15px"}}>
                                                                                                            <Form.Label className={styles.label}>Periodo 1 {anho != undefined ? ("(" + (anho-1) + "/" + anho + ")") : undefined}</Form.Label>
                                                                                                            <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                                                                                        </Form.Group>                          
                                                                                                    </Col>                                                        
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Group style={{marginBottom: "15px"}}>
                                                                                                            <Form.Label className={styles.label}>Periodo 2 {anho2 != undefined ? ("(" + (anho2-1) + "/" + anho2 + ")") : undefined}</Form.Label>
                                                                                                            <Form.Control className={"karanda-input"} type="number" value={anho2} onChange={(e) => {setAnho2(e.target.value);}} />
                                                                                                        </Form.Group>                          
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </>
                                                                                        )
                                                                                        : (
                                                                                            <>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Group style={{marginBottom: "15px"}}>
                                                                                                            <Form.Label className={styles.label}>Año</Form.Label>
                                                                                                            <Form.Control className={"karanda-input"} type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />
                                                                                                        </Form.Group>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Group style={{marginBottom: "15px"}}>
                                                                                                            <Form.Label className={styles.label}>Mes inicial</Form.Label>
                                                                                                            <Form.Control as="select" className="karanda-input" value={mesInicial} onChange={(e) => {setMesInicial(e.target.value)}}>
                                                                                                                <option value={1}>Enero</option>
                                                                                                                <option value={2}>Febrero</option>
                                                                                                                <option value={3}>Marzo</option>
                                                                                                                <option value={4}>Abril</option>
                                                                                                                <option value={5}>Mayo</option>
                                                                                                                <option value={6}>Junio</option>
                                                                                                                <option value={7}>Julio</option>
                                                                                                                <option value={8}>Agosto</option>
                                                                                                                <option value={9}>Septiembre</option>
                                                                                                                <option value={10}>Octubre</option>
                                                                                                                <option value={11}>Noviembre</option>
                                                                                                                <option value={12}>Diciembre</option>
                                                                                                            </Form.Control>
                                                                                                        </Form.Group>                          
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Group style={{marginBottom: "15px"}}>
                                                                                                            <Form.Label className={styles.label}>Mes final</Form.Label>
                                                                                                            <Form.Control as="select" className="karanda-input" value={mesFinal} onChange={(e) => {setMesFinal(e.target.value)}}>
                                                                                                                <option value={1}>Enero</option>
                                                                                                                <option value={2}>Febrero</option>
                                                                                                                <option value={3}>Marzo</option>
                                                                                                                <option value={4}>Abril</option>
                                                                                                                <option value={5}>Mayo</option>
                                                                                                                <option value={6}>Junio</option>
                                                                                                                <option value={7}>Julio</option>
                                                                                                                <option value={8}>Agosto</option>
                                                                                                                <option value={9}>Septiembre</option>
                                                                                                                <option value={10}>Octubre</option>
                                                                                                                <option value={11}>Noviembre</option>
                                                                                                                <option value={12}>Diciembre</option>                                                                            
                                                                                                            </Form.Control>
                                                                                                        </Form.Group>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                                
                                            }
                                        </>

                                    )
                                }                   

                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label className={styles.label}>Tipo de informe</Form.Label>
                                            <Form.Control className={"karanda-input"} as="select" value={tipoInforme} onChange={(e) => {setTipoInforme(e.target.value);}}>
                                                <option value="G">Cuentas</option>
                                                <option value="C">Consolidado</option>
                                                <option value="CE">Consolidado empresa</option>
                                                <option value="CM">Consolidado mensual</option>
                                                <option value="M">Mensual</option>
                                                <option value="CP">Comparativo</option>
                                                <option value="GN">General</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" disabled={loadingPrev} onClick={preview}>
                                            Previsualizar
                                            {
                                                loadingPrev ? (
                                                    <>
                                                        &nbsp;&nbsp;<img src={loadingBtn} height="15" />
                                                    </>
                                                ) : <></>
                                            }
                                        </Button>                                    
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" disabled={loadingXls} onClick={generarExcel}>
                                            Descargar Excel
                                            {
                                                loadingXls ? (
                                                    <>
                                                        &nbsp;&nbsp;<img src={loadingBtn} height="15" />
                                                    </>
                                                ) : <></>
                                            }
                                        </Button>                                    
                                    </Col>
                                </Row>
                            </Form>                            
                        </section>
                    </Col>
                    <Col>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Informe de gastos</h1>
                            <section className={styles.actions}>
                                <section style={{marginTop: "1em"}}>
                                    {
                                        tipoInforme === "G" ? (
                                            <ReportAccountsPreview data={data}/>
                                        ) : 
                                        <>
                                            {
                                                tipoInforme === "C" ? (
                                                    <ReportConsolidadoPreview data={data}/>
                                                ) : 
                                                <>
                                                    {
                                                        tipoInforme === "CM" ? (
                                                            <ReportConsolidadoMensualPreview data={data}/>
                                                        ) :
                                                        <>
                                                            {
                                                                tipoInforme === "CP" ? (
                                                                    <ReportComparativaPreview data={data}/>
                                                                ) : 
                                                                <>
                                                                    {
                                                                        tipoInforme === "CE" ? (
                                                                            <ReportConsolidadoEmpresasPreview data={data}/>
                                                                        ) : 
                                                                        <>
                                                                            {
                                                                                tipoInforme === "GN" ? (
                                                                                    <ReportGeneralPreview data={data} />
                                                                                )
                                                                                : (
                                                                                    <ReportMensualPreview data={data} />
                                                                                )
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </section>
                            </section>
                        </section>                    
                    </Col>
                </Row>
            </section>
        </>
    );

};

export default InformeGastos;