import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../../core/validators";
import {dateToRequestDate} from "../../../../core/date";
import styles from "./ProyeccionModal.module.css";
import ideaIcon from '@iconify/icons-carbon/idea';
import { Icon } from '@iconify/react';

const ProyeccionModal = ({ show, handleClose, parent, callback }) => {

    const loadingBtn = require("../../../../assets/loaders/btn-loading.gif");

    const [cookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [accountsLoading, setAccountsLoading] = useState(false);


    const [mes, setMes] = useState(0);
    const [anho, setAnho] = useState(new Date().getFullYear());
    const [meses, setMeses] = useState([
        {
            code: 1,
            name: "Enero"
        },
        {
            code: 2,
            name: "Febrero"
        },
        {
            code: 3,
            name: "Marzo"
        },
        {
            code: 4,
            name: "Abril"
        },
        {
            code: 5,
            name: "Mayo"
        },
        {
            code: 6,
            name: "Junio"
        },
        {
            code: 7,
            name: "Julio"
        },
        {
            code: 8,
            name: "Agosto"
        },
        {
            code: 9,
            name: "Septiembre"
        },
        {
            code: 10,
            name: "Octubre"
        },
        {
            code: 11,
            name: "Noviembre"
        },
        {
            code: 12,
            name: "Diciembre"
        }

    ]);
    const [proyeccion, setProyeccion] = useState(undefined);
    const [accountId, setAccountId] = useState(0);

    const [accounts, setAccounts] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const validateParams = () => {
        return true;
    }

    const retrieveAccountingAccounts = () => {
        setAccountsLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/accounts?id_estancia=" + currentFarm.id, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if (rs.status == 200) {
                let { data } = rs.data;
                setAccountsLoading(false);
                setAccounts(data);
            }
            else {
                setAccountsLoading(false);
                setAccounts([]);
            }
        })
        .catch(error => {
            console.error(error);
            setAccountsLoading(false);
            setAccounts([]);
        });        
    }    

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "expense/projection", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            handleClose();
            notify.show("Proyeccion registrada exitosamente", "success");
            callback();
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al crear la proyeccion", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){

            var currentCompany = cookie.kUserSession.data["company"];
            var currentFarm = cookie.kUserSession.data["farm"];

            const body = {

                data: {
                    anho: parseInt(anho),
                    id_cuenta: accountId,
                    id_empresa: currentCompany.id,
                    id_estancia: currentFarm.id,
                    mes: parseInt(mes),
                    proyeccion: proyeccion
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveAccountingAccounts();
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva proyección</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Mes</Form.Label>
                                    <Form.Control as="select" value={mes} onChange={(e) => {setMes(e.target.value);}}>
                                        <option value={0}>TODOS</option>
                                        {
                                            meses.map((i) => (
                                                <option value={i.code}>{i.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                          
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label>Año</Form.Label>
                                    <Form.Control type="number" value={anho} onChange={(e) => {setAnho(e.target.value);}} />&nbsp;&nbsp;
                                </Form.Group>
                            </Col>                                
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group controlId="codigo">
                                    <Form.Label>Cuenta contable</Form.Label>
                                    <Form.Control as="select" value={accountId} onChange={(e) => {setAccountId(e.target.value);}}>
                                        <option value={0}>Seleccione</option>
                                        {
                                            accounts.map((i) => (
                                                <option value={i.id}>{i.codigo + " - " + i.descripcion}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="importe">
                                    <Form.Label>Proyección</Form.Label>
                                    <div style={{display: "flex"}}>
                                        <Form.Control type="number" placeholder="Ingrese su proyección" value={proyeccion} onChange={(e) => {setProyeccion(e.target.value)}} />&nbsp;&nbsp;
                                        <Button className="karanda-btn">
                                            <Icon icon={ideaIcon} width="20px"/>
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ProyeccionModal;