import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Clientes.module.css";
import History from "../../components/history";
import { Button, Form, Table } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import roundAssignment from '@iconify/icons-ic/round-assignment';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import CustomerModal from "./CustomerModal";
import DeleteConfirmation from "./DeleteConfirmation";
import CustomerView from "./CustomerView";

const Clientes = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [customer, setCustomer] = useState(0);
    const [showCustomer, setCustomerVisibility] = useState(false);
    const [showRegisterModal, setRegisterModalVisibility] = useState(false);
    const [edit, setEdit] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveCustomers = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "customer", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setLoading(false);
                setCustomers(data);
            }
            else {
                setLoading(false);
                setCustomers([]);
            }
        })
        .catch(error => {
            setLoading(false);
            setCustomers([]);
        });
    }

    const handleEdit = (e, i) => {
        setCustomer(i);
        setEdit(true);
        setCustomerVisibility(true);
    }

    const handleDelete = (e, i) => {
        setCustomer(i);
        setDeleteVisibility(true);
    }    

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveCustomers();
    }, []);

    return (
        <>
            <CustomerView show={showCustomer} edit={edit} handleClose={() => {setCustomerVisibility(false); setEdit(false); }} data={customer} callback={retrieveCustomers} />
            <DeleteConfirmation show={showDelete} handleClose={() => {setDeleteVisibility(false);}} data={customer} callback={retrieveCustomers}/>
            <CustomerModal show={showRegisterModal} handleClose={() => {setRegisterModalVisibility(false); }} callback={retrieveCustomers} />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Gestión de clientes"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Gestión de clientes</h1>
                    <section className={styles.actions}>
                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={() => {setRegisterModalVisibility(true);}}>Registrar cliente</Button>
                        {
                            loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (

                                customers.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    isMobile() ? (
                                        customers.map((i) => (
                                            <></>
                                        ))
                                    ) : (
                                        <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                            <thead>
                                                <th>Razón social</th>
                                                <th>RUC</th>
                                                <th>Empresa</th>
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    customers.map((i) => (
                                                        <tr>
                                                            <td>{i.razon_social}</td>
                                                            <td>{i.ruc}</td>
                                                            <td>{i.empresa.nombre}</td>
                                                            <td>
                                                                <Icon className={styles.icon}  icon={editFilled} height="30px" onClick={(e) => { handleEdit(e, i); }} />&nbsp;&nbsp;
                                                                <Icon className={styles.icon}  icon={bxsTrash} height="30px" onClick={(e) => {handleDelete(e, i);}} />&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                )
                            )
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Clientes;