import React from "react";
import { Card, Accordion } from "react-bootstrap";
import { Icon } from '@iconify/react';
import plusBoxMultiple from '@iconify/icons-mdi/plus-box-multiple';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { useCookies } from 'react-cookie';
import styles from "./CuentaCard.module.css";

const CuentaCard = ({cuenta, openNewAccount, editAccount, deleteAccount}) => {

    const [cookie] = useCookies(['kUserSession']);    

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 3;
    }    


    return (
        <>
            {
                cuenta.cuentas == undefined || cuenta.cuentas.length == 0 ? (

                    <Card className={styles.child}>
                        <Card.Header>
                            <span>{cuenta.codigo + " - " + cuenta.descripcion}</span>
                            <div style={{float: "right"}}>
                                {
                                    isSupervisor() || isInternalAdmin() ? (
                                        <>
                                            <Icon onClick={(e) => { openNewAccount(e, cuenta); }} className={styles.icon} icon={plusBoxMultiple} height="20px" />&nbsp;
                                            <Icon onClick={(e) => { editAccount(e, cuenta); }} className={styles.icon} icon={editFilled} height="20px" />&nbsp;
                                            <Icon onClick={(e) => { deleteAccount(e, cuenta); }} className={styles.icon} icon={bxsTrash} height="20px" />
                                        </>
                                    ) : (<></>)
                                }
                            </div>
                        </Card.Header>
                    </Card>
                ) : (

                    <Accordion>
                        <Card className={styles.parent}>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <span>{cuenta.codigo + " - " + cuenta.descripcion}</span>
                                <div style={{float: "right"}}>
                                    {
                                        isSupervisor() || isInternalAdmin() ? (
                                            <>
                                                <Icon onClick={(e) => { openNewAccount(e, cuenta); }} className={styles.icon} icon={plusBoxMultiple} height="20px" />&nbsp;
                                                <Icon onClick={(e) => { editAccount(e, cuenta); }} className={styles.icon} icon={editFilled} height="20px" />&nbsp;
                                                <Icon onClick={(e) => { deleteAccount(e, cuenta); }} className={styles.icon} icon={bxsTrash} height="20px" />                                                        
                                            </>
                                        ) : (<></>)
                                    }
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className={styles.parentBody}>
                                    {
                                        cuenta.cuentas.map((i) => (
                                            <CuentaCard cuenta={i} openNewAccount={openNewAccount} deleteAccount={deleteAccount} editAccount={editAccount}/>
                                        ))
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                )
            }
        </>

    );

}

export default CuentaCard;