import React, {useEffect, useState} from "react";
import styles from "./Header.module.css";
import { DropdownButton, Dropdown, Button, Form, Row, Col } from "react-bootstrap";

import { TiUser } from "react-icons/ti";
import { FiMenu, FiBarChart } from "react-icons/fi";
import { MdNotificationsActive } from "react-icons/md";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { GrPowerCycle } from "react-icons/gr";

import { useCookies } from 'react-cookie';
import {checkSession} from "../../core/session";
import { isMobile } from "../../core/session";
import MiniButton from "../mini-button";

import logoutIcon from '@iconify/icons-fe/logout';
import controlsIcon from '@iconify/icons-il/controls';
import bxsUser from '@iconify/icons-bx/bxs-user';
import ChangeFarm from "./ChangeFarm";

import logo from "../../assets/img/karanda.svg";

const Header = ({ companies, farms, history }) => {

    const [menuToggled, toggleMenu] = useState(false);
    
    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [showChangeFarm, changeFarmVisibility] = useState(false);

    const goToControlPanel = (e) => history.push("/control-panel");
    const goToProfile = (e) => history.push("/profile");
    const goToClientes = (e) => history.push("/clientes");
    const goToProveedores = (e) => history.push("/proveedores");

    const handleLogout = () => {
        removeCookie("kUserSession");
        history.push("/login");
    }

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isClient = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 4;
    }    

    useEffect(() => {
        checkSession("home", history, cookie.kUserSession);
    }, []);

    const showCompanyAndFarm = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return (<></>);
        }
        var currentCompany = cookie.kUserSession.data["company"];
        var currentFarm = cookie.kUserSession.data["farm"];
        if(!isMobile()){
            return (
                <div className={styles.selectGroup}>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group style={{margin: "0"}}>
                                    <Form.Label className={styles.label}>Empresa</Form.Label>
                                    <Form.Control className="karanda-input" value={currentCompany.nombre} type="text"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group style={{margin: "0"}}>
                                    <Form.Label className={styles.label}>Estancia</Form.Label>
                                    <div style={{display: "flex"}}>
                                        <Form.Control className="karanda-input" value={currentFarm.nombre} type="text"/>
                                        &nbsp;&nbsp;&nbsp;
                                        <GrPowerCycle onClick={(e) => {changeFarmVisibility(true);}} size="30" style={{cursor: "pointer"}}/>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>                
            );
        }
        else {
            // TODO: Vista en menu desplegable
            return (<></>);
        }
    }

    return (
        <>
            <ChangeFarm show={showChangeFarm} handleClose={() => {changeFarmVisibility(false);}} history={history}/>
            <div className={styles.headerContainer}>
                <div className={styles.leftSide}>
                    <img src={logo} alt="Logo Karanda Consultores S.A" height="50px" />&nbsp;&nbsp;
                    { showCompanyAndFarm() }


        {/*                 <div className={styles.selectGroup}>
                        <span className={styles.label}>Empresa</span>
                        <Select className={styles.select}>
                            <Option>CHAI S.A</Option>
                        </Select>
                    </div>
                    &nbsp;&nbsp;
                    <div className={styles.selectGroup}>
                        <span className={styles.label}>Estancia</span>
                        <Select className={styles.select}>
                            <Option>DIANA I</Option>
                            <Option>DIANA II</Option>
                        </Select>
                    </div> */}
                </div>
                {
                    !isMobile() ? (
                        <div className={styles.rightSide}>
                            {
                                cookie.kUserSession == undefined || cookie.kUserSession == null ? (<></>) : (
                                    <span className={styles.mail}>{cookie.kUserSession.data.email}</span>
                                )
                            }&nbsp;&nbsp;
                            <DropdownButton alignRight title={<BsFillPersonLinesFill />} bsPrefix={styles.dropdown} id="user-menu">
                                <Dropdown.Item eventKey="1" onClick={goToProfile}>Perfil</Dropdown.Item>
                                {
                                    !isSupervisor() ? (<></>) : (
                                        <Dropdown.Item eventKey="2" onClick={goToControlPanel}>Panel de control</Dropdown.Item>
                                    )
                                }
                                {
                                    isClient() ? (<></>) : (
                                        <>
                                            <Dropdown.Item eventKey="3" onClick={goToClientes}>Clientes</Dropdown.Item>
                                            <Dropdown.Item eventKey="4" onClick={goToProveedores}>Proveedores</Dropdown.Item>
                                        </>
                                    )                                    
                                }
                                <Dropdown.Item eventKey="5" onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
                                &nbsp;&nbsp;
                            </DropdownButton>
{/*                             &nbsp;&nbsp;
                            <DropdownButton alignRight title={<FaBell />} id="notifications" bsPrefix={styles.dropdown}>
                                <Dropdown.Item eventKey="1">En construcción</Dropdown.Item>
                            </DropdownButton> */}
                        </div>
                    ) : (
                        <div className={styles.rightSide}>
                            {
                                cookie.kUserSession == undefined || cookie.kUserSession == null ? (<></>) : (
                                    <span className={styles.mail}>{cookie.kUserSession.data.email}</span>
                                )
                            }&nbsp;&nbsp;
                            <TiUser size={"2em"}/>
                            &nbsp;&nbsp;
                            <MdNotificationsActive size={"2em"}/>
                            &nbsp;&nbsp;
                            {
                                menuToggled ? (
                                    <FiBarChart onClick={() => {toggleMenu(!menuToggled);}} size={"2em"} />
                                ) : (
                                    <FiMenu onClick={() => {toggleMenu(!menuToggled);}} size={"2em"} />
                                )
                            }
                        </div>
                    )
                }            
            </div>
            {
                menuToggled ? (
                    <div className={styles.headerContainer}>
                        <MiniButton history={history} menu="Perfil" path="/profile" representation={bxsUser} />
                        <MiniButton history={history} menu="Panel" path="/control-panel" representation={controlsIcon} />
                        <MiniButton history={history} menu="Cerrar sesión" path="/control-panel" representation={logoutIcon} />
                    </div>
                ) : (
                    <></>
                )
            }
        </>
    );

};

export default Header;