import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Proveedores.module.css";
import History from "../../components/history";
import { Button, Form, Table } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import roundAssignment from '@iconify/icons-ic/round-assignment';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import ProviderModal from "./ProviderModal";
import DeleteConfirmation from "./DeleteConfirmation";
import ProviderView from "./ProviderView";

const Proveedores = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [provider, setCompany] = useState(0);
    const [showRegisterModal, setRegisterModalVisibility] = useState(false);
    const [edit, setEdit] = useState(false);
    const [showProvider, showProviderVisiblity] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveProviders = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "provider", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                console.log(data);
                setLoading(false);
                setProviders(data);
            }
            else {
                setLoading(false);
                setProviders([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setProviders([]);
        });
    }

    const handleEdit = (e, i) => {
        setCompany(i);
        setEdit(true);
        showProviderVisiblity(true);
    }

    const handleDelete = (e, i) => {
        setCompany(i);
        setDeleteVisibility(true);
    }    

    const openCompany = (e, i) => {
        setCompany(i);
        showProviderVisiblity(true);
    }        

    const retrieveTipoProveedor = (tipoProveedor) => {
        if(tipoProveedor == "R"){
            return "Recursos";
        }
        else {
            return "Ganado";
        }
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveProviders();
    }, []);

    return (
        <>
            <ProviderView show={showProvider} edit={edit} handleClose={() => {showProviderVisiblity(false); setEdit(false); }} data={provider} callback={retrieveProviders} />
            <DeleteConfirmation show={showDelete} handleClose={() => {setDeleteVisibility(false);}} data={provider} callback={retrieveProviders}/>
            <ProviderModal show={showRegisterModal} handleClose={() => {setRegisterModalVisibility(false); }} callback={retrieveProviders} />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Gestión de proveedores"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Gestión de proveedores</h1>
                    <section className={styles.actions}>
                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={() => {setRegisterModalVisibility(true);}}>Registrar proveedor</Button>
                        {
                            loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (

                                providers.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    isMobile() ? (
                                        providers.map((i) => (
                                            <></>
                                        ))
                                    ) : (
                                        <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                            <thead>
                                                <th>Razón social</th>
                                                <th>RUC</th>
                                                <th>Tipo de proveedor</th>
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    providers.map((i) => (
                                                        <tr>
                                                            <td>{i.razon_social}</td>
                                                            <td>{i.ruc}</td>
                                                            <td>{retrieveTipoProveedor(i.tipo_proveedor)}</td>
                                                            <td>
                                                                <Icon className={styles.icon}  icon={editFilled} height="30px" onClick={(e) => { handleEdit(e, i); }} />&nbsp;&nbsp;
                                                                <Icon className={styles.icon}  icon={bxsTrash} height="30px" onClick={(e) => {handleDelete(e, i);}} />&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                )
                            )
                        }
                    </section>
                </section>
            </section>
        </>
    );

};

export default Proveedores;