import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./InformeMovimientos.module.css";
import History from "../../components/history";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import Notifications, {notify} from "react-notify-toast";
import TimeFilter from "../../components/time-filter"

const InformeMovimientos = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);

    const [loadingXls, setLoadingXls] = useState(false);
    const [movements, setMovements] = useState([]);

    const [owners, setOwners] = useState([]);
    const [owner, setOwner] = useState(undefined);            

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos",
            path: "/movimientos"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
            }
            else {
                setOwners([]);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
        });
    }       

    const preview = (e) => {
        e.preventDefault();
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var url =  "movements/report/general/preview?id_estancia=" + currentFarm.id;
        
        if(owner != undefined && owner.id != 0){
            url += "&id_propietario=" + owner.id;
        }             

        if(fechaDesde != undefined && fechaHasta != undefined){
            url += "&fecha_desde=" + fechaDesde + "&fecha_hasta=" + fechaHasta;
        }
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(response => {
            var data = response.data;
            setMovements(reBuildPreview(data));
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false); 
            setMovements([]);
        });
    }

    const generarExcel = (e) => {
        e.preventDefault();
        setLoadingXls(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var url =  "movements/report/general?id_estancia=" + currentFarm.id;
        if(owner != undefined && owner.id != 0){
            url += "&id_propietario=" + owner.id;
        }                     
        if(fechaDesde != undefined && fechaHasta != undefined){
            url += "&fecha_desde=" + fechaDesde + "&fecha_hasta=" + fechaHasta;
        }
        fetch(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(response => {
            const filename =  response.headers.get("content-disposition").split("filename=")[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                setLoadingXls(false);
                notify.show("Reporte generado exitosamente", "success");
            });
        }).catch(error => {
            notify.show("Ha ocurrido un error al generar el reporte", "error");
            console.error(error);
            setLoadingXls(false);            
        });        
    }


    useEffect(() => {
        checkSession("expenses", props.history, cookie.kUserSession);
        retrieveOwners();
    }, []);

    const reBuildPreview = (data) => {
        let filterCondition = (obj, markCondition) => {return obj.marcaInicio !== undefined && obj.marcaInicio !== null && markCondition};
        let soloTerneros = data.filter(fil => filterCondition(fil, fil.marcaInicio));
        let noTerneros = data.filter(fil => filterCondition(fil, !fil.marcaInicio));
        let totalNoTerneros = {
            "idEstancia": undefined,
            "estanciaNombre": undefined,
            "idCategoria": undefined,
            "categoriaNombre": "TOTAL GANADO MARCADO",
            "carimbo": 99,
            "compras": 0,//COMPRADOS
            "nacimientos": 0,//NACIDOS
            "transferenciasEntrada": 0,//TRANSFER. 1
            "recategorizacionEntrada": 0,//RECLASIF
            "ventas": 0,//VENTAS
            "sobrantes": 0,
            "faltantes": 0,
            "mortandad": 0,//MORTANDAD
            "consumo": 0,//CONSUMO
            "transferenciasSalida": 0,
            "recategorizacionSalida": 0,//RECLASIF. 2
            "totalEntradas": 0,//TOTAL ENTRADAS
            "totalSalidas": 0,//TOTAL SALIDA
            "totalExistencias": 0,//TOTAL EXISTENCIA
            "stockMesAnterior": 0,//STOCK MES ANTERIOR
            "marcaInicio": false,
            "sexo": undefined
        }
        let totalTotal = {
            "idEstancia": undefined,
            "estanciaNombre": undefined,
            "idCategoria": undefined,
            "categoriaNombre": "TOTAL GENERAL GANADO VACUNO",
            "carimbo": 99,
            "compras": 0,//COMPRADOS
            "nacimientos": 0,//NACIDOS
            "transferenciasEntrada": 0,//TRANSFER. 1
            "recategorizacionEntrada": 0,//RECLASIF
            "ventas": 0,//VENTAS
            "sobrantes": 0,
            "faltantes": 0,            
            "mortandad": 0,//MORTANDAD
            "consumo": 0,//CONSUMO
            "transferenciasSalida": 0,
            "recategorizacionSalida": 0,//RECLASIF. 2
            "totalEntradas": 0,//TOTAL ENTRADAS
            "totalSalidas": 0,//TOTAL SALIDA
            "totalExistencias": 0,//TOTAL EXISTENCIA
            "stockMesAnterior": 0,//STOCK MES ANTERIOR
            "marcaInicio": false,
            "sexo": undefined
        }
        let conditionNullElseUndefined = (d) => {return d === undefined || d === null ? 0 : d};
        noTerneros.forEach(e => {
            totalNoTerneros.compras += conditionNullElseUndefined(e.compras);
            totalNoTerneros.nacimientos += conditionNullElseUndefined(e.nacimientos);
            totalNoTerneros.transferenciasEntrada += conditionNullElseUndefined(e.transferenciasEntrada);
            totalNoTerneros.recategorizacionEntrada += conditionNullElseUndefined(e.recategorizacionEntrada);
            totalNoTerneros.ventas += conditionNullElseUndefined(e.ventas);

            totalNoTerneros.sobrantes += conditionNullElseUndefined(e.sobrantes);
            totalNoTerneros.faltantes += conditionNullElseUndefined(e.faltantes);

            totalNoTerneros.mortandad += conditionNullElseUndefined(e.mortandad);
            totalNoTerneros.consumo += conditionNullElseUndefined(e.consumo);
            totalNoTerneros.recategorizacionSalida += conditionNullElseUndefined(e.recategorizacionSalida);
            totalNoTerneros.totalEntradas += conditionNullElseUndefined(e.totalEntradas);
            totalNoTerneros.totalSalidas += conditionNullElseUndefined(e.totalSalidas);
            totalNoTerneros.totalExistencias += conditionNullElseUndefined(e.totalExistencias);
            totalNoTerneros.stockMesAnterior += conditionNullElseUndefined(e.stockMesAnterior);
        });
        noTerneros.push(totalNoTerneros);
        soloTerneros.forEach(e => {
            totalTotal.compras += conditionNullElseUndefined(e.compras);
            totalTotal.nacimientos += conditionNullElseUndefined(e.nacimientos);
            totalTotal.transferenciasEntrada += conditionNullElseUndefined(e.transferenciasEntrada);
            totalTotal.recategorizacionEntrada += conditionNullElseUndefined(e.recategorizacionEntrada);
            totalTotal.ventas += conditionNullElseUndefined(e.ventas);

            totalTotal.sobrantes += conditionNullElseUndefined(e.sobrantes);
            totalTotal.faltantes += conditionNullElseUndefined(e.faltantes);
            
            totalTotal.mortandad += conditionNullElseUndefined(e.mortandad);
            totalTotal.consumo += conditionNullElseUndefined(e.consumo);
            totalTotal.recategorizacionSalida += conditionNullElseUndefined(e.recategorizacionSalida);
            totalTotal.totalEntradas += conditionNullElseUndefined(e.totalEntradas);
            totalTotal.totalSalidas += conditionNullElseUndefined(e.totalSalidas);
            totalTotal.totalExistencias += conditionNullElseUndefined(e.totalExistencias);
            totalTotal.stockMesAnterior += conditionNullElseUndefined(e.stockMesAnterior);
        });
        totalTotal.compras += totalNoTerneros.compras;
        totalTotal.nacimientos += totalNoTerneros.nacimientos;
        totalTotal.transferenciasEntrada += totalNoTerneros.transferenciasEntrada;
        totalTotal.recategorizacionEntrada += totalNoTerneros.recategorizacionEntrada;
        totalTotal.ventas += totalNoTerneros.ventas;

        totalTotal.sobrantes += conditionNullElseUndefined(totalNoTerneros.sobrantes);
        totalTotal.faltantes += conditionNullElseUndefined(totalNoTerneros.faltantes);        

        totalTotal.mortandad += totalNoTerneros.mortandad;
        totalTotal.consumo += totalNoTerneros.consumo;
        totalTotal.recategorizacionSalida += totalNoTerneros.recategorizacionSalida;
        totalTotal.totalEntradas += totalNoTerneros.totalEntradas;
        totalTotal.totalSalidas += totalNoTerneros.totalSalidas;
        totalTotal.totalExistencias += totalNoTerneros.totalExistencias;
        totalTotal.stockMesAnterior += totalNoTerneros.stockMesAnterior;
        soloTerneros.push(totalTotal);
        return noTerneros.concat(soloTerneros);
    };

    return (
        <>
            <Notifications />
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Informe de movimientos"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <section className={styles.side}> 
                            <Form className={styles.searchForm}>
                                <TimeFilter callback={callbackFiltroFechas}/>
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label className={styles.label}>Propietario</Form.Label>
                                            <Form.Control as="select" value={owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    owners.map((i) => (
                                                        <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>                                 
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label htmlFor="account" className={styles.label}>Tipo de informe</Form.Label>
                                            <Form.Control id="account" className={"karanda-input"} as="select">
                                                <option value={1}>General</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" disabled={loading} style={{ height: "fit-content", marginTop: "auto" }} onClick={preview}>
                                            Previsualización
                                            {
                                                loading ? (
                                                    <>
                                                        &nbsp;&nbsp;<img src={loadingBtn} height="15" />
                                                    </>
                                                ) : <></>
                                            }
                                        </Button>                                    
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" disabled={loadingXls} style={{ height: "fit-content", marginTop: "auto" }} onClick={generarExcel}>
                                            Descargar Excel
                                            {
                                                loadingXls ? (
                                                    <>
                                                        &nbsp;&nbsp;<img src={loadingBtn} height="15" />
                                                    </>
                                                ) : <></>
                                            }
                                        </Button>                                    
                                    </Col>
                                </Row>
                            </Form>                        
                        </section>
                    </Col>
                    <Col>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Informe de movimientos</h1>
                            <section className={styles.actions}>
                                {
                                    movements.length == 0 ? (
                                        <>
                                            <br></br>
                                            <span>No hay registros que mostrar</span>
                                        </>
                                    ) : <></>
                                }
                                {
                                    movements.length > 0 ? (

                                        <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                            <thead>
                                                <tr>
                                                    <th className={styles.informeHeader} colSpan="10">ENTRADAS</th>
                                                    <th className={styles.informeHeader} colSpan="8">SALIDAS</th>
                                                </tr>
                                                <tr>
                                                    <th className={styles.informeCell}>CATEGORIA</th>
                                                    <th className={styles.informeCell}>CBO</th>
                                                    <th className={styles.informeCell}>STOCK MES ANTERIOR</th>
                                                    <th className={styles.informeCell}>NACIDOS</th>
                                                    <th className={styles.informeCell}>MARCADOS</th>
                                                    <th className={styles.informeCell}>RECLASIF.</th>
                                                    <th className={styles.informeCell}>COMPRADOS</th>
                                                    <th className={styles.informeCell}>TRANSFER.</th>
                                                    <th className={styles.informeCell}>SOBRANTES</th>
                                                    <th className={styles.informeCell}>TOTAL ENTRADAS</th>
                                                    <th className={styles.informeCell}>MORTANDAD</th>
                                                    <th className={styles.informeCell}>VENTAS</th>
                                                    <th className={styles.informeCell}>RECLASIF.</th>
                                                    <th className={styles.informeCell}>CONSUMO</th>
                                                    <th className={styles.informeCell}>FERIAS</th>
                                                    <th className={styles.informeCell}>FALTANTES</th>
                                                    <th className={styles.informeCell}>TOTAL SALIDA</th>
                                                    <th className={styles.informeCell}>TOTAL EXISTENCIA</th>                                                        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    movements.map((i, index) => (
                                                        <tr>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.categoriaNombre}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.carimbo == 99 ? "-" : i.carimbo}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.stockMesAnterior}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.nacimientos}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}></td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.recategorizacionEntrada}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.compras}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.transferenciasEntrada}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.sobrantes}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.totalEntradas}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.mortandad}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.ventas}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.recategorizacionSalida}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.consumo}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}></td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.faltantes}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.totalSalidas}</td>
                                                            <td className={`${i.categoriaNombre.toUpperCase().includes("TOTAL") ? styles.totalRow : styles.informeCell}`}>{i.totalExistencias}</td>                                                    
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>

                                    ) : <></>
                                }
                            </section>
                        </section>                    
                    </Col>          
                </Row>
            </section>
        </>
    );

};

export default InformeMovimientos;