import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import { validateField, validateEmail, validateDate } from "../../../core/validators";
import { dateToRequestDate } from "../../../core/date";
import styles from "./TransferenciaEntradaModal.module.css";
import { format } from "../../../core/number";

const TransferenciaEntradaModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [fechaTransferencia, setFechaTransferencia] = useState(undefined);
    const [estanciaOrigen, setEstanciaOrigen] = useState(0);
    const [retiro, setRetiro] = useState(undefined);
    const [categoria, setCategoria] = useState(undefined);
    const [carimbo, setCarimbo] = useState(0);
    const [cantidadRecibida, setCantidadRecibida] = useState(0);
    const [pesoTotal, setPesoTotal] = useState(0);
    const [observacion, setObservacion] = useState(undefined);
    const [categories, setCategories] = useState([]);
    const [farms, setFarms] = useState([]);

    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }


    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id, prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let data = rs.data;
                    setOwners(data);
                    setOwner(data[0]);
                }
                else {
                    setOwners([]);
                    setOwner(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setOwners([]);
                setOwner(undefined);
            });
    }

    const validateParams = () => {
        return true;
    }


    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
            .then(rs => {
                console.log(rs);
                if (rs.status == 200) {
                    let { data } = rs.data;
                    setCategories(data);
                }
                else {
                    setCategories([]);
                }
            })
            .catch(error => {
                console.error(error);
                setCategories([]);
            });
    }

    const retrieveFarms = () => {
        let currentCompany = cookie.kUserSession.data["company"];
        console.log("CURRENT_farm", currentCompany);
        axios.get(process.env.REACT_APP_API_URL + "farm/" + currentCompany.id, prepareHeaders())
            .then(rs => {
                console.log(rs);
                if (rs.status == 200) {
                    let { data } = rs.data;
                    setFarms(data);
                }
                else {
                    setFarms([]);
                }
            })
            .catch(error => {
                console.error(error);
                setFarms([]);
            })
    }

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/entrance/transfer", body, prepareHeaders())
            .then(rs => {
                let data = rs.data;
                setLoading(false);
                callback();
                handleClose();
                notify.show("Transferencia registrada exitosamente", "success");
            }).catch(error => {
                console.error(error.response);
                setLoading(false);
                handleClose();
                notify.show("Ha ocurrido un error al registrar la transferencia", "error");
            });
    }

    const handleSave = (e) => {
        if (validateParams()) {
            var currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    cantidad: parseInt(cantidadRecibida),
                    carimbo: parseInt(carimbo),
                    fecha_movimiento: fechaTransferencia,
                    fecha_transferencia: fechaTransferencia,
                    id_categoria: parseInt(categoria),
                    id_estancia: parseInt(currentFarm.id),
                    estancia_origen: parseInt(estanciaOrigen),
                    retiro: retiro,
                    observacion: observacion,
                    peso_bruto_total: parseInt(pesoTotal),
                    peso_total_devastado: format(pesoTotal * 0.96, 2),
                    owner: owner ? owner.id : undefined
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        retrieveCategories();
        retrieveFarms();
        retrieveOwners();
    }, []);

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva transferencia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha de transferencia</Form.Label>
                                    <Form.Control className="karanda-input" value={fechaTransferencia} onChange={(e) => { setFechaTransferencia(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Estancia origen</Form.Label>
                                    <Form.Control className="karanda-input" value={estanciaOrigen} onChange={(e) => { setEstanciaOrigen(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            farms.map((i) => (
                                                <option value={i.id}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => { setOwner({ ...owner, id: e.target.value }); }}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control className="karanda-input" value={retiro} onChange={(e) => { setRetiro(e.target.value); }} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" placeholder="Ingrese carimbo" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad recibida</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidadRecibida} onChange={(e) => { setCantidadRecibida(e.target.value); }} type="number" placeholder="Ingrese cantidad" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Peso total (KG)</Form.Label>
                                    <Form.Control className="karanda-input" value={pesoTotal} onChange={(e) => { setPesoTotal(e.target.value); }} type="number" placeholder="Ingrese cantidad" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control className="karanda-input" value={observacion} onChange={(e) => { setObservacion(e.target.value); }} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15" />
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default TransferenciaEntradaModal;