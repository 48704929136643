import React from "react";
import styles from "./MiniButton.module.css";
import { Card } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';

const MiniButton = ({ history, menu, path, representation }) => {

    const go = (e) => history.push(path);

    return (
        <div className={`${styles.container}`}>
            <Card className={`text-center ${styles.card}`} onClick={go}>
                <Card.Body className={styles.cardBody}>
                    <Icon icon={representation} height="50" />
                </Card.Body>
                <Card.Footer className={styles.cardFooter}>
                    <span className={styles.acceder}>{menu}</span>
                </Card.Footer>
            </Card>
        </div>
    );

};

export default MiniButton;