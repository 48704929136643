import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const FaltantesView = ({ show, handleClose, data, edit, callback }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [idMovimiento, setIdMovimiento] = useState(data != undefined ? data.id_movimiento : undefined);
    const [idFaltantes, setIdFaltantes] = useState(data != undefined ? data.id_nacimiento : undefined);
    const [observacion, setObservacion] = useState(data != undefined ? data.observacion : undefined);
    const [fechaFaltantes, setFechaFaltantes] = useState(data != undefined ? data.fecha_movimiento : undefined);
    const [cantidad, setCantidad] = useState(data != undefined ? data.cantidad : undefined);
    const [carimbo, setCarimbo] = useState(data != undefined ? data.carimbo : undefined);
    const [categoria, setCategoria] = useState(data != undefined ? data.categoria.id_categoria : 0);

    const [categories, setCategories] = useState([]);


    const [creationDate, setCreation] = useState(data != undefined ? data.fecha_creacion : undefined);
    const [userCreation, setUserCreation] = useState(data != undefined ? data.usuario_creacion.email : undefined);
    const [userLastModification, setUserLastModification] = useState(data != undefined ? data.usuario_ultima_modificacion.email : undefined);

    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs.data;
                setCategories(data);
            }
            else {
                setCategories([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
        });
    }     

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }        

    useEffect(() => {
        setIdMovimiento(data != undefined ? data.id_movimiento : undefined);
        setIdFaltantes(data != undefined ? data.id_nacimiento : undefined);
        

        setObservacion(data != undefined ? data.observacion : undefined);
        setFechaFaltantes(data != undefined ? data.fecha_movimiento : undefined);
        setCantidad(data != undefined ? data.cantidad : undefined);

        setCarimbo(data != undefined ? data.carimbo : undefined);
        setCategoria(data != undefined ? data.categoria.id_categoria : 0);

        setCreation(data != undefined ? data.fecha_creacion : undefined);
        setUserCreation(data != undefined ? data.usuario_creacion.email : undefined);
        setUserLastModification(data != undefined ? data.usuario_ultima_modificacion.email : undefined);

        setOwner(data != undefined ? data.owner : undefined);        

    }, [data]);

    const handleUpdate = (e) => {
        var currentFarm = cookie.kUserSession.data["farm"];
        var data = {
            fecha_movimiento: fechaFaltantes,
            cantidad: parseInt(cantidad),
            carimbo: parseInt(carimbo),
            id_categoria: parseInt(categoria),
            id_estancia: parseInt(currentFarm.id),
            id_movimiento: parseInt(idMovimiento),
            id_nacimiento: parseInt(idFaltantes),
            observacion: observacion,
            owner: owner ? owner.id : undefined            
        }
        update(data);        
    }

    const update = (data) => {
        setUpdateLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/missings/" + idMovimiento, data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs;
                notify.show("Se ha actualizado la información exitosamente", "success");
                setUpdateLoading(false);
                handleClose();
                callback();
            }
            else {
                setUpdateLoading(false);
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
                callback();
            }
        })
        .catch(error => {
            console.error(error);
            setUpdateLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
            callback();
        })        
    }

    useEffect(() => {
        retrieveCategories();
        retrieveOwners();        
    }, []);    

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos del faltante</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha de faltante</Form.Label>
                                    <Form.Control disabled={!edit} value={fechaFaltantes} onChange={(e) => { setFechaFaltantes(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                               
                            <Col>
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control disabled={!edit} value={categoria} onChange={(e) => { setCategoria(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="cantidad_sobre_guia">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control disabled={!edit} value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control disabled={!edit} value={carimbo == 99 ? "" : carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control disabled={!edit} value={observacion} onChange={(e) => {setObservacion(e.target.value); }} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="date-time" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>Actualizar</Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default FaltantesView;