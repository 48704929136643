import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table, Spinner } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./Estancias.module.css";
import { Icon, InlineIcon } from '@iconify/react';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import bxsUser from '@iconify/icons-bx/bxs-user';
import Usuarios from "../Usuarios";

const Estancias = ({ show, handleClose, callback, data, setVisibility }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [farmsLoading, setFarmsLoading] = useState(false);
    const [farms, setFarms] = useState([]);
    const [nameError, setNameError] = useState(false);
   
    const [farm, setFarm] = useState(undefined);

    const [farmName, setFarmName] = useState(undefined);
    const [showUsers, setUsersVisibility] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveFarms = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "farm/" + data.id, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setFarms(data);
                setLoading(false);
            }
            else {
                setFarms([]);
                setLoading(false);
            }

        })
        .catch(error => {
            console.error(error);
            setFarms([]);
            setLoading(false);
        });
    }    

    const handleSave = (e) => {
        if(farmName == undefined || farmName == null || farmName.length == 0){
            setNameError(true);
        }
        else {
            setNameError(false);
            var postData = {
                data: {
                    empresa: data.id,
                    nombre: farmName
                }
            }
            save(postData);
        }
    }

    const handleDelete = (e, i) => {
        deleteFarm(i.id);
    }

    const deleteFarm = (id) => {
        setFarmsLoading(true);
        axios.delete(process.env.REACT_APP_API_URL + "farm/" + id, prepareHeaders())
        .then(rs => {
            setFarmsLoading(false);
            retrieveFarms();
        })
        .catch(error => {
            console.error(error);
            setFarmsLoading(false);
            notify.show("Ha ocurrido un error al eliminar la estancia", "error");
        });
    }

    const save = (postData) => {
        setFarmsLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "farm", postData, prepareHeaders())
        .then(rs => {
            setFarmsLoading(false);
            setFarmName(undefined);
            retrieveFarms();            
        })
        .catch(error => {
            console.error(error);
            setFarmsLoading(false);
            retrieveFarms();
            notify.show("Ha ocurrido un error al crear la estancia", "error");
        })
    }

    const handleUsers = (e, i) => {
        setVisibility(false);
        setFarm(i);
        setUsersVisibility(true);
    }

    const callbackUsers = () => {
        setVisibility(true);
        retrieveFarms(data.id);
    }

    const usersClose = () => {
        setUsersVisibility(false);
        setVisibility(true);
    }

    useEffect(() => {
        if (data != undefined) {
            retrieveFarms(data.id);
        }
    }, [data]);

    return (
        <>
            <Usuarios callback={callbackUsers} show={showUsers} handleClose={usersClose} farm={farm} company={data}/>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Estancias de {data.nombre}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={styles.searchForm}>
                        <Form.Group>
                            <Form.Label><b>Nombre de la estancia</b></Form.Label>
                            <Form.Control className={nameError ? styles.error : ""} type="text" placeholder="Ingrese el nombre de la estancia" value={farmName} onChange={(e) => {setFarmName(e.target.value)}}/>
                        </Form.Group>
                        <Button className="karanda-btn" disabled={farmsLoading} onClick={(e) => {handleSave(e); }}>
                            {
                                farmsLoading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <span>Registrar</span>
                                )
                            }
                        </Button>
                    </Form>
                    {
                        loading ?   <div style={{marginTop: "15px", textAlign: "center"}}>
                                        <img src={loadingBtn} height="50"  />
                                    </div> : 
                            (
                                farms.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                        <thead>
                                            <th>Nombre</th>
                                            <th>Fecha creación</th>
                                            <th>Creador</th>
                                            <th>Acciones</th>
                                        </thead>
                                        <tbody>
                                            {
                                                farms.map((i) => (
                                                    <tr>
                                                        <td>{i.nombre}</td>
                                                        <td>{i.fecha_creacion}</td>
                                                        <td>{i.usuario_creador != undefined ? i.usuario_creador.email : ""}</td>
                                                        <td>
                                                            <Icon className={styles.icon} icon={bxsUser} height="20px" onClick={(e) => {handleUsers(e, i);}}/>&nbsp;&nbsp;
                                                            <Icon className={styles.icon} icon={bxsTrash} height="20px" onClick={(e) => {handleDelete(e, i); }} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                )
                            )
                        }                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default Estancias;