import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./Categorias.module.css";
import History from "../../components/history";
import { Button, Form, Table } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import roundAssignment from '@iconify/icons-ic/round-assignment';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {checkSession, isMobile} from "../../core/session";
import CustomerModal from "./CustomerModal";
import DeleteConfirmation from "./DeleteConfirmation";
import CustomerView from "./CustomerView";
import * as go from 'gojs';
import { Gender } from "../../core/genders";



const Categorias = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [correlations, setCorrelations] = useState([]);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [customer, setCustomer] = useState(0);
    const [showCustomer, setCustomerVisibility] = useState(false);
    const [showRegisterModal, setRegisterModalVisibility] = useState(false);
    const [edit, setEdit] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [links, setLinks] = useState([]);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Panel de control",
            path: "/control-panel"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveCorrelations = () => {
        setCorrelations([
            {
                id: 1, 
                nombre: "TERNERO",
                sexo: "MACHO",
                marca_inicio: true,
                categorias_anteriores: [],
                categorias_siguientes: [
                    {
                        id: 3,
                        nombre: "TORO",
                        sexo: "MACHO",
                        marca_inicio: false
                    }
                ]
            }
        ]);
    }

    const retrieveCategories = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 200){
                let { data } = rs.data;
                setLoading(false);
                setCategories(data);
            }
            else {
                setLoading(false);
                setCategories([]);
            }
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setCategories([]);
        });
    }

    const handleEdit = (e, i) => {
        setCustomer(i);
        setEdit(true);
        setCustomerVisibility(true);
    }

    const handleDelete = (e, i) => {
        setCustomer(i);
        setDeleteVisibility(true);
    }    

    const makeGraph = () => {
        const $ = go.GraphObject.make;
        // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
        const diagram =
          $(go.Diagram,
            {
              'undoManager.isEnabled': true,  // must be set to allow for model change listening
              // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
              'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
              model: $(go.GraphLinksModel,
                {
                  linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                })
            });
    
        // define a simple Node template
        diagram.nodeTemplate =
          $(go.Node, 'Auto',  // the Shape will go around the TextBlock
            new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
            $(go.Shape, 'RoundedRectangle',
              { name: 'SHAPE', fill: 'white', strokeWidth: 0 },
              // Shape.fill is bound to Node.data.color
              new go.Binding('fill', 'color')),
            $(go.TextBlock,
              { margin: 8, editable: true },  // some room around the text
              new go.Binding('text').makeTwoWay()
            )
          );
    
        return diagram;
    }

    const handleModelChange = (changes) => {
        //alert('GoJS model changed!');
    }    

    const createNodes = () => {
        let nodes = [];
        for(var i = 0; i < categories.length; i++){
            nodes.push({
                key: categories[i].id,
                text: categories[i].nombre
            });
        }
        console.log("Nodes", nodes);
        setNodes(nodes);
    }

    const createLink = () => {
        let links = [];
        var counter = 1;
        for(var i = 0; i < correlations.length; i++){
            let anteriores = correlations[i].categorias_anteriores;
            let siguientes = correlations[i].categorias_siguientes;
            for(var j = 0; j < anteriores == undefined ? 0 : anteriores.length; j++){
                counter++;
                links.push({
                    key: counter*-1,
                    from: anteriores[j].id,
                    to: correlations[i].id
                });
            }
            for(var j = 0; j < ((siguientes == undefined) ? 0 : siguientes.length); j++){
                counter++;
                links.push({
                    key: counter*-1,
                    from: correlations[i].id,
                    to: siguientes[j].id
                });
            }
        }
        setLinks(links);
    }

    useEffect(() => {
        createNodes();
    }, [categories]);

    useEffect(() => {
        createLink();
    }, [correlations]);

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveCategories();
        retrieveCorrelations();
    }, []);

    return (
        <>
            <CustomerView show={showCustomer} edit={edit} handleClose={() => {setCustomerVisibility(false); setEdit(false); }} data={customer} callback={retrieveCategories} />
            <DeleteConfirmation show={showDelete} handleClose={() => {setDeleteVisibility(false);}} data={customer} callback={retrieveCategories}/>
            <CustomerModal show={showRegisterModal} handleClose={() => {setRegisterModalVisibility(false); }} callback={retrieveCategories} />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Gestión de categorias"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Gestión de categorias</h1>
                    <section className={styles.actions}>
                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={() => {setRegisterModalVisibility(true);}}>Registrar categoria</Button>
                        {
                            loading ? <div style={{marginTop: "15px", textAlign: "center"}}><img src={loadingBtn} height="50"  /> </div>: (

                                categories.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    isMobile() ? (
                                        categories.map((i) => (
                                            <></>
                                        ))
                                    ) : (
                                        <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                            <thead>
                                                <th>Nombre</th>
                                                <th>Sexo</th>
                                                <th>Marca inicio</th>
                                                <th>Tipo de animal</th>
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    categories.map((i) => (
                                                        <tr>
                                                            <td>{i.nombre}</td>
                                                            <td>{Gender[i.sexo]}</td>
                                                            <td>{i.marca_inicio ? "SI" : "NO"}</td>
                                                            <td>{i.tipo_animal.nombre}</td>
                                                            <td>
{/*                                                                 <Icon className={styles.icon}  icon={editFilled} height="30px" onClick={(e) => { handleEdit(e, i); }} />&nbsp;&nbsp; */}
                                                                <Icon className={styles.icon}  icon={bxsTrash} height="30px" onClick={(e) => {handleDelete(e, i);}} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                )
                            )
                        }
{/*                         <ReactDiagram
                            initDiagram={makeGraph}
                            divClassName={styles.diagramComponent}
                            nodeDataArray={nodes}
                            linkDataArray={links}
                            onModelChange={handleModelChange}
                        /> */}
                    </section>
                </section>
            </section>
        </>
    );

};

export default Categorias;