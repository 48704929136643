import React from "react";

import styles from './input.module.css'

const InputKaranda = ({ inputType, placeHolder, value, change, onEnter }) => {

    const handleKeyPress = (e) => {
        if (onEnter != undefined && onEnter != null) {
            if (e.charCode == 13) {
                onEnter();
            }
        }
    }

    return (
        <input className={styles.inputStyle}
            type={inputType}
            autoComplete={true}
            placeHolder={placeHolder}
            value={value}
            onKeyPress={handleKeyPress}
            onChange={e => change(e.target.value)} />
    );
}

export default InputKaranda;