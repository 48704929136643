import React, {useState, useEffect} from "react";
import { Table } from "react-bootstrap";
import ComparativaPreview from "./ComparativaPreview";
import {formatAmount} from "../../../core/amount";
import styles from "./ReportComparativaPreview.module.css";

const ReportComparativaPreview = ( {data} ) => {

    const [gastosFijos, setGastosFijos] = useState([]);
    const [gastosVariables, setGastosVariables] = useState([]);
    const [ingresos, setIngresos] = useState([]);
    const [inversion, setInversion] = useState([]);
    const [months, setMonths] = useState([]);

    useEffect(() => {

        if(data != undefined){
            setGastosFijos(calcularMontosGeneral(data.rows, 2000000));
            setGastosVariables(calcularMontosGeneral(data.rows, 3000000));
            setIngresos(calcularMontosGeneral(data.rows, 1000000));
            setInversion(calcularMontosGeneral(data.rows, 4000000));       

        }

    }, [data]);

    const calcularMontosGeneral = (rows, cuenta) => {
        var gastosFijosVar = [];
        for(var i = 0; i < rows.length; i++){
            if(rows[i].codigo == cuenta){
                for(var j = 0; j < data.indicesCabeza.length; j++){
                    gastosFijosVar.push(rows[i].amounts[j]);
                }
                if(rows[i].cuentas != undefined){
                    gastosFijosVar = calcularMontos(rows[i].cuentas, gastosFijosVar);
                }
            }
        }
        return gastosFijosVar;
    }

    const calcularMontos = (cuentas, montos) => {
        for(var i = 0; i < cuentas.length; i++){
            for(var j = 0; j < cuentas[i].amounts.length; j++){
                montos[j] += cuentas[i].amounts[j];
            }
            if(cuentas[i].cuentas != undefined){
                montos = calcularMontos(cuentas[i].cuentas, montos);
            }
        }
        return montos;
    }
    
    return (
        <>
            {
                data == undefined ? <></> : 
                <div>
                    <Table responsive className={styles.previewTable}>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    data.indicesCabeza.map((i) => (
                                        <>
                                            <th>PERIODO</th>
                                            <th>CAB CONSOLIDADAS</th>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr style={{background: "#DAF7A6"}}>
                                <th></th>
                                <th>{"Jul " + (data.periodo1-1) + " - " + "Jun " + data.periodo1}</th>
                                <th>{data.indicesCabeza[0]}</th>
                                <th>{"Jul " + (data.periodo2-1) + " - " + "Jun " + data.periodo2}</th>
                                <th>{data.indicesCabeza[1]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ComparativaPreview rows={data.rows} indices={data.indicesCabeza} level={0}/>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{background: "#808000"}} ><b>Total Gastos Fijos + Gastos Variables</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index])}</span>
                                            </td>            
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index])/data.indicesCabeza.slice(1)[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Gastos + Inversión</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index] + inversion[index])}</span>
                                            </td>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index] + inversion[index])/data.indicesCabeza[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Ingresos - Egresos</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(ingresos[index] - (i + gastosVariables[index] + inversion[index]))}</span>
                                            </td>                                                        
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((ingresos[index] - (i + gastosVariables[index] + inversion[index]) )/data.indicesCabeza[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            }
        </>
    );

}

export default ReportComparativaPreview;