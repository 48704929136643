import React, { useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import styles from "./ChangeFarm.module.css";
import {checkSession} from "../../../core/session";

const ChangeFarm = ({ show, handleClose, history }) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [companiesLoading, setCompaniesLoading] = useState(false);
    const [farmsLoading, setFarmsLoading] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);

    const [company, setCompany] = useState(undefined);
    const [farm, setFarm] = useState(undefined);

    const prepareHeaders = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            history.push("/login");
        }
        else {
            return {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "api_key": "k-randa",
                    "access_token": cookie.kUserSession.data.access_token
                }
            };
        }
    }


    const retrieveCompanies = () => {
        setCompaniesLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "company", prepareHeaders())
            .then(rs => {
                let { data } = rs.data;
                setCompaniesLoading(false);
                setCompany(0);
                setCompanies(data == undefined ? [] : data);
            })
            .catch(error => {
                setCompaniesLoading(false);
                setCompanies([]);
                setCompany(0);
            });
    }

    const retrieveFarms = (companyId) => {
        setFarmsLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "farm/" + companyId, prepareHeaders())
        .then(rs => {
            let { data } = rs.data;
            setFarmsLoading(false);
            setFarms(data == undefined ? [] : data);
            setFarm(0);
        })
        .catch(error => {
            console.error(error);
            setFarmsLoading(false);
            setFarms([]);
            setFarm(0);
        });
    }

    const searchFarm = (id) => {
        for(var i = 0; i < farms.length; i++){
            if(farms[i].id == id){
                return farms[i];
            }
        }
        return undefined;
    }

    const searchCompany = (id) => {
        for(var i = 0; i < companies.length; i++){
            if(companies[i].id == id){
                return companies[i];
            }
        }
        return undefined;
    }        

    const handleSelection = () => {
        if(company != undefined && farm != undefined && farm != 0 && company != 0){
            var {kUserSession} = cookie;
            kUserSession.data["company"] = searchCompany(company);
            kUserSession.data["farm"] = searchFarm(farm);
            setCookies("kUserSession", kUserSession);
            handleClose();
            history.go(history.location.pathname);
            //history.push("/");
        }
        else {
            notify.show("Debes seleccionar la empresa y la estancia", "warning");
        }
    }    

    useEffect(() => {
        checkSession("home", history, cookie.kUserSession);
        retrieveCompanies();
    }, []);

    useEffect(() => {
        if (company != undefined && company != 0) {
            retrieveFarms(company);
        }
    }, [company]);    

    return (
        <>  
            <Notifications />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Cambiar navegación empresa/estancia</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={styles.formLogin}>
                        <Col md={12} sm={12} xs={12}>
                            <FormGroup controlId="formUsername">
                                    <Form.Label className={styles.label}>Seleccione la empresa</Form.Label>
                                    <Form.Control as="select" value={company} className="karanda-input" onChange={(e) => {setCompany(e.target.value);}}>
                                        <option value={0} selected disabled>Seleccione la empresa</option>
                                        {
                                            companies.map((i) => (
                                                <option value={i.id}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <FormGroup controlId="formPassword">
                                <Form.Label className={styles.label}>Seleccione la estancia</Form.Label>
                                <Form.Control as="select" value={farm} className="karanda-input" onChange={(e) => {setFarm(e.target.value);}}>
                                    <option value={0} selected disabled>Seleccione la estancia</option>
                                    {
                                        farms.map((i) => (
                                            <option value={i.id}>{i.nombre}</option>
                                        ))
                                    }
                                </Form.Control>
                            </FormGroup>
                        </Col>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSelection}>Cambiar</Button>
                    <Button variant="outline-secondary" onClick={handleClose}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ChangeFarm;