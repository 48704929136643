import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./TransferenciaSalida.module.css";
import History from "../../components/history";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import { displayDate, buildQueryParams } from "../../core/date";
import DeleteConfirmation from "./DeleteConfirmation";
import TimeFilter from "../../components/time-filter"
import { formatAmount } from "../../core/amount";
import TransferenciaEntradaModal from "./TransferenciaEntradaModal";
import TransferenciaEntradaView from "./TransferenciaEntradaView";
import CustomPagination from "../../components/pagination/CustomPagination";

const TransferenciaSalida = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [transfers, setTransfers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [showAsignations, setAsignationsVisibility] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);

    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [transferOutData, setTransferOutData] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const [category, setCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [carimbo, setCarimbo] = useState(0);

    const [owners, setOwners] = useState([]);
    const [owner, setOwner] = useState(undefined);        

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos de hacienda",
            path: "/movimientos"
        },
        {
            name: "Salidas",
            path: "/movimientos/salidas"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
            }
            else {
                setOwners([]);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
        });
    }            

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    }

    const handleSearch = (e) => {
        setPage(1);
        setPageSize(5);
        retrievePurchases();
    }

    const retrievePurchases = () => {
        setLoading(true);
        setTotalPages(1);
        let url = process.env.REACT_APP_API_URL + "movements/outcome/transfer";
        var currentFarm = cookie.kUserSession.data["farm"];
        let queryParams = {
            page: page,
            page_size: pageSize,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta,
            id_categoria: category == 0 ? undefined : category,
            carimbo: carimbo,
            estancia_origen: currentFarm.id,
            id_propietario: owner != undefined ? owner.id == 0 ? undefined : owner.id : undefined
        };
        url += buildQueryParams(queryParams);

        axios.get(url, prepareHeaders())
            .then(rs => {
                console.log(rs);
                if (rs.status == 200 || rs.status == 206) {
                    let { content, totalPages } = rs.data;
                    setTotalPages(totalPages);
                    setTransfers(content);
                }
                else {
                    setTotalPages(1);
                    setTransfers([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setTotalPages(1);
                setTransfers([]);
            });

    }

    const isSupervisor = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isAdmin = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 2;
    }

    const isInternalAdmin = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 3;
    }

    const isClient = () => {
        if (cookie.kUserSession == undefined || cookie.kUserSession == null) {
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 4;
    }

    const openUserDetailView = (e, i) => {
        setTransferOutData(i);
        setUserDetailVisibility(true);
    }

    const openUserEdit = (e, i) => {
        setTransferOutData(i);
        setUserDetailVisibility(true);
        setEdit(true);
    }

    const handleDelete = (e, i) => {
        setTransferOutData(i);
        setDeleteVisibility(true);
    }

    useEffect(() => {
        checkSession("transfers", props.history, cookie.kUserSession);
        retrievePurchases();
        retrieveCategories();
        retrieveOwners();               
    }, []);

    useEffect(() => {
        retrievePurchases();
        retrieveCategories();
    }, [page, pageSize]);

    const toPage = (page) => {
        console.log("Setting page to ", page);
        setPage(page);
    }

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let { data } = rs.data;
                    console.log(data)
                    setCategories(data);
                }
                else {
                    setCategories([]);
                    setCategory(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setCategories([]);
                setCategory(undefined);
            });
    };

    const outcomeTransfer = () => {
        return transfers.map((i, index) => {
            let { retiro: { nombre }, estancia_involucrada, categoria } = i;
            let noCategory = "Sin categoria";
            return (<tr key={index}>
                <td>{displayDate(i.fecha_transferencia)}</td>
                <td>{nombre}</td>
                <td>{categoria.nombre === undefined ? noCategory : categoria.nombre}</td>
                <td>{i.carimbo}</td>
                <td>{i.cantidad}</td>
                <td>{estancia_involucrada.nombre}</td>
                <td>{i.peso_bruto_total}</td>
                <td>{(i.peso_bruto_total / i.cantidad).toFixed(2)}</td>
                <td style={{ display: 'flex' }}>
                    <Icon className={styles.icon} onClick={(e) => { openUserDetailView(e, i); }} icon={eyeFilled} height="15px" />&nbsp;&nbsp;
                    {
                        isSupervisor() ? (
                            <>
                                <Icon className={styles.icon} onClick={(e) => { openUserEdit(e, i); }} icon={editFilled} height="15px" />&nbsp;&nbsp;
                                <Icon className={styles.icon} onClick={(e) => { handleDelete(e, i); }} icon={bxsTrash} height="15px" />&nbsp;&nbsp;                                    
                            </>
                        ) : (<></>)
                    }

                </td>
            </tr>);
        });
    };

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setDeleteVisibility(false); }} data={transferOutData} callback={retrievePurchases} />
            <TransferenciaEntradaView show={showUserDetail} edit={edit}
                handleClose={() => { setUserDetailVisibility(false); setEdit(false); }}
                data={transferOutData} callback={retrievePurchases} />
            <TransferenciaEntradaModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrievePurchases} />
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Transferencias"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <section className={styles.side}>
                            <Form className={styles.searchForm}>
                                <TimeFilter callback={callbackFiltroFechas} />
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label className={styles.label}>Propietario</Form.Label>
                                            <Form.Control as="select" value={owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    owners.map((i) => (
                                                        <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>                                      
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginBottom: "15px" }}>
                                            <Form.Label className={styles.label}>Categoria</Form.Label>
                                            <Form.Control as="select" value={category} className="karanda-input" onChange={(e) => setCategory(e.target.value)}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    categories.map((i) => (
                                                        <option value={i.id_categoria}>{i.nombre}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginBottom: "15px" }}>
                                            <Form.Label className={styles.label}>Carimbo</Form.Label>
                                            <Form.Control className="karanda-input" value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" placeholder="Ingrese carimbo" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" onClick={handleSearch}>Buscar</Button> 
                                    </Col>
                                </Row>
                            </Form>
                        </section>
                    </Col>
                    <Col>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Transferencias</h1>
                            <section className={styles.actions}>
                                {
                                    isSupervisor() || isAdmin() ? (
                                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setModalVisibility(true); }}>Registrar transferencia</Button>
                                    ) : (<></>)
                                }
                                {
                                    loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                        transfers.length == 0 ? (
                                            <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div>
                                        ) : (
                                                isMobile() ? (
                                                    /*                                                 transfers.map((i) => (
                                                                                                        <UserCard expense={i} />
                                                                                                    )) */
                                                    <span>Vista no disponible</span>
                                                ) : (
                                                        <>
                                                            <Table responsive className="karanda-table" style={{ marginTop: "15px" }}>
                                                                <thead>
                                                                    <th>Fecha</th>
                                                                    <th>Zona</th>
                                                                    <th>Categoria</th>
                                                                    <th>CBO</th>
                                                                    <th>Cantidad s/ transferencia</th>
                                                                    <th>Estancia destino</th>
                                                                    <th>Peso total bruto (KG)</th>
                                                                    <th>Peso prom. (KG)</th>
                                                                    <th>Acciones</th>
                                                                </thead>
                                                                <tbody>
                                                                    {outcomeTransfer()}
                                                                </tbody>
                                                            </Table>
                                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={toPage} totalPages={totalPages}/>
                                                        </>
                                                    )
                                            )
                                    )
                                }
                            </section>
                        </section>                    
                    </Col>
                </Row>
            </section>
        </>
    );

};

export default TransferenciaSalida;