import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./StockInicial.module.css";
import History from "../../components/history";
import { Icon, InlineIcon } from '@iconify/react';
import { displayDate } from "../../core/date";
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import {Button, Form, Table, Pagination} from "react-bootstrap";
import { useCookies } from 'react-cookie';
import {checkSession, isMobile} from "../../core/session";
import axios from 'axios';
import StockModal from "./StockModal";
import DeleteConfirmation from "./DeleteConfirmation";
import StockView from "./StockView";

const StockInicial = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");    

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [stocks, setStocks] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);    

    const [category, setCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [carimbo, setCarimbo] = useState(undefined);

    const [showModal, setModalVisibility] = useState(false);
    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [initialStock, setInitialStock] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Panel de control",
            path: "/control-panel"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const handleSearch = (e) => {
        setPage(1);
        setPageSize(5);
        retrieveStocks();
    }    

    const toPage = (page) => {
        console.log("Setting page to ", page);
        setPage(page);
    };    

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        console.log(cookie.kUserSession.data);
        return cookie.kUserSession.data.rol_id == 1;
    }    

    const retrieveCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                console.log(data)
                setCategories(data);
            }
            else {
                setCategories([]);
                setCategory(undefined);
            }            
        })
        .catch(error => {
            console.error(error);
            setCategories([]);
            setCategory(undefined);
        });
    };    

    const retrieveStocks = () => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var url =  "movements/initial/stock?page=" + page + "&page_size=" + pageSize + "&id_estancia=" + currentFarm.id;
        if(category != 0){
           url += "&id_categoria=" + category
        }
        if(carimbo != undefined){
            url += "&carimbo=" + carimbo;
        }
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(rs => {
            console.log(rs);
            if(rs.status == 206){
                let { content } = rs.data;
                console.log(rs.data);
                setLoading(false);
                setTotalPages(rs.data.totalPages);
                setStocks(content);
            }
            else {
                setLoading(false);
                setTotalPages(1);
                setStocks([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setTotalPages(1);
            setStocks([]);
        });        
    }

    useEffect(() => {
        checkSession("users", props.history, cookie.kUserSession);
        retrieveStocks();
        retrieveCategories();
    }, []);

    useEffect(() => {
        retrieveStocks();
    }, [page, pageSize]);    

    const openUserDetailView = (e, i) => {
        setInitialStock(i);
        setUserDetailVisibility(true);
    }

    const openUserEdit = (e, i) => {
        setInitialStock(i);
        setUserDetailVisibility(true);
        setEdit(true);
    }

    const handleDelete = (e, i) => {
        setInitialStock(i);
        setDeleteVisibility(true);
    }    

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setDeleteVisibility(false); }} data={initialStock} callback={retrieveStocks} />
            <StockModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrieveStocks} />
            <StockView show={showUserDetail} edit={edit} handleClose={() => { setUserDetailVisibility(false); setEdit(false); }} data={initialStock} callback={retrieveStocks} />
            <Header history={props.history} />
            <section className={styles.body}>
                <History items={history} current={"Stock inicial"} history={props.history} />
                <section className={styles.content}>
                    <h1 className={styles.title}>Stock inicial</h1>
                    <section className={styles.actions}>

                        {
                            isSupervisor() ? (
                                <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setModalVisibility(true); }}>Registrar stock inicial</Button>
                            ) : (<></>)
                        }

                        <Form className={styles.searchForm}>
                            <Form.Row style={{ margin: "0px" }}>
                                <Form.Group style={{margin: "0px"}}>
                                    <Form.Label className={styles.label}>Categoria</Form.Label>
                                    <Form.Control as="select" value={category} className="karanda-input" onChange={(e) => setCategory(e.target.value)}>
                                        <option value={0}>      TODOS      </option>
                                        {
                                            categories.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>&nbsp;&nbsp;
                                <Form.Group style={{margin: "0px"}}>
                                    <Form.Label className={styles.label}>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" placeholder="Ingrese carimbo" />
                                </Form.Group>
                                &nbsp;&nbsp;&nbsp;
                                <Button className="karanda-btn" style={{ height: "fit-content", marginTop: "auto" }} onClick={handleSearch}>Buscar</Button>
                            </Form.Row>
                        </Form>
                        {
                            loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                stocks.length == 0 ? (
                                    <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div>
                                ) : (
                                        isMobile() ? (
                                            <span>Vista no disponible</span>
                                        ) : (
                                                <>
                                                    <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                                        <thead>
                                                            <th>Fecha</th>
                                                            <th>Categoría</th>
                                                            <th>CBO</th>
                                                            <th>Cantidad</th>
                                                            <th>Estancia</th>
                                                            <th>Acciones</th>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                stocks.map((i) => (
                                                                    <tr>
                                                                        <td>{displayDate(i.fecha_creacion)}</td>
                                                                        <td>{i.categoria.nombre}</td>
                                                                        <td>{i.carimbo == 99 ? "-" : i.carimbo}</td>
                                                                        <td>{i.cantidad}</td>
                                                                        <td>{i.estancia.nombre}</td>
                                                                        <td>
                                                                            <Icon className={styles.icon} onClick={(e) => { openUserDetailView(e, i); }} icon={eyeFilled} height="30px" />&nbsp;&nbsp;

                                                                            {
                                                                                isSupervisor() ? (
                                                                                    <>
                                                                                        <Icon className={styles.icon} onClick={(e) => { openUserEdit(e, i); }} icon={editFilled} height="30px" />&nbsp;&nbsp;
                                                                                        <Icon className={styles.icon} onClick={(e) => { handleDelete(e, i); }} icon={bxsTrash} height="30px" />&nbsp;&nbsp;
                                                                                    </>
                                                                                ) : (<></>)
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <Pagination className="karanda-pagination">
                                                        {
                                                            [...Array(totalPages).keys()].map((i) => (
                                                                <Pagination.Item onClick={() => { toPage(i + 1); }} active={(i + 1) == page}>{i + 1}</Pagination.Item>
                                                            ))
                                                        }
                                                    </Pagination>
                                                </>
                                            )
                                    )
                            )
                        }                        
                    </section>
                </section>
            </section>
        </>
    );

};

export default StockInicial;