import React from "react";
import {displayDate} from "../../../../core/date";
import {formatAmount} from "../../../../core/amount";

const AccountsPreview = ( { rows, level } ) => {

    const spaces = () => {
        return {
            marginLeft: level + "em"
        };
    }

    const sum = (invoices) => {
        var value = 0;
        for(var i = 0; i < invoices.length; i++){
            value += invoices[i].amount;
        }
        return value;
    }

    return (
        <>
            {
                rows == undefined ? <></> : (
                    <>
                        {
                            rows.map((i) => (
                                <>
                                    <p style={spaces()}>{i.codigo + " - " + i.descripcion}</p>
                                    {
                                        (i.data == undefined || i.data.length == 0) ? <></> : (
                                            <div style={{width: "100%", marginBottom: "1em", paddingLeft: "3em"}}>
                                                <table style={{width: "100%"}}>
                                                {
                                                    i.data.map((j) => (
                                                        <tr>
                                                            <td>{displayDate(j.fecha)}</td>
                                                            <td>{j.nroFactura}</td>
                                                            <td>{j.provider}</td>
                                                            <td>{j.descripcion}</td>
                                                            <td align="right">{formatAmount(j.amount)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                    <tfoot>
                                                        <tr style={{borderTop: "1px solid silver"}}>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td align="right">{formatAmount(sum(i.data))}</td>
                                                        </tr>                                                        
                                                    </tfoot>
                                                </table>
                                            </div>
                                        )
                                    }
                                    {
                                        i.cuentas == undefined ? <></> : (
                                            <AccountsPreview level={level+1} rows={i.cuentas}/>
                                        )
                                    }
                                </>
                            ))
                        }
                    </>
                )
            }
        </>
    );

}

export default AccountsPreview;