import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./RecategorizacionModal.module.css";

const RecategorizacionModal = ({ show, handleClose, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [observation, setObservation] = useState(undefined);
    const [expenseDate, setExpenseDate] = useState(undefined);
    const [carimbo, setCarimbo] = useState(undefined);
    const [cantidad, setCantidad] = useState(undefined);

    const [canRecategorize, setCanRecategorize] = useState([]);
    const [recategorize, setRecategorize] = useState(0);

    const [correlations, setCorrelations] = useState([]);    
    const [correlation, setCorrelation] = useState(0);

    const [owner, setOwner] = useState(undefined);
    const [owners, setOwners] = useState([]);        

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }      

    const validateParams = () => {
        return true;
    }

    const retrieveCanRecategorize = () => {
        axios.get(process.env.REACT_APP_API_URL + "category/canRecategorize", prepareHeaders())
        .then(rs => {
            if(rs.status === 200){
                setCanRecategorize(rs.data);
            }
            else {
                setCanRecategorize([]);
            }
        })
        .catch(error => {
            console.error(error);
            setCanRecategorize([]);
        })
    }

    const retrieveCorrelations = (categoryId) => {
        axios.get(process.env.REACT_APP_API_URL + "category/correlations/" + categoryId, prepareHeaders())
        .then(rs => {
            if(rs.status === 200){
                setCorrelations(rs.data);
            }
            else {
                setCorrelations([]);
            }
        })
        .catch(error => {
            console.error(error);
            setCorrelations([]);
        });
    }

    const save = (body) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + "movements/entrance/recategorization", body, prepareHeaders()).then(rs => {
            let data = rs.data;
            setLoading(false);
            callback();
            handleClose();
            notify.show("Recategorizacion registrada exitosamente", "success");
        }).catch(error => {
            console.error(error.response);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al registrar la recategorización", "error");
        });
    }
    
    const handleSave = (e) => {
        if(validateParams()){
            var currentFarm = cookie.kUserSession.data["farm"];
            const body = {
                data: {
                    cantidad: cantidad,
                    carimbo: carimbo,
                    fecha_movimiento: expenseDate,
                    id_categoria_In: correlation,
                    id_categoria_out: recategorize,
                    id_estancia: currentFarm.id,
                    observacion: observation,
                    owner: owner ? owner.id : undefined                    
                }
            };
            console.log(body);
            save(body);
        }
    }

    useEffect(() => {
        if(recategorize != undefined && recategorize != 0){
            retrieveCorrelations(recategorize);
        }
    }, [recategorize])

    useEffect(() => {
        retrieveCanRecategorize();
        retrieveOwners();        
    }, []);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Nueva recategorización</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="fecha_gasto">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control className="karanda-input" value={expenseDate} onChange={(e) => { setExpenseDate(e.target.value); }} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control as="select" value={ owner != undefined ? owner.id : undefined} className="karanda-input" onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                              
                            <Col>
                                <Form.Group controlId="recategorizar">
                                    <Form.Label>Recategorizar</Form.Label>
                                    <Form.Control className="karanda-input" value={recategorize} onChange={(e) => { setRecategorize(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            canRecategorize.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="nro_guia">
                                    <Form.Label>Nueva categoría</Form.Label>
                                    <Form.Control className="karanda-input" value={correlation} onChange={(e) => { setCorrelation(e.target.value); }} as="select">
                                        <option value={0}>Seleccione</option>
                                        {
                                            correlations.map((i) => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>                                                
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control className="karanda-input" value={carimbo} onChange={(e) => { setCarimbo(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="carimbo">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control className="karanda-input" value={cantidad} onChange={(e) => { setCantidad(e.target.value); }} type="number" />
                                </Form.Group>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="observacion">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control className="karanda-input" value={observation} onChange={(e) => {setObservation(e.target.value);}} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary"
                        onClick={handleSave}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Registrar</span>)
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default RecategorizacionModal;