import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";

const DeleteConfirmation = ({ show, handleClose, data, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(data != undefined ? data.id_consumo_interno : undefined);
    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    useEffect(() => {
        setId(data != undefined ? data.id_consumo_interno : undefined);
    }, [data]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }
    
    const deleteUser = (e) => {
        setLoading(true);
        axios.delete(process.env.REACT_APP_API_URL + "movements/outcome/consuption/" + id, prepareHeaders())
        .then(rs => {
            callback();
            setLoading(false);
            handleClose();
            notify.show("Se ha eliminado el registro del consumo exitosamente", "success");
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            handleClose();
            notify.show("Ha ocurrido un error al eliminar el registro", "error");
        });
    }

    return (
        <>  
            <Notifications />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Confirmar eliminaci&oacute;n</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Est&aacute; seguro que desea eliminar consumo {id} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                    <Button variant="outline-secondary" onClick={deleteUser}>
                        {
                            loading ? (
                                <img src={loadingBtn} height="15"/>
                            ) : (<span>Eliminar</span>)
                        }
                    </Button>            
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default DeleteConfirmation;