import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table, BreadcrumbItem } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, {notify} from "react-notify-toast";
import {validateField, validateEmail, validateDate} from "../../../core/validators";
import {dateToRequestDate} from "../../../core/date";
import styles from "./Usuarios.module.css";
import { Icon, InlineIcon } from '@iconify/react';
import bxsTrash from '@iconify/icons-bx/bxs-trash';

const Usuarios = ({ show, handleClose, callback, company, farm }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);

    const [userAsignationLoading, setUserAsignationLoading] = useState(false);
    const [asignedUsers, setAsignedUsers] = useState([]);

    const [usersLoading, setUsersLoading] = useState(false);

    const [user, setUser] = useState(undefined);
    const [users, setUsers] = useState([]);

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }    

    const retrieveAsignedUsers = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "farm/asign/" + farm.id, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setAsignedUsers(data);
                setLoading(false);
            }
            else {
                setAsignedUsers([]);
                setLoading(false);
            }

        })
        .catch(error => {
            setAsignedUsers([]);
            setLoading(false);
        });
    }    

    const retrieveUsers = () => {
        setUsersLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "users/", prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { data } = rs.data;
                setUsers(data);
                setUsersLoading(false);
            }
            else {
                setUsers([]);
                setUsersLoading(false);
            }

        })
        .catch(error => {
            console.error(error);
            setUsers([]);
            setUsersLoading(false);
        });        
    }

    const handleSave = (e) => {
        callback();
    }

    const assignUser = (postData) => {
    }

    useEffect(() => {
        if (farm != undefined && company != undefined) {
            retrieveAsignedUsers();
            retrieveUsers();
        }
    }, [farm, company]);

    return (
        <>
            <Notifications/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Usuarios asignados a {farm != undefined ? farm.nombre : ""} de {company != undefined ? company.nombre : ""}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading ?   <div style={{marginTop: "15px", textAlign: "center"}}>
                                        <img src={loadingBtn} height="50"  />
                                    </div> : 
                            (
                                asignedUsers.length == 0 ? (
                                    <div style={{marginTop: "15px", textAlign: "center"}}><span>No hay resultados</span></div>
                                ) : (
                                    <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                        <thead>
                                            <th>Nombre</th>
                                            <th>Email</th>
                                            <th>Asignador</th>
                                            <th>Fecha creación</th>
                                            <th>Acciones</th>
                                        </thead>
                                        <tbody>
                                            {
                                                asignedUsers.map((i) => (
                                                    <tr>
                                                        <td>{i.usuario_asignado.nombres + " " + i.usuario_asignado.apellidos}</td>
                                                        <td>{i.usuario_asignado.email}</td>
                                                        <td>{i.usuario_creador != undefined ? i.usuario_creador.email : ""}</td>
                                                        <td>{i.fecha_creacion}</td>
                                                        <td>
                                                            {/* <Icon className={styles.icon} icon={bxsTrash} height="20px" /> */}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                )
                            )
                        }                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default Usuarios;