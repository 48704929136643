import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import styles from "./GestionGastos.module.css";
import History from "../../components/history";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { Icon, InlineIcon } from '@iconify/react';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import bxsTrash from '@iconify/icons-bx/bxs-trash';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { checkSession, isMobile } from "../../core/session";
import { displayDate } from "../../core/date";
import ExpenseModal from "./ExpenseModal";
import ExpenseView from "./ExpenseView";
import UserCard from "./UserCard";
import UserAsignations from "./UserAsignations";
import DeleteConfirmation from "./DeleteConfirmation";
import TimeFilter from "../../components/time-filter"
import {formatAmount} from "../../core/amount";
import CustomPagination from "../../components/pagination/CustomPagination";

const GestionGastos = (props) => {

    const loadingBtn = require("../../assets/loaders/btn-loading.gif");

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [showModal, setModalVisibility] = useState(false);
    const [showAsignations, setAsignationsVisibility] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(undefined);
    const [fechaHasta, setFechaHasta] = useState(undefined);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(undefined);

    const [showUserDetail, setUserDetailVisibility] = useState(false);
    const [showDelete, setDeleteVisibility] = useState(false);
    const [expense, setExpense] = useState(undefined);
    const [edit, setEdit] = useState(false);

    const [provider, setProvider] = useState(undefined);
    const [providers, setProviders] = useState([]);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Ingresos/Egresos",
            path: "/facturas"
        }
    ]

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }

    const callbackFiltroFechas = (fechaInicio, fechaFin) => {
        setFechaDesde(fechaInicio);
        setFechaHasta(fechaFin);
    }

    const retrieveAccountingAccounts = () => {
        setAccountsLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        axios.get(process.env.REACT_APP_API_URL + "accountancy/accounts?id_estancia=" + currentFarm.id + "&tipo=P", prepareHeaders())
            .then(rs => {
                if (rs.status == 200) {
                    let { data } = rs.data;
                    setAccountsLoading(false);
                    setAccounts(data);
                }
                else {
                    setAccountsLoading(false);
                    setAccounts([]);
                    setAccount(undefined);
                }
            })
            .catch(error => {
                console.error(error);
                setAccountsLoading(false);
                setAccounts([]);
                setAccount(undefined);
            });        
    }

    const handleSearch = (e) => {
        setPage(1);
        //setPageSize(5);
        retrieveExpenses();
    }

    const retrieveExpenses = () => {
        setLoading(true);
        var currentFarm = cookie.kUserSession.data["farm"];
        var url =  "expense?page=" + page + "&page_size=" + pageSize + "&estancia=" + currentFarm.id;
        if(fechaDesde != undefined && fechaHasta != undefined){
            url += "&start_date=" + fechaDesde + "&end_date=" + fechaHasta;
        }
        if(account != undefined && account.id != 0){
            url += "&accounting_account=" + account.id;
        }
        if(provider != undefined && provider.id != 0){
            url += "&provider=" + provider.id;
        }
        url += "&tipo_factura=E";
        axios.get(process.env.REACT_APP_API_URL + url, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let { content } = rs.data;
                setLoading(false);
                setTotalPages(rs.data.totalPages);
                setExpenses(content);
            }
            else {
                setLoading(false);
                setTotalPages(1);
                setExpenses([]);
            }            
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
            setTotalPages(1);
            setExpenses([]);
        }); 
    }

    const retrieveProviders = () => {
        axios.get(process.env.REACT_APP_API_URL + "provider", prepareHeaders())
            .then(rs => {
                if(rs.status == 200){
                    let { data } = rs.data;
                    setProviders(data);
                }
                else {
                    setProviders([]);
                    setProvider(undefined);
                }            
            })
            .catch(error => {
                console.error(error);
                setProviders([]);
                setProvider(undefined);
            });
    }    

    const isSupervisor = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 1;
    }

    const isAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 2;
    }

    const isInternalAdmin = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 3;
    }                

    const isClient = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 4;
    }        

    const openUserDetailView = (e, i) => {
        setExpense(i);
        setUserDetailVisibility(true);
    }

    const openUserEdit = (e, i) => {
        setExpense(i);
        setUserDetailVisibility(true);
        setEdit(true);
    }

    const handleDelete = (e, i) => {
        setExpense(i);
        setDeleteVisibility(true);
    }

    useEffect(() => {
        checkSession("expenses", props.history, cookie.kUserSession);
        retrieveExpenses();
        retrieveAccountingAccounts();
        retrieveProviders();
    }, []);

    useEffect(() => {
        retrieveExpenses();
    }, [page, pageSize]);

    const toPage = (page) => {
        setPage(page);
    }

    return (
        <>
            <DeleteConfirmation show={showDelete} handleClose={() => { setDeleteVisibility(false); }} data={expense} callback={retrieveExpenses} />
            <ExpenseView show={showUserDetail} edit={edit} handleClose={() => { setUserDetailVisibility(false); setEdit(false); }} data={expense} callback={retrieveExpenses} />
            <ExpenseModal show={showModal} handleClose={() => { setModalVisibility(false); }} callback={retrieveExpenses} />
            <UserAsignations show={showAsignations} handleClose={() => { setAsignationsVisibility(false); }} data={expense} callback={retrieveExpenses} />
            <Header history={props.history} />
            <section className={styles.body}>
                <Row>
                    <Col>
                        <History items={history} current={"Egresos"} history={props.history} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <section className={styles.side}>                
                            <Form className={styles.searchForm}>
                                <TimeFilter callback={callbackFiltroFechas}/>
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label htmlFor="account" className={styles.label}>Cuenta contable</Form.Label>
                                            <Form.Control id="account" className={"karanda-input"} as="select" value={account != undefined ? account.id : undefined} onChange={(e) => {setAccount({...account, id: e.target.value});}}>
                                            <option value={0}>      TODOS      </option>
                                            {
                                                accounts.map((i) => (
                                                    <option value={i.id}>{i.codigo + " - " + i.descripcion}</option>
                                                ))
                                            }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group style={{marginBottom: "15px"}}>
                                            <Form.Label className={styles.label}>Proveedor</Form.Label>
                                            <Form.Control as="select" value={provider != undefined ? provider.id : undefined} className="karanda-input" onChange={(e) => {setProvider({...provider, id: e.target.value});}}>
                                                <option value={0}>      TODOS      </option>
                                                {
                                                    providers.map((i) => (
                                                        <option value={i.id}>{i.razon_social}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>                                
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="karanda-btn" onClick={handleSearch}>Buscar</Button>                                    
                                    </Col>
                                </Row>
                            </Form>                        
                        </section>
                    </Col>
                    <Col>
                        <section className={styles.content}>
                            <h1 className={styles.title}>Gestión de gastos</h1>
                            <section className={styles.actions}>
                                {
                                    isSupervisor() || isInternalAdmin() ? (
                                        <Button className={`karanda-btn ${styles.btnRegister}`} onClick={(e) => { setModalVisibility(true); }}>Registrar gasto</Button>
                                    ) : (<></>)
                                }
                                {
                                    loading ? <div style={{ marginTop: "15px", textAlign: "center" }}><img src={loadingBtn} height="50" /> </div> : (

                                        expenses.length == 0 ? (
                                            <div style={{ marginTop: "15px", textAlign: "center" }}><span>No hay resultados</span></div>
                                        ) : (
                                                isMobile() ? (
        /*                                                 expenses.map((i) => (
                                                            <UserCard expense={i} />
                                                        )) */
                                                        <span>Vista no disponible</span>
                                                ) : (
                                                        <>
                                                            <Table className="karanda-table" style={{ marginTop: "15px" }}>
                                                                <thead>
                                                                    <th>Fecha</th>
                                                                    <th>Importe</th>
                                                                    <th>Proveedor</th>
                                                                    <th>N° Factura</th>
                                                                    <th>Observaciones</th>
                                                                    <th>Acciones</th>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        expenses.map((i) => (
                                                                            <tr>
                                                                                <td>{displayDate(i.fecha_gasto)}</td>
                                                                                <td>{formatAmount(i.importe)}</td>
                                                                                <td>{i.proveedor.razon_social}</td>
                                                                                <td>{i.numero_factura}</td>
                                                                                <td>{i.observacion}</td>
                                                                                <td>
                                                                                    <Icon className={styles.icon} onClick={(e) => { openUserDetailView(e, i); }} icon={eyeFilled} height="15px" />&nbsp;&nbsp;

                                                                                    {
                                                                                        (isSupervisor()) ? (
                                                                                            <>
                                                                                                <Icon className={styles.icon} onClick={(e) => { openUserEdit(e, i); }} icon={editFilled} height="15px" />&nbsp;&nbsp;
                                                                                                <Icon className={styles.icon} onClick={(e) => { handleDelete(e, i); }} icon={bxsTrash} height="15px" />&nbsp;&nbsp;
                                                                                            </>
                                                                                        ) : (<></>)
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={toPage} totalPages={totalPages}/>
                                                        </>
                                                    )
                                            )
                                    )
                                }
                            </section>
                        </section>                    
                    </Col>
                </Row>
            </section>
        </>
    );

};

export default GestionGastos;