import React, {useState, useEffect} from "react";
import { Table } from "react-bootstrap";
import ConsolidadoPreview from "./ConsolidadoPreview";
import {formatAmount} from "../../../core/amount";
import styles from "./ReportConsolidadoMensualPreview.module.css";

const ReportConsolidadoMensualPreview = ( {data} ) => {

    const [gastosFijos, setGastosFijos] = useState([]);
    const [gastosVariables, setGastosVariables] = useState([]);
    const [ingresos, setIngresos] = useState([]);
    const [inversion, setInversion] = useState([]);
    const [months, setMonths] = useState([]);

    const NAMED_MONTHS = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    useEffect(() => {

        if(data != undefined){
            setMonths(calcularMeses(data.mesInicial, data.indicesCabeza));
            setGastosFijos(calcularMontosGeneral(data.rows, 2000000));
            setGastosVariables(calcularMontosGeneral(data.rows, 3000000));
            setIngresos(calcularMontosGeneral(data.rows, 1000000));
            setInversion(calcularMontosGeneral(data.rows, 4000000));       

        }

    }, [data]);

    const calcularMeses = (mesInicial, indices) => {
        var meses = [];
        for(var i = 0; i < indices.length; i++){
            meses.push(add(i, mesInicial));
        }
        return meses;
    }

    const calcularMontosGeneral = (rows, cuenta) => {
        var gastosFijosVar = [];
        for(var i = 0; i < rows.length; i++){
            if(rows[i].codigo == cuenta){
                for(var j = 0; j < data.indicesCabeza.length; j++){
                    gastosFijosVar.push(rows[i].amounts[j]);
                }
                if(rows[i].cuentas != undefined){
                    gastosFijosVar = calcularMontos(rows[i].cuentas, gastosFijosVar);
                }
            }
        }
        return gastosFijosVar;
    }

    const calcularMontos = (cuentas, montos) => {
        for(var i = 0; i < cuentas.length; i++){
            for(var j = 0; j < cuentas[i].amounts.length; j++){
                montos[j] += cuentas[i].amounts[j];
            }
            if(cuentas[i].cuentas != undefined){
                montos = calcularMontos(cuentas[i].cuentas, montos);
            }
        }
        return montos;
    }

    const resolveMonth = (index, mesInicial, mesFinal, year, total) => {
        if(index == 0){
            return NAMED_MONTHS[mesInicial-1] + " - " + resolveYear(mesInicial, year);
        }
        else if(index == total-1){
            return NAMED_MONTHS[mesFinal-1] + " - " + resolveYear(mesFinal, year);
        }
        else {
            return NAMED_MONTHS[add(index, mesInicial)-1] + " - " + resolveYear(add(index, mesInicial), year);
        }
    }

    const resolveYear = (mes, year) => {
        return (mes >= 7 && mes <= 12) ? year-1 : year;
    }

    const add = (index, mes) => {
        if(mes + index > 12){
            return (mes + index) - 12;
        }
        return mes + index;
    }
    
    return (
        <>
            {
                data == undefined ? <></> : 
                <div>
                    <Table responsive className={styles.previewTable}>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    data.indicesCabeza.map((i, index) => (
                                        <>
                                            <th>{resolveMonth(index, data.mesInicial, data.mesFinal, data.year, data.indicesCabeza.length)}</th>
                                            <th>Inc/Cab</th>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr style={{background: "#DAF7A6"}}>
                                <th>CANTIDAD CABEZAS</th>
                                {
                                    data.indicesCabeza.map((i, index) => (
                                        <>
                                            <th></th>
                                            <th>{i}</th>
                                        </>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <ConsolidadoPreview months={months} rows={data.rows} indices={data.indicesCabeza} level={0}/>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{background: "#808000"}} ><b>Total Gastos Fijos + Gastos Variables</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index])}</span>
                                            </td>            
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index])/data.indicesCabeza.slice(1)[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Gastos + Inversión</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(i + gastosVariables[index] + inversion[index])}</span>
                                            </td>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((i + gastosVariables[index] + inversion[index])/data.indicesCabeza[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td style={{background: "#808000"}}><b>Total Ingresos - Egresos</b></td>
                                {
                                    gastosFijos.map((i, index) => (
                                        <>
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {}}>
                                                <span style={{textAlign: "right"}}>{formatAmount(ingresos[index] - (i + gastosVariables[index] + inversion[index]))}</span>
                                            </td>                                                        
                                            <td align="right" style={index >= gastosFijos.length-1 ? {background: "#808000"} : {background: "#D4EFDF"}}>
                                                <span style={{textAlign: "right"}}>{formatAmount((ingresos[index] - (i + gastosVariables[index] + inversion[index]) )/data.indicesCabeza[index])}</span>
                                            </td>
                                        </>
                                    ))
                                }
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            }
        </>
    );

}

export default ReportConsolidadoMensualPreview;