import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Entradas.module.css";
import MenuCard from "../../components/menu-card";
import History from "../../components/history";
import transferIcon from '@iconify/icons-mdi/transfer';
import dataTransferUpload from '@iconify/icons-oi/data-transfer-upload';
import cartArrowDown from '@iconify/icons-mdi/cart-arrow-down';
import cowIcon from '@iconify/icons-whh/cow';
import {checkSession} from "../../core/session";

const Entradas = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        },
        {
            name: "Movimientos de entrada",
            path: "/movimientos"
        },        
    ]    

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Entradas"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Entradas</h1>
                    <section className={styles.menuGroup}>
                        <MenuCard history={props.history} menu="Compras" representation={cartArrowDown} path={"/movimientos/entradas/compras"} />
                        <MenuCard history={props.history} menu="Nacimientos" representation={cowIcon} path={"/movimientos/entradas/nacimientos"} />
                        <MenuCard history={props.history} menu="Transferencias" representation={transferIcon} path={"/movimientos/entradas/transferencias"} />
                    </section>
                </section>
            </section>                
        </>
    );

};

export default Entradas;