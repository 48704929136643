import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Home.module.css";
import MenuCard from "../../components/menu-card";
import bxTransferAlt from "@iconify/icons-bx/bx-transfer-alt";
import moneyCoin from "@iconify/icons-si-glyph/money-coin";
import chartPiece from "@iconify/icons-si-glyph/chart-piece";
import {checkSession} from "../../core/session";

const Home = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    const isClient = () => {
        if(cookie.kUserSession == undefined || cookie.kUserSession == null){
            return false;
        }
        return cookie.kUserSession.data.rol_id == 4;
    }        

    return (
        <>
            <Header history={props.history} />
            <section className={styles.content}>
                <MenuCard history={props.history} menu="Movimientos de hacienda" representation={bxTransferAlt} path={"/movimientos"}/>
                <MenuCard history={props.history} menu="Ingresos/Egresos" representation={moneyCoin} path={"/facturas"}/>
                {
                    !isClient() ? (
                        <MenuCard history={props.history} menu="Proyecciones" representation={chartPiece} path={"/proyecciones"}/>
                    ) : (
                        <></>
                    )
                }

            </section>
        </>
    );

};

export default Home;