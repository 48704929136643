import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import {dateToRequestDate, buildQueryParams} from "../../../core/date";

const ConsumoView = ({ show, handleClose, data, edit, callback }) => {

    const loadingBtn = require("../../../assets/loaders/btn-loading.gif");

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);
    const [loading, setLoading] = useState(false);
    const [observation, setObservation] = useState(data != undefined ? data.observacion : undefined);
    
    const [fechaConsumo, setFechaConsumo] = useState(new Date());
    const [carabana, setCarabana] = useState('');
    const [retiro, setRetiro] = useState(0);
    const [retirementData, setRetirementData] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [carimbo, setCarimbo] = useState(0);
    const [pesoVivo, setPesoVivo] = useState(0);
    const [pesoGancho, setPesoGancho] = useState(0);
    const [cantidad, setCantidad] = useState(0);
    const [destino, setDestino] = useState(data != undefined ? data.tipo : "I");
    const [creationDate, setCreation] = useState(new Date());
    const [userCreation, setUserCreation] = useState('');
    const [consumos, setConsumos] = useState(data != undefined ? (data.consumos == undefined ? [] : data.consumos) : []);
    const [userLastModification, setUserLastModification] = useState(undefined);

    const [consumidorName, setConsumidor] = useState(undefined);
    const [cantidadKilos, setCantidadKilos] = useState(0);    

    const [owner, setOwner] = useState(data != undefined ? data.owner : undefined);
    const [owners, setOwners] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "api_key": "k-randa",
                "access_token": cookie.kUserSession.data.access_token
            }
        };
    }      

    useEffect(() => {
        setConsumos(data != undefined ? (data.consumos == undefined ? [] : data.consumos) : []);
        setFechaConsumo(data != undefined ? dateToRequestDate(data.fecha_consumo) : new Date());
        setCarabana(data != undefined ? data.nro_caravana : "");
        setRetiro(data != undefined && data.retiro != undefined  ? data.retiro.id_retiro : 0);
        setCategoria(data != undefined && data.categoria != undefined ? data.categoria.id_categoria : 0);
        setCarimbo(data != undefined ? data.carimbo : 0);
        setObservation(data != undefined ? data.observacion : undefined);
        setPesoVivo(data != undefined ? data.peso_vivo : 0);
        setPesoGancho(data != undefined ? data.peso_al_gancho : 0);
        setCantidad(data != undefined ? data.cantidad : 0);
        setDestino(data != undefined ? data.tipo : "I");
        setCreation(data != undefined ? dateToRequestDate(data.fecha_creacion) : new Date());
        setUserCreation(data != undefined && data.usuario_creacion != undefined ? data.usuario_creacion.email : "");
        setUserLastModification(data != undefined && data.usuario_ultima_modificacion != undefined ? data.usuario_ultima_modificacion.email : "");
        setOwner(data != undefined ? data.owner : undefined);        
    }, [data]);


    const retrieveOwners = () => {
        let company = cookie.kUserSession.data["company"];
        axios.get(process.env.REACT_APP_API_URL + "owners?company_id=" + company.id , prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let data = rs.data;
                setOwners(data);
                setOwner(data[0]);
            }
            else {
                setOwners([]);
                setOwner(undefined);
            }
        })
        .catch(error => {
            console.error(error);
            setOwners([]);
            setOwner(undefined);
        });
    }    


    const buildConsumos = () => {
        var consumosEntity = [];
        for(var i = 0; i < consumos.length; i++){
            var consumoEntity = {
                cantidad_kilos: consumos[i].cantidad_kilos,
                consumidor: consumos[i].consumidor,
                id_consumo: consumos[i].id_consumo,
                id_consumo_interno: consumos[i].id_consumo_interno
            };
            consumosEntity.push(consumoEntity);
        }
        return consumosEntity;
    }
        

    const handleUpdate = (e) => {
        let currentFarm = cookie.kUserSession.data["farm"];//preguntar
        var body = {
            data: {
                cantidad: parseInt(cantidad),
                carimbo: parseInt(carimbo),
                fecha_movimiento: data.fecha_movimiento,
                id_categoria: categoria,
                id_consumo_interno: data.id_consumo_interno,
                id_estancia: currentFarm.id,
                id_movimiento: data.id_movimiento,
                id_retiro: retiro,
                nro_caravana: carabana,
                observacion: observation == '' ? null : observation,
                peso_al_gancho: pesoGancho,
                peso_vivo: pesoVivo,
                tipo: destino,
                consumos: buildConsumos(),
                owner: owner ? owner.id : undefined                
            }
        }
        update(body);
    }

    const update = (data) => {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + "movements/outcome/consuption", data, prepareHeaders())
        .then(rs => {
            if(rs.status == 200){
                let {data} = rs;
                callback();
                notify.show("Se ha actualizado la información exitosamente", "success");
                setLoading(false);
                handleClose();
                
            }
            else {                
                setLoading(false);
                callback();
                notify.show("Ha ocurrido un error al actualizar la información", "error");
                handleClose();
            }
        })
        .catch(error => {
            callback();
            console.error(error);
            setLoading(false);
            notify.show("Ha ocurrido un error al actualizar la información", "error");
            handleClose();
        })        
    }

    useEffect(() => {
        retrieveRetirementByFarm();
        retrieveCategory();
        retrieveOwners();
    }, []);

    const retrieveRetirementByFarm = async () => {
        let {farm:{id}} = cookie.kUserSession.data;
        let url = process.env.REACT_APP_API_URL + "retiros";
        url += buildQueryParams({id_estancia: id});
        await axios.get(url, prepareHeaders())
        .then(rs => {
            setRetirementData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setRetirementData([]);
        });
    }

    const retrieveCategory = () => {
        axios.get(process.env.REACT_APP_API_URL + "category", prepareHeaders())
        .then(rs => {
            setCategoryData(rs.status == 200 || rs.status == 206 ? rs.data.data : []);
        })
        .catch(error => {
            console.error(error);
            setCategoryData([]);
        });
    }

    const agregarConsumidor = (e) => {
        e.preventDefault();
        if(consumidorName != undefined && cantidadKilos != undefined){
            var consumidorObj = {
                consumidor: consumidorName,
                cantidad_kilos: cantidadKilos
            };
            setConsumos([...consumos, consumidorObj]);
        }
    }    

    const sumTotal = () => {
        var total = 0;
        for(var i = 0; i < consumos.length; i++){
            total += parseFloat(consumos[i].cantidad_kilos);
        }
        return total;
    }    

    return (
        <>
            <Notifications />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>Datos del consumo</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="consumption-fecha-view">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control disabled={!edit} value={fechaConsumo}
                                                  onChange={(e) => setFechaConsumo(dateToRequestDate(e.target.value))} type="date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="consumption-carabana-view">
                                    <Form.Label>Caravana N°</Form.Label>
                                    <Form.Control disabled={!edit} value={carabana} onChange={(e) => setCarabana(e.target.value)} type="text" placeholder="Ingrese la guía" />
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="owner">
                                    <Form.Label>Propietario</Form.Label>
                                    <Form.Control disabled={!edit}  as="select" value={ owner != undefined ? owner.id : undefined} onChange={(e) => {setOwner({...owner, id: e.target.value});}}>
                                        <option value={0} selected disabled>Seleccione el propietario</option>
                                        {
                                            owners.map((i) => (
                                                <option value={i.id}>{i.nombres + " " + i.apellidos}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>                               
                            <Col>
                                <Form.Group controlId="consumption-retiro-view">
                                    <Form.Label>Zona</Form.Label>
                                    <Form.Control disabled={!edit} value={retiro} onChange={(e) => setRetiro(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione retiro</option>
                                        {
                                            retirementData != undefined && retirementData.length > 0
                                            ? retirementData.map((i)  => (
                                                <option value={i.id_retiro}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ningun retiro disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="consumption-categoria-view">
                                    <Form.Label>Categor&iacute;a</Form.Label>
                                    <Form.Control disabled={!edit} value={categoria} onChange={(e) => setCategoria(e.target.value)} as="select">
                                        <option value={0} selected disabled>Seleccione categor&iacute;a</option>
                                        {
                                            categoryData != undefined && categoryData.length > 0
                                            ? categoryData.map((i)  => (
                                                <option value={i.id_categoria}>{i.nombre}</option>
                                                ))
                                            : <option value={0} selected disabled>{'Ninguna categoria disponible'}</option>
                                        }
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>
                            <Col>
                                <Form.Group controlId="consumption-carimbo-view">
                                    <Form.Label>Carimbo</Form.Label>
                                    <Form.Control disabled={!edit} value={carimbo} onChange={(e) => setCarimbo(e.target.value)} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="consumption-destino-view">
                                    <Form.Label>Destino</Form.Label>
                                    <Form.Control disabled={!edit} value={destino} onChange={(e) => { setDestino(e.target.value); }} as="select">
                                        <option value={"I"}>INTERNO</option>
                                        <option value={"C"}>CONTRATISTA</option>
                                    </Form.Control>
                                </Form.Group>                        
                            </Col>                              
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="consumption-peso-vivo-view">
                                    <Form.Label>Peso vivo</Form.Label>
                                    <Form.Control disabled={!edit} value={pesoVivo} onChange={(e) => setPesoVivo(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="consumption-peso-gancho-view">
                                    <Form.Label>Peso al gancho</Form.Label>
                                    <Form.Control disabled={!edit} value={pesoGancho} onChange={(e) => setPesoGancho(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="consumption-consumo-view">
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control disabled={!edit} value={cantidad} onChange={(e) => setCantidad(e.target.value)} type="number"/>
                                </Form.Group>
                            </Col>                                                    
                        </Row>
                        <>
                            {
                                destino === "C" && edit ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Consumidor</Form.Label>
                                                    <Form.Control type="text" value={consumidorName} onChange={(e) => { setConsumidor(e.target.value); }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Cantidad de kilos</Form.Label>
                                                    <div style={{display: "flex"}}>
                                                        <Form.Control type="number" value={cantidadKilos} onChange={(e) => {setCantidadKilos(e.target.value); }}/>&nbsp;&nbsp;
                                                        <Button onClick={agregarConsumidor}>Agregar</Button>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)
                            }
                        </>                          
                        <>
                            {
                                consumos.length > 0 ? (
                                    <Row>
                                        <Table>
                                            <thead>
                                                <th>Consumidor</th>
                                                <th>Cantidad (KG)</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    consumos.map((i) => (
                                                        <tr>
                                                            <td>{i.consumidor}</td>
                                                            <td style={{textAlign: "right"}}>{i.cantidad_kilos}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style={{textAlign: "right"}}>TOTAL:</td>
                                                    <td style={{textAlign: "right"}}>{sumTotal()}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Row>                                                  
                                ) : (
                                    <></>
                                )
                            }
                        </>                        
                        <Row>
                            <Col>
                                <Form.Group controlId="consumption-observacion-view">
                                    <Form.Label>Observaci&oacute;n</Form.Label>
                                    <Form.Control disabled={!edit} value={observation} onChange={(e) => setObservation(e.target.value)} type="text" placeholder="Observacion" />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            edit ? (<></>) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creador">
                                                <Form.Label>Creador</Form.Label>
                                                <Form.Control value={userCreation} disabled type="email" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="modificador">
                                                <Form.Label>Modificador</Form.Label>
                                                <Form.Control value={userLastModification} disabled type="email" />
                                            </Form.Group>                                
                                        </Col>                     
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="creacion">
                                                <Form.Label>Creación</Form.Label>
                                                <Form.Control value={creationDate} disabled type="date" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>                                    
                            )
                        }
                    </Form>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        edit 
                        ? (<Button variant="primary" onClick={handleUpdate}>
                            {
                                loading ? (
                                    <img src={loadingBtn} height="15"/>
                                ) : (<span>Actualizar</span>)
                            }
                           </Button>)
                        : (<></>)
                    }
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ConsumoView;