import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";

const EficienciaFisicaModal = ( { handleNext, handleClose} ) => {

    
    const [periodo, setPeriodo] = useState(new Date().getFullYear());
    const [precipitacion, setPrecipitacion] = useState(undefined);
    const [hasUtiles, setHasUtiles] = useState(undefined);
    const [stockCabPromedioAnho, setStockCabPromedioAnho] = useState(undefined);
    const [ugPromAnho, setUgPromAnho] = useState(undefined);
    const [cargaCabHa, setCargaCabHa] = useState(undefined);
    const [cargaUgHa, setCargaUgHa] = useState(undefined);
    const [cargaKgsHas, setCargaKgsHas] = useState(undefined);
    const [totalKilosProducidos, setTotalKilosProducidos] = useState(undefined);
    const [produccionKgsHa, setProduccionKgsHa] = useState(undefined);
    const [produccionKgsCab, setProduccionKgsCab] = useState(undefined);
    const [produccionKgsUg, setProduccionKgsUg] = useState(undefined);
    const [gdp, setGdp] = useState(undefined);
    const [totalCabVendidas, setTotalCabVendidas] = useState(undefined);
    const [porcentajeExtraccion, setPorcentajeExtraccion] = useState(undefined);
    const [porcentajeMortandad, setPorcentajeMortandad] = useState(undefined);
    const [porcentajeEficiencia, setPorcentajeEficiencia] = useState(undefined);

    const internalHandleNext = (e) => {
        e.preventDefault();
        const data = {
            periodo,
            eficienciaFisica: {
                cantidadCabezasPromedio: stockCabPromedioAnho,
                cargaKgHa: cargaKgsHas,
                cargaUgHa,
                cargaCabHa,
                gananciaDiario: gdp,
                hectareasUtilizadas: hasUtiles,
                porcentajeExtraccion,
                porcentajeMortandad,
                porcentajeEficiencia,
                precipitacion,
                produccionKgCab: produccionKgsCab,
                produccionKgHa: produccionKgsHa,
                produccionKgUg: produccionKgsUg,
                totalKgProducidos: totalKilosProducidos,
                totalVendidasConsumidasDonadas: totalCabVendidas,
                unidadGanaderaTotales: ugPromAnho
            }
        };
        handleNext(data);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title><b>Eficiencia física</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="Periodo">
                                <Form.Label>Periodo</Form.Label>
                                <Form.Control placeholder="Ingrese el periodo" className="karanda-input" value={periodo} onChange={(e) => setPeriodo(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Precipitación (mm)</Form.Label>
                                <Form.Control placeholder="Precipitación en mm" className="karanda-input" value={precipitacion} onChange={(e) => setPrecipitacion(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Hectáreas utilizadas</Form.Label>
                                <Form.Control placeholder="Ingrese las hectáreas utilizadas" className="karanda-input" value={hasUtiles} onChange={(e) => setHasUtiles(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>                                                
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Cantidad total de cabezas</Form.Label>
                                <Form.Control placeholder="Ingrese el total de cabezas" className="karanda-input" value={stockCabPromedioAnho} onChange={(e) => setStockCabPromedioAnho(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>U.G Totales</Form.Label>
                                <Form.Control placeholder="U.G Totales" className="karanda-input" value={ugPromAnho} onChange={(e) => setUgPromAnho(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>                        
                    </Row>                    
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Carga CAB./HA</Form.Label>
                                <Form.Control placeholder="Ingrese la carga CAB./HA" className="karanda-input" value={cargaCabHa} onChange={(e) => setCargaCabHa(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Carga U.G/HA</Form.Label>
                                <Form.Control placeholder="Ingrese la carga U.G/HA" className="karanda-input" value={cargaUgHa} onChange={(e) => setCargaUgHa(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Carga KGS/HA</Form.Label>
                                <Form.Control placeholder="Ingrese la carga KGS/HA" className="karanda-input" value={cargaKgsHas} onChange={(e) => setCargaKgsHas(e.target.value)} type="number" />
                            </Form.Group>
                        </Col>                        
                    </Row>                                        
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Total de kilos producidos</Form.Label>
                                <Form.Control placeholder="Ingrese el total de kilos producidos" className="karanda-input" value={totalKilosProducidos} onChange={(e) => setTotalKilosProducidos(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Producción KGS/HA</Form.Label>
                                <Form.Control placeholder="Ingrese la producción KG/HA" className="karanda-input" value={produccionKgsHa} onChange={(e) => setProduccionKgsHa(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Producción KGS/CAB</Form.Label>
                                <Form.Control placeholder="Ingrese la producción KG/CAB" className="karanda-input" value={produccionKgsCab} onChange={(e) => setProduccionKgsCab(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>Producción KGS/U.G</Form.Label>
                                <Form.Control placeholder="Ingrese la producción KG/U.G" className="karanda-input" value={produccionKgsUg} onChange={(e) => setProduccionKgsUg(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                                                
                    </Row>                    
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Ganancia diario</Form.Label>
                                <Form.Control placeholder="Ingrese la ganancia diaria" className="karanda-input" value={gdp} onChange={(e) => setGdp(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>Total CAB. vendidas / consumidas / donadas</Form.Label>
                                <Form.Control placeholder="Total CAB. vendidas / consumidas / donadas" className="karanda-input" value={totalCabVendidas} onChange={(e) => setTotalCabVendidas(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>% de extracción</Form.Label>
                                <Form.Control placeholder="Ingrese el % de extracción" className="karanda-input" value={porcentajeExtraccion} onChange={(e) => setPorcentajeExtraccion(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>% de mortandad</Form.Label>
                                <Form.Control placeholder="Ingrese el % de mortandad" className="karanda-input" value={porcentajeMortandad} onChange={(e) => setPorcentajeMortandad(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>% de eficiencia</Form.Label>
                                <Form.Control placeholder="Ingrese el % de eficiencia" className="karanda-input" value={porcentajeEficiencia} onChange={(e) => setPorcentajeEficiencia(e.target.value)} type="number" />
                            </Form.Group>                        
                        </Col>
                    </Row>                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>Cancelar</Button>
                <Button variant="primary" onClick={ internalHandleNext }>Siguiente</Button>
            </Modal.Footer>        
        </>
    );

};

export default EficienciaFisicaModal;