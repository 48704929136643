import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';
import Header from "../../components/header";
import styles from "./Proyecciones.module.css";
import MenuCard from "../../components/menu-card";
import History from "../../components/history";
import moneyCoin from '@iconify/icons-si-glyph/money-coin';
import graphUp from '@iconify/icons-bi/graph-up';
import dataTransferDownload from '@iconify/icons-oi/data-transfer-download';
import dataTransferUpload from '@iconify/icons-oi/data-transfer-upload';
import {checkSession} from "../../core/session";


const Proyecciones = (props) => {

    const [cookie, setCookies, removeCookie] = useCookies(['kUserSession']);

    const history = [
        {
            name: "Inicio",
            path: "/"
        }
    ]    

    useEffect(() => {
        checkSession("home", props.history, cookie.kUserSession);
    }, []);

    return (
        <>
            <Header history={props.history}/>
            <section className={styles.body}>
                <History items={history} current={"Proyecciones"} history={props.history}/>
                <section className={styles.content}>
                    <h1 className={styles.title}>Proyecciones</h1>
                    <section className={styles.menuGroup}>
                        <MenuCard history={props.history} menu="Gastos" representation={moneyCoin} path={"/proyecciones/gastos"}/>
{/*                         <MenuCard history={props.history} menu="Entradas" representation={dataTransferDownload} path={"/proyecciones/entradas"}/>
                        <MenuCard history={props.history} menu="Salidas" representation={dataTransferUpload} path={"/proyecciones/salidas"}/> */}
                        <MenuCard history={props.history} menu="Productividad" representation={graphUp} path={"/proyecciones/productividad"}/>
                    </section>
                </section>
            </section>                
        </>
    );

};

export default Proyecciones;